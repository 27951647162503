.advertisementList_container {
  grid-area: advertisement_list;
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid var(--border-primary);
  padding: 25px 0;

  .advertisementList_img {
    width: auto;
    max-height: 100px;
    object-fit: contain;
    object-position: center;
    margin: 0 20px;
  }
  .advertisementList_link {
    cursor: pointer;
  }
}

.mobile {
  .advertisementList_container {
    border: none;
    margin: 0;
    padding: 0;
    align-items: center;
    display: flex;
    padding: 0;

    .advertisementList_img {
      height: 100px;
      min-height: unset;
      margin: 10px;
      width: 30%;
    }
    a {
       .advertisementList_img {
        width: 100%;
      }
    }
  }
}
