@import '../../../styles/mixins.scss';
@import '../../../styles/sizes.scss';

.category_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: inherit;
  width: 100%;

  .nav_item {
    @include center();

    height: inherit;
    width: fit-content;
    margin: 0 12px 0 0;
    cursor: pointer;
    border-radius: 4px;
    height: 44px;
    text-transform: uppercase;
    padding: 0 16px;
    background: var(--button-secondary);
    span {
      color: var(--button-secondary-text);
    }

    .nav_item_title {
      margin-left: 10px;
    }
    .img_icon {
      width: 24px;
    }
    &:hover {
      background: var(--button-secondary-hover);
    }
  }
  .selected {
    background: var(--button-secondary-selected);
    border: 1px solid var(--button-secondary-border-selected);
    &:hover {
      background: none;
    }
  }
}