/* stylelint-disable selector-max-compound-selectors */
@import '../../../styles/constants';
@import '../../../styles/mixins';

.checkbox_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 25px 0px 35px;
  color: var(--text-primary);

  .checkbox_wrapper {
    background-color: transparent;
    height: 20px;
    width: 20px;
    border: 1px solid var(--icon-color-primary);
    border-radius: 5px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 4px;
    margin-right: 8px;
  }

  .checkbox {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &_disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.6;
    }

    input {
      display: none;

      &:checked + .checkbox__label {
        border: none;

        .checkbox__icon {
          opacity: 1;
        }
      }
    }

    &__label {
      position: relative;
      display: block;
      width: 100%;
      height: 20px;
      overflow: hidden;
      cursor: pointer;
      border-radius: 2px;
    }

    &__icon {
      @include center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 18px;
      width: 18px;
      font-size: 20px;
      opacity: 0;
      transition: opacity $anim-time-fast;

      svg {
        vertical-align: initial;
      }
    }
  }

  p {
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 0;
    width: calc(100% - 25px);
  }

  .errorMessage {
    color: var(--red);
  }
}
