@import '../../../styles/sizes.scss';
@import '../../../styles/constants';

.frame {
  visibility: hidden;
  height: 0;
  width: 0;
  border: none;
}

.tombala,
.casinoTournament,
.race,
.silvaSeries,
.dinhutech,
.betting,
.liveSport,
.cybersport,
.poker,
.fullScreen,
.fun-mission {
  .frame {
    display: block;
    max-width: $maxWidth;
    border-radius: 8px;

    &.fullScreen {
      width: 100vw;
      height: 100vh;
      height: 100svh;
      max-width: unset;
      margin-top: 0;
    }
  }
}

.fullWidth.frame {
  max-width:100%
}

.loading_spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: var(--bg-main-color-8);
  width: 100%;
  color: var(--text-primary);

  .loading_spinner_text {
    margin-top: 15%;
  }

  .loader {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 75px;
    display: inline-block;
    vertical-align: middle;
  }

  .loader-1 .loader-outter {
    position: absolute;
    border: 3px solid hsl(0, 100%, 60.2%);
    border-left-color: transparent;
    border-bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
    animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
  }

  .loader-1 .loader-inner {
    position: absolute;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    border: 3px solid hsl(0, 100%, 60.2%);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border-right: 0;
    border-top-color: transparent;
    -webkit-animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
    animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
  }

  .loader_spinner_wrapper {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    animation: spin-the-thing 1.4s infinite;
    animation-timing-function: linear;
    margin: -180px 0 20px;
  }

  .loading_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;

    .loading_title {
      font-size: 25px;
      color: var(--text-primary);
      margin: 20px 0 5px;
    }

    .loading_subtitle {
      font-size: 20px;
      color: var(--text-secondary);
      margin-bottom: 20px;
    }

    .loading_close {
      background-color: var(--bg-main-color-5);
      padding: 2px 10px;
      border-radius: 5px;
      border: 1px;
      color: var(--text-secondary);

      &:hover {
        background-color: var(--background-surface-3);
      }
    }
  }
}

.desktop,
.table {
  .sport,
  .betting,
  .livesport,
  .cybersport,
  .tournament,
  .sport_frame,
  .casinoTournament {
    .loading_spinner {
      height: calc(100vh - 117px);
    }
  }

  &.tombala,
  &.casinoTournament,
  &.race,
  &.silvaSeries,
  &.dinhutech,
  &.betting,
  &.liveSport,
  &.cybersport,
  &.poker,
  &.blog,
  &.fun-mission {
    .frame {
      width: 100%;

      &.fullScreen {
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: $full-screen-page--index;
        max-width: unset;
        margin: 0;
      }
    }

    .staticIframe {
      &#staticIframe {
        width: 100%;
      }
    }
  }
}

.mobile, .tablet {
  &.tombala,
  &.casinoTournament,
  &.race,
  &.silvaSeries,
  &.dinhutech,
  &.betting,
  &.liveSport,
  &.cybersport,
  &.poker,
  &.blog,
  &.fun-mission {
    .frame {
      width: 100vw;

      &.fullScreen {
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: $full-screen-page--index;
        max-width: unset;
        margin: 0;
      }
    }
  }

  .loading_spinner {
    height: calc(100vh - 20px);
  }
}

// spiner
@keyframes spin-the-thing {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
