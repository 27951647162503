@import '../../../styles/mixins.scss';

.cs_loader {
  @include center();

  width: 100%;
  margin: 10px auto;

  .cs_loader_inner {
    width: calc(100% - 200px);
    color: var(--text-primary);
    text-align: center;

    label {
      margin: 10px;
    }

    &.slow {
      label {
        font-size: 15px;
        opacity: 0;
        display: inline-block;

        &::nth-child(6) {
          -webkit-animation: movement 3s infinite ease-in-out;
          animation: movement 3s infinite ease-in-out;
        }

        &:nth-child(5) {
          -webkit-animation: movement 3s 100ms infinite ease-in-out;
          animation: movement 3s 100ms infinite ease-in-out;
        }

        &:nth-child(4) {
          -webkit-animation: movement 3s 200ms infinite ease-in-out;
          animation: movement 3s 200ms infinite ease-in-out;
        }

        &:nth-child(3) {
          -webkit-animation: movement 3s 300ms infinite ease-in-out;
          animation: movement 3s 300ms infinite ease-in-out;
        }

        &:nth-child(2) {
          -webkit-animation: movement 3s 400ms infinite ease-in-out;
          animation: movement 3s 400ms infinite ease-in-out;
        }

        &:nth-child(1) {
          -webkit-animation: movement 3s 500ms infinite ease-in-out;
          animation: movement 3s 500ms infinite ease-in-out;
        }
      }
    }

    &.fast {
      label {
        font-size: 15px;
        opacity: 0;
        display: inline-block;

        &::nth-child(6) {
          -webkit-animation: movement 2s infinite ease-in-out;
          animation: movement 1s infinite ease-in-out;
        }

        &:nth-child(5) {
          -webkit-animation: movement 2s 100ms infinite ease-in-out;
          animation: movement 1s 100ms infinite ease-in-out;
        }

        &:nth-child(4) {
          -webkit-animation: movement 2s 200ms infinite ease-in-out;
          animation: movement 1s 200ms infinite ease-in-out;
        }

        &:nth-child(3) {
          -webkit-animation: movement 2s 300ms infinite ease-in-out;
          animation: movement 1s 300ms infinite ease-in-out;
        }

        &:nth-child(2) {
          -webkit-animation: movement 1s 400ms infinite ease-in-out;
          animation: movement 1s 400ms infinite ease-in-out;
        }

        &:nth-child(1) {
          -webkit-animation: movement 1s 500ms infinite ease-in-out;
          animation: movement 1s 500ms infinite ease-in-out;
        }
      }
    }
  }
}

@keyframes movement {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }
  33% {
    opacity: 1;
    transform: translateX(0px);
  }
  66% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(300px);
  }
}

@-webkit-keyframes movement {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-300px);
  }
  33% {
    opacity: 1;
    -webkit-transform: translateX(0px);
  }
  66% {
    opacity: 1;
    -webkit-transform: translateX(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(300px);
  }
}

.mobile {
  .section_loader_container .cs_loader {
    align-items: center;
    height: inherit;
  }
}
