@import '../../../styles/sizes.scss';
@import '../../../styles/mixins.scss';

.ageInfo_container {
  display: flex;
  align-items: center;
  grid-area: ageInfo;

  .label {
    font-family: Inter;
    line-height: 17px;
    color: var(--text-primary);
    //color: #1d212d
  }

  img {
    margin-right: 14.5px;
    border-radius: 2px;
    height: $age_info_height;
    width: $age_info_width;
  }
}

.desktop .ageInfo_container,
.tablet .ageInfo_container {
  margin: 0;
  max-width: 250px;
}

.mobile .ageInfo_container {
  margin: 8px 0 0;
  width: 100%;
}
