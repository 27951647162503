@import '../../../styles/sizes.scss';
@import '../../../styles/mixins.scss';

.backToTopBtn_wrapper {
  grid-area: back_to_top;

  .backToTopBtn {
    line-height: 22px;
    border-radius: 8px;
    text-transform: capitalize;
    min-width: 158px;
    color: var(--button-secondary-text);

    svg {
      transform: rotate(180deg);
    }
  }
}

.mobile .backToTopBtn_wrapper {
  width: fit-content;
  margin: 10px;

  .backToTopBtn {
    outline: none;
    width: 40px;
    height: 40px;
    min-width: unset;
  }
}

.desktop .backToTopBtn_wrapper,
.tablet .backToTopBtn_wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .backToTopBtn {
    @include center();
    width: fit-content;
  }
}
