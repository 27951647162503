@import '../../../../../../../styles/mixins.scss';
@import '../../../../../../../styles/constants';

.refer_a_friend_container {
  //ReferredFriendsList---------------------------------------
  .referredFriendsList_modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: calc((700px - 450px) / 2); //modal height  - child modal height;
    left: 0;
    right: 0;
    width: 802px;
    margin: 0 auto;
    border: 1px solid var(--border-primary);
    transition: all 0.3s;
    background: var(--account-background-surface-1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    // z-index: $refer-a-friend__referred-friends-list-modal--index;
    z-index: $modal-index;
    overflow-y: auto;

    .header {
      height: 78px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 20px;

      .title_wrapper {
        display: flex;

        .title_inner_wrapper {
          margin-left: 10px;
        }
      }

      .header_wrapper {
        .title_content {
          display: flex;
          flex-direction: column;
        }

        svg {
          margin-right: 14px;
        }

        .title {
          font-style: normal;
          font-family: Inter-Bold;
          font-size: 20px;
          line-height: 24px;
          color: var(--text-secondary);
          margin: 0;
          text-transform: capitalize;
        }

        .text {
          font-style: normal;
          font-family: Inter-Medium;
          font-size: 16px;
          line-height: 19px;
          color: var(--text-secondary);
        }
      }

      .title {
        font-style: normal;
        font-family: Inter-Bold;
        font-size: 20px;
        line-height: 24px;
        color: var(--text-primary);
        margin: 0;
        text-transform: capitalize;
      }

      .text {
        font-style: normal;
        font-family: Inter-Medium;
        font-size: 16px;
        line-height: 19px;
        color: var(--text-primary);
      }
    }

    .instagram {
      cursor: not-allowed;
    }

    .close {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: var(--account-background-surface-2);
      cursor: pointer;

      @include custom-btn(#313341, 'light');
    }

    .content {
      // overflow: hidden;

      .caption {
        height: 39px !important;
        display: flex;
        align-items: center;
        background-color: var(--account-background-surface-2);

        .caption_col {
          font-style: normal;
          font-family: Inter-SemiBold;
          font-size: 12px;
          line-height: 15px;
          text-transform: uppercase;
        }

        .caption_col {
          &:nth-child(1) {
            width: 170px;
            text-align: center;
          }

          &:nth-child(2) {
            width: calc((100% - 170px) / 2);
            text-align: left;
            padding: 0 20px;
          }

          &:nth-child(3) {
            width: calc((100% - 170px) / 2);
            text-align: right;
            padding: 0 20px;
          }
        }
      }

      .friendList {
        @include scrollbar();

        overflow-y: auto;
        height: 48vh;
        border-radius: 10px;
        overflow: hidden;

        .friend {
          height: 4.7vh;
          display: flex;
          align-items: center;

          &:nth-child(2n) {
            background-color: var(--account-background-surface-2);
          }

          .not_existing_friend__name {
            height: inherit;
            padding: 0 0 0 50px;
            border-right: 1px solid rgba(90, 92, 103, 0.4);
            display: flex;
            align-items: center;
            width: 170px;

            svg {
              height: 4.5vh;
              path {
                fill: var(--icon-color-primary);
              }
            }
          }

          .not_existing_friend__info {
            font-style: normal;
            font-family: Inter-Medium;
            font-size: 12px;
            line-height: 15px;
            color: var(--text-secondary);
            padding: 23px 16px;
          }

          .existing_friend__name {
            height: inherit;
            width: 170px;
            border-right: 1px solid rgba(90, 92, 103, 0.4);
            display: inline-flex;
            align-items: center;

            h6 {
              padding: 0 20px;
              width: 170px;
              max-width: 170px;
              overflow-wrap: break-word;
              text-align: center;
              max-height: 38px;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box !important;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              white-space: normal;
            }
          }

          .existing_friend__info {
            font-style: normal;
            font-family: Inter-Medium;
            font-size: 12px;
            line-height: 15px;
            color: var(--text-secondary);
            padding: 0 16px;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            .level_info {
              display: flex;
              width: 450px;

              .topLine {
                display: flex;
                align-items: center;
                flex-direction: column;

                p {
                  font-style: normal;
                  font-family: Inter-Medium;
                  font-size: 14px;
                  line-height: 17px;
                  color: var(--text-secondary);
                  margin: 0 0 4px;
                  width: 179px;
                  text-align: right;
                }

                .progress {
                  background: rgba(255, 255, 255, 0.1);
                  border: 1px solid rgba(255, 255, 255, 0.8);
                  backdrop-filter: blur(22px);
                  border-radius: 10px;
                  height: 13px;
                  width: 179px;
                  margin: 0 62px;
                  display: flex;
                  align-items: center;
                  padding: 0 2px;
                  margin-bottom: 6px;

                  .passed {
                    height: 9px;
                    background: #ffbf17;
                    border-radius: 8px;
                  }
                }
              }

              .bottomLine {
                display: flex;
                align-items: center;
                width: 240px;
                max-width: 260px;

                .dot {
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  background: #ffbf17;
                  margin-right: 8px;
                }

                span {
                  font-style: normal;
                  font-family: Inter-Medium;
                  font-size: 16px;
                  line-height: 19px;
                  text-align: right;
                  color: var(--text-secondary);

                  span {
                    color: #ffbf17 !important;
                    margin-left: 5px;
                  }
                }
              }
            }

            .prize {
              width: calc(100% - 450px);
              display: flex;
              justify-self: flex-end;

              span {
                font-style: normal;
                font-family: Inter-Medium;
                font-size: 12px;
                line-height: 15px;
                text-align: right;
                color: var(--text-secondary);
                width: 100%;

                & > div {
                  justify-content: flex-end;
                }
              }
            }
          }
        }
      }
    }
  }

  //steps --------------------------------------------------------------------
  .steps {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .step {
      background: var(--background-surface-2);
      border: 1px solid var(--border-primary);
      border-radius: 8px;
      width: 32%;
      height: 12vh;
      padding: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        font-style: normal;
        font-family: Inter-Medium;
        font-size: 0.8rem;
        line-height: 24px;
        margin: 0;
        color: var(--text-secondary);
        position: relative;
        bottom: 0;
      }

      .step_info {
        display: flex;
        justify-content: space-between;

        span {
          font-style: normal;
          font-family: Inter-Bold;
          font-size: 1.5rem;
          line-height: 29px;
          color: var(--text-primary);
        }

        div div svg {
          height: 6vh;
        }
      }
    }
  }

  //rewards -------------------------------------------------------------------
  .collection_wrapper {
    display: flex;
    justify-content: space-between;

    .collection {
      background: linear-gradient(79.05deg, #3241a4 5.19%, #4973de 95.29%);
      border-radius: 10px;
      padding: 8px 12px;
      width: 49%;

      .title {
        font-style: normal;
        font-family: Inter-Bold;
        font-size: 16px;
        line-height: 19px;
        color: var(--text-secondary);
        text-transform: capitalize;
      }

      .list {
        display: flex;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;

        .listItem {
          display: flex;
          justify-content: space-between;
          width: 50%;
          padding: 0.7rem 0.5rem;

          &:nth-child(1) {
            border-right: 1px solid rgba(255, 255, 255, 0.1);
          }

          .title {
            font-family: Inter;
            font-size: 16px;
            line-height: 19px;
            color: var(--text-secondary);
            margin: 0;
          }

          .value {
            font-family: Inter-Bold;
            font-size: 16px;
            line-height: 19px;
            text-align: right;
            color: var(--text-secondary);
          }
        }
      }
    }
  }

  .bottom_Section {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;

    .right_hand {
      width: 49%;
    }

    //generate link------------------------------------------------------------
    .generate_link {
      background: linear-gradient(79.05deg, #3241a4 5.19%, #4973de 95.29%);
      border-radius: 10px;
      width: 49%;

      .header {
        background: rgba(255, 255, 255, 0.1);
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        overflow: hidden;

        svg {
          border-radius: 10px;
        }

        .title {
          display: flex;
          align-items: center;
          flex-direction: column;
          position: relative;
          right: -22px;

          span {
            display: flex;
          }
        }

        h6 {
          font-style: normal;
          font-family: Inter-Bold;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          color: var(--text-secondary);
          white-space: nowrap;
        }

        span {
          font-style: normal;
          font-family: Inter-Bold;
          font-size: 13px;
          line-height: 19px;
          text-align: center;
          color: var(--text-primary);
        }
      }

      .main_content {
        height: calc(100% - 80px);
        overflow-y: auto;
        position: relative;
        padding-top: 20px;
        @include lightScrollbar();

        .inputs {
          padding: 10px;

          .input {
            span {
              margin-bottom: 4px;
              font-style: normal;
              font-family: Inter-Bold;
              font-size: 12px;
              line-height: 15px;
              color: var(--text-secondary);
            }

            .inner_input {
              background: rgba(255, 255, 255, 0.1);
              border: 1px solid rgba(255, 255, 255, 0.1);
              border-radius: 8px;
              height: 38px;
              display: flex;
              align-items: center;

              p {
                background-color: transparent;
                border: none;
                width: 84.5%;
                padding: 12px;
                font-style: normal;
                font-family: Inter;
                font-size: 12px;
                color: var(--text-secondary);
                margin: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

              .copy {
                background: #ffbf17;
                border-radius: 7px;
                height: 33px;
                width: 120px;

                .copy_icon {
                  margin-right: 10px;

                  svg path {
                    fill: black;
                  }
                }

                .copied {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  position: absolute;
                  top: -45px;
                  left: 0;
                  height: 34px;
                  background: var(--background-surface-1);
                  border-radius: 2px;
                  font-family: Inter;
                  font-size: 14px;
                  line-height: 22px;
                  color: var(--text-secondary);
                  padding: 8px;
                  border-radius: 4px;

                  .arrow_down {
                    margin: 0 auto;
                    width: 8px;
                    height: 8px;
                    background: #1f212c;
                    border-radius: 0px 0px 2px 0px;
                    transform: rotate(45deg);
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                  }
                }

                span {
                  font-style: normal;
                  font-family: Inter-Bold;
                  font-size: 12px;
                  line-height: 15px;
                  text-align: center;
                  color: #000000;
                  padding: 0;
                }

                @include custom-btn(#ffbf17, 'dark');
              }
            }
          }
        }

        .generate_link {
          background: rgba(0, 0, 0, 0.8);
          width: 100%;
          height: 150px;
          position: absolute;
          top: 0;
          border-radius: 0;

          .container {
            backdrop-filter: blur(2px);
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          p,
          h6 {
            max-width: 290px;
            text-align: center;
            margin-bottom: 28px;
          }

          h6 {
            margin-bottom: 0;
          }

          .btn {
            width: 253px;
            height: 40px;
            background: #ffbf17;
            border-radius: 8px;
            font-family: Inter-Bold;
            font-size: 12px;
            line-height: 15px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #1b1d24;
            border: none;
            outline: none;
            text-transform: uppercase;

            span {
              width: 100%;
            }

            @include custom-btn(#ffbf17, 'dark');
          }
        }

        .divider {
          text-align: center;
          font-style: normal;
          font-family: Inter-Bold;
          font-size: 12px;
          line-height: 15px;
          color: var(--text-secondary);
          position: relative;
          margin: 8px 0;

          &:before {
            content: '';
            display: block;
            position: absolute;
            left: 35px;
            top: 50%;
            width: 100px;
            height: 1px;
            background: #6682d6;
          }

          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 35px;
            width: 100px;
            height: 1px;
            background: #6682d6;
          }
        }

        .social_icons {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0;
          margin-top: 30px;

          .icon {
            background: white;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            height: 34px;
            width: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 8px;
            cursor: pointer;

            @include custom-btn(white, 'dark');

            svg {
              width: 20px;
              height: 20px;
            }
          }
          .instagram {
            cursor: not-allowed;
          }
        }
      }

      .condition {
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        margin: 0 20px 16px;

        .btn {
          background-color: transparent;
          border: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 11px 12px;
          height: 39px;
          font-style: normal;
          font-family: Inter-SemiBold;
          font-size: 14px;
          line-height: 17px;
          text-decoration-line: underline;
          color: var(--text-secondary);

          svg {
            path {
              fill: var(--text-secondary);
            }

            transform: rotate(-90deg);
            margin-right: -7px;
          }

          @include custom-btn(rgba(255, 255, 255, 0.1), 'dark');
        }
      }
    }

    // prize distribution -------------------------------------------------------
    .prize {
      .title {
        font-family: Inter-Bold;
        font-size: 16px;
        line-height: 19px;
        color: var(--text-secondary);
      }

      .table {
        background: var(--account-background-surface-2);
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 8px;

        .header {
          padding: 8px 12px;
          background: linear-gradient(180deg, rgba(31, 34, 45, 0.4) 0%, rgba(20, 22, 28, 0.4) 100%);
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;

          .header_column {
            font-style: normal;
            font-family: Inter-Bold;
            font-size: 12px;
            line-height: 15px;
            color: var(--text-secondary);
            width: 50px;
            text-transform: capitalize;

            &:nth-child(3),
            &:nth-child(4) {
              width: 110px;
            }

            &:last-child {
              text-align: right;
            }
          }
        }

        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 40px;
          padding: 8px 12px;

          &:nth-child(2n) {
            background-color: var(--account-background-surface-1);
          }

          &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }

          .row_col {
            font-style: normal;
            font-family: Inter;
            font-size: 12px;
            line-height: 15px;
            margin: 0;
            width: auto;
            width: 50px;
            text-transform: capitalize;
          }

          .prize {
            color: var(--text-primary);
            width: 110px;

            &:last-child {
              text-align: right;
            }
          }

          .pp {
            color: var(--text-secondary);
          }

          .level {
            display: flex;
            align-items: center;
            margin-left: -5px;
          }
        }
      }
    }
  }
}

//referred friends ----------------------------------------------------------
.referred_friends {
  background: linear-gradient(79.05deg, #3241a4 5.19%, #4973de 95.29%);
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 25px;

  .accordion {
    background: linear-gradient(268.44deg, rgba(31, 34, 45, 0.4) 7.58%, rgba(154, 164, 194, 0.4) 97.22%);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    @include custom-btn(rgba(31, 34, 45, 0.4), 'dark');

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        h5 {
          margin: 0 9px;
          font-style: normal;
          font-family: Inter-Bold;
          font-size: 16px;
          line-height: 19px;
          color: var(--text-secondary);
        }

        h6 {
          margin: 5px 0 0;
          font-style: normal;
          font-family: Inter-Bold;
          font-size: 12px;
          line-height: 14.52px;
          color: var(--text-secondary);
        }
      }

      span {
        background: var(--button-ghost);
        border-radius: 5px;
        width: 43px;
        height: 36px;
        font-family: Inter-Bold;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #14161c;
      }

      .arrow {
        svg {
          path {
            fill: white;
          }

          transform: rotate(-90deg);
          margin-right: -7px;
        }
      }
    }
  }
}

.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #656c72;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

//termsAndConditions--------------------------------------
.terms_and_condition_modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: calc((700px - 450px) / 2); //modal height  - child modal height;
  left: 0;
  right: 0;
  width: 802px;
  max-height: 100%;
  margin: 0 auto;
  border: 1px solid var(--border-primary);
  transition: all 0.3s;
  background: var(--account-background-surface-2);
  border: 1px solid rgba(250, 250, 250, 0.2);
  border-radius: 10px;
  // z-index: $refer-a-friend__terms-and-condition-modal--index;
  z-index: $modal-index;

  .header {
    height: 48px;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: var(--bg-main-color-0);
    border-radius: 10px;

    .title {
      font-style: normal;
      font-family: Inter-Bold;
      font-size: 16px;
      line-height: 19px;
      color: var(--text-secondary);
    }

    .close {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: var(--account-background-surface-1);
      cursor: pointer;

      @include custom-btn(#1f222d, 'light');
    }
  }

  .content {
    @include scrollbar();

    padding: 20px;
    max-height: 750px;
    overflow-y: auto;

    p {
      font-style: normal;
      font-family: Inter-Medium;
      font-size: 14px;
      line-height: 20px;
      color: var(--text-secondary);
      margin: 0;
      white-space: break-spaces;
    }
  }
}

.backdrop {
  position: absolute;
  inset: 0;
  background-color: rgba(26, 26, 28, 0.6);
  will-change: opacity;
  // z-index: $refer-a-friend__backdrop--index;
  z-index: calc($modal-index - 1);
}

//desktop
.desktop {
  &.refer_a_friend_container {
    height: 100%;

    .bottom_Section {
      height: calc(100% - 210px);
      position: relative;
    }

    .prize {
      overflow: auto;
    }

    .right_hand {
      & > .prize {
        height: 100%;
      }

      &:has(.referred_friends) {
        > .prize {
          height: calc(100% - 117px);
          overflow: hidden;
        }
      }
    }

    .table {
      height: calc(100% - 46px);
      overflow: auto;
      @include scrollbar;
    }

    .row {
      .prize {
        height: unset;
      }
    }
  }
}

.mobile_refer_a_friend_container {
  position: absolute;
  top: 55px;
  background: linear-gradient(79.05deg, #3241a4 5.19%, #4973de 95.29%);
  height: calc(100vh - 55px);
  height: calc(100svh - 55px);
  width: 100vw;
  overflow-y: hidden;

  .mobile_header {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    background-color: var(--background-surface-2);
    right: 0;
    left: 0;

    .arrowDown {
      position: fixed;
      top: 0;
      left: 0;
      transform: rotate(90deg);
      margin: 15px 0 0 10px;
      // z-index: $refer-a-friend__container-mobile-header-arrow-down--index;
      z-index: $icon-index;
    }

    img {
      height: 24px;
      width: 56px;
    }

    .mobile_modal_container {
      top: 0;
    }
  }

  &.no_scroll {
    overflow: hidden;

    .inner_container {
      overflow: hidden;
    }
  }

  .inner_container {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.03) 0%,
      rgba(8, 7, 7, 0.173752) 38.13%,
      rgba(20, 20, 20, 0.41) 100%
    );
    height: 100%;
    padding: 18px 16px;

    .header {
      background: rgba(255, 255, 255, 0.1);
      height: 124px;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      left: -16px;
      top: -17px;
      margin-bottom: 20px;

      .leftCoin svg,
      .rightCoin svg {
        height: 124px;
      }

      .howItWorks {
        @include center();

        background: var(--account-background-surface-1);
        color: var(--text-primary);
        border-radius: 8px;
        width: 180px;

        svg {
          margin-right: 5px;

          path {
            fill: var(--icon-color-primary);
          }
        }
      }

      svg {
        border-radius: 10px;
      }

      .title {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: absolute;
        width: 100%;

        h6 {
          font-style: normal;
          font-family: Inter-Bold;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          color: var(--text-secondary);
          text-transform: uppercase;
          margin: 0 0 9px 0;
        }

        span {
          font-style: normal;
          font-family: Inter-Bold;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          text-transform: uppercase;
          color: var(--text-primary);
          margin-bottom: 16px;
          display: flex;
        }
      }
    }

    .reverse {
      flex-direction: column-reverse;
    }

    .content {
      position: relative;
      top: -17px;
      display: flex;
      flex-direction: column;

      .collection_wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;

        .collection {
          width: 100%;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 10px;
          }

          .title {
            font-style: normal;
            font-family: Inter-Bold;
            font-size: 16px;
            line-height: 19px;
            color: var(--text-secondary);
            text-align: left;
            text-transform: capitalize;
            margin-bottom: 5px;
          }

          .list {
            display: flex;
            background: rgba(255, 255, 255, 0.1);
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            height: 75px;
            margin-bottom: 4px;

            .listItem {
              display: flex;
              flex-direction: column;
              align-items: baseline;
              width: 50%;
              padding: 16px 20px;

              &:nth-child(1) {
                border-right: 1px solid rgba(255, 255, 255, 0.1);
              }

              .title {
                font-family: Inter-SemiBold;
                font-size: 12px;
                line-height: 15px;
                color: var(--text-secondary);
                margin: 0 0 8px 0;
                text-transform: uppercase;
              }

              .value {
                font-style: normal;
                font-family: Inter-Bold;
                font-size: 16px;
                line-height: 19px;
                text-align: right;
                color: var(--text-secondary);
              }
            }
          }
        }
      }

      .ref_friend {
        display: flex;
        justify-content: space-between;
        margin: 18px 0 0;
        width: 100%;

        .referred_friends {
          width: 100%;
          background: transparent;
          padding: 0;
          margin-bottom: 16px;

          .accordion {
            height: 38px;
            font-style: normal;
            font-family: Inter-Bold;
            font-size: 14px;
            line-height: 17px;
            text-transform: uppercase;
            color: var(--text-secondary);

            div {
              .friend_icon svg {
                height: 24px;
                width: 18px;
                margin-right: 9px;
              }

              span {
                width: 29px;
                height: 24px;
                margin-right: 10px;
              }

              h5 {
                font-size: 12px;
              }

              h6 {
                font-size: 10px;
              }
            }
          }
        }
      }

      .generate_link {
        width: 100%;

        .btn {
          width: 100%;
          height: 40px;
          background: #ffbf17;
          border-radius: 8px;
          font-family: Inter-Bold;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #1b1d24;
          border: none;
          outline: none;
          text-transform: uppercase;

          span {
            width: 100%;
          }

          @include custom-btn(#ffbf17, 'dark');
        }

        .condition {
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 8px;
          margin: 16px 0 24px;

          .btn {
            background-color: transparent;
            border: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 11px 12px;
            height: 39px;
            font-style: normal;
            font-family: Inter-SemiBold;
            font-size: 14px;
            line-height: 17px;
            text-decoration-line: underline;
            color: var(--text-secondary);

            span {
              text-align: initial;
              font-style: normal;
              font-family: Inter-SemiBold;
              font-size: 12px;
              line-height: 15px;
              text-decoration-line: underline;
              color: var(--text-secondary);
              text-transform: capitalize;
            }

            svg {
              path {
                fill: var(--text-secondary);
              }

              transform: rotate(-90deg);
              margin-right: -7px;
            }

            @include custom-btn(rgba(255, 255, 255, 0.1), 'dark');
          }
        }

        .steps {
          .step {
            height: 15px;
          }
        }
      }
    }
  }

  .terms_and_condition_modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    max-height: fit-content;
    margin: 0 auto;
    border: none;
    border-radius: 0;
    transition: all 0.3s;
    background: var(--account-background-surface-1);
    // z-index: $refer-a-friend__terms-and-condition-modal-mobile--index;
    z-index: $modal-index;
    text-align: initial;
    overflow-y: auto;

    .header {
      border-radius: 0;

      .close {
        background-color: var(--account-background-surface-2);
      }
    }

    .content {
      overflow: auto;
      max-height: unset;
      padding: 12px;
      margin-bottom: 20px;
    }
  }

  .referredFriendsList_modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    border: none;
    transition: all 0.3s;
    background: var(--account-background-surface-1);
    box-shadow: none;
    border-radius: 0;
    // z-index: $refer-a-friend__referred-friends-list-modal-mobile--index;
    z-index: $modal-index;
    overflow: hidden;

    .header {
      height: 84px;
      display: flex;
      justify-content: space-between;
      padding: 12px 20px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      .title_wrapper {
        display: flex;
        align-items: center;
        text-align: start;

        svg {
          margin-right: 14px;
        }

        .title {
          font-style: normal;
          font-family: Inter-Bold;
          font-size: 20px;
          line-height: 24px;
          color: var(--text-secondary);
          margin: 0;
          text-transform: capitalize;
        }

        .text {
          font-style: normal;
          font-family: Inter-Medium;
          font-size: 16px;
          line-height: 19px;
          color: var(--text-secondary);
        }
      }

      .close {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: var(--account-background-surface-2);
        cursor: pointer;

        @include custom-btn(#313341, 'light');
      }
    }

    .content {
      padding: 12px;
      margin-bottom: 20px;
      overflow: auto;
      height: fit-content;

      .caption {
        display: none;
      }

      .friendList {
        @include scrollbar();

        height: 584px;
        width: 100%;
        overflow-y: auto;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 8px;

        .friend {
          height: 58px;
          display: flex;
          align-items: center;

          &:nth-child(2n) {
            background-color: var(--account-background-surface-2);
          }

          .not_existing_friend__name {
            height: inherit;
            padding: 0 18px;
            border-right: 1px solid rgba(90, 92, 103, 0.4);
            display: flex;
            align-items: center;

            svg {
              height: 4.5vh;
            }
          }

          .not_existing_friend__info {
            font-style: normal;
            font-family: Inter-Medium;
            font-size: 12px;
            line-height: 15px;
            color: var(--text-secondary);
            padding: 23px 16px;
          }

          .existing_friend__name {
            height: inherit;
            width: 77px;
            border-right: 1px solid rgba(90, 92, 103, 0.4);
            display: inline-flex;
            align-items: center;

            h6 {
              padding: 0 20px;
              width: 170px;
              max-width: 170px;
              overflow-wrap: break-word;
              text-align: center;
              max-height: 38px;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box !important;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              white-space: normal;
              font-style: normal;
              font-family: Inter-Bold;
              font-size: 12px;
              line-height: 15px;
              color: var(--text-secondary);
            }
          }

          .existing_friend__info {
            font-style: normal;
            font-family: Inter-Medium;
            font-size: 12px;
            line-height: 15px;
            color: var(--text-secondary);
            padding: 0 12px;
            display: flex;
            align-items: center;
            width: 77%;
            justify-content: space-between;

            .level_info {
              display: flex;
              flex-direction: column-reverse;
              width: 64%;

              .topLine {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                justify-content: flex-end;

                p {
                  font-style: normal;
                  font-family: Inter-Medium;
                  font-size: 10px;
                  line-height: 12px;
                  color: var(--text-secondary);
                  text-align: right;
                  width: auto;
                  margin: 0;
                }

                .progress {
                  background: rgba(255, 255, 255, 0.1);
                  border: 1px solid rgba(255, 255, 255, 0.8);
                  backdrop-filter: blur(22px);
                  border-radius: 10px;
                  height: 10px;
                  width: 100px;
                  margin: 0 4px 4px 0;
                  display: flex;
                  align-items: center;

                  .passed {
                    height: 6px;
                    background: #ffbf17;
                    border-radius: 8px;
                  }
                }
              }

              .bottomLine {
                display: flex;
                align-items: center;

                .dot {
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  background: #ffbf17;
                  margin-right: 8px;
                }

                span {
                  font-style: normal;
                  font-family: Inter-Medium;
                  font-size: 12px;
                  line-height: 15px;
                  text-align: right;
                  color: var(--text-secondary);

                  span {
                    color: #ffbf17 !important;
                    margin-left: 5px;
                  }
                }
              }
            }

            .prize {
              width: 35%;
              display: flex;
              justify-self: flex-end;
              flex-direction: column;
              align-items: flex-end;

              span {
                font-style: normal;
                font-family: Inter-Medium;
                font-size: 12px;
                line-height: 15px;
                text-align: right;
                color: var(--text-secondary);
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.how_it_works_container_modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  border: none;
  transition: all 0.3s;
  background: var(--account-background-surface-1);
  box-shadow: none;
  border-radius: 0;
  // z-index: $refer-a-friend__how-it-works-container-modal--index;
  z-index: $modal-index;
  overflow: hidden;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);

    .title_wrapper {
      display: flex;
      flex-direction: column;
      align-items: baseline;

      .title {
        font-style: normal;
        font-family: Inter-Bold;
        font-size: 20px;
        line-height: 24px;
        color: var(--text-secondary);
        margin: 0 0 10px 0;
        text-transform: capitalize;
      }

      .text {
        font-style: normal;
        font-family: Inter-Medium;
        font-size: 16px;
        line-height: 19px;
        color: var(--text-secondary);
        text-align: initial;
      }
    }

    .close {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: var(--account-background-surface-2);
      cursor: pointer;

      @include custom-btn(#313341, 'light');
    }
  }

  .content {
    padding: 12px;
    margin-bottom: 20px;
    overflow: auto;
    height: fit-content;

    .steps {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-bottom: 1rem;

      .step {
        background: var(--background-surface-2);
        border: 1px solid var(--border-primary);
        border-radius: 8px;
        min-height: 14vh;
        padding: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 8px;

        p {
          font-style: normal;
          font-family: Inter-Medium;
          font-size: 16px;
          line-height: 24px;
          margin: 0;
          color: var(--text-secondary);
          position: relative;
          bottom: 0;
          text-align: initial;
          max-width: 80%;
        }

        .step_info {
          display: flex;
          justify-content: space-between;

          svg {
            height: 7vh;
          }

          span {
            font-style: normal;
            font-family: Inter-Bold;
            font-size: 24px;
            line-height: 29px;
            color: var(--text-primary);
          }
        }
      }
    }

    .prize {
      width: 100%;

      .title {
        font-family: Inter-Bold;
        font-size: 16px;
        line-height: 19px;
        color: var(--text-secondary);
        text-transform: capitalize;
      }

      .table {
        background: var(--account-background-surface-2);
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 8px;

        .header {
          padding: 8px 12px;
          background: linear-gradient(180deg, rgba(31, 34, 45, 0.4) 0%, rgba(20, 22, 28, 0.4) 100%);
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          height: 31px;

          .header_column {
            font-style: normal;
            font-family: Inter-Bold;
            font-size: 12px;
            line-height: 15px;
            color: var(--text-secondary);
            width: unset;
            text-transform: capitalize;

            &:last-child {
              text-align: right;
            }
          }
        }

        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 40px;
          padding: 8px 12px;

          &:nth-child(2n) {
            background-color: var(--background-surface-1);
          }

          &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }

          .row_col {
            font-style: normal;
            font-family: Inter;
            font-size: 12px;
            line-height: 15px;
            margin: 0;
            width: 25%;
            text-transform: capitalize;

            &:last-child {
              text-align: right;
            }
          }

          .prize {
            color: var(--text-primary);
          }

          .pp {
            color: var(--text-secondary);
          }

          .level {
            display: flex;
            align-items: center;
            margin-left: -5px;
            color: var(--text-secondary);
          }
        }
      }
    }
  }
}

.invite_a_friend_modal {
  position: absolute;
  top: 16vh;
  left: 0;
  right: 0;
  width: 93%;
  margin: 0 auto;
  transition: all 0.3s;
  // z-index: $refer-a-friend__invite-a-friend-modal--index;
  z-index: $modal-index;
  overflow: hidden;
  background: var(--background-surface-1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;

  .header {
    height: 47px;
    display: flex;
    justify-content: space-between;
    padding: 14px 20px;
    border: 1px solid var(--border-primary);
    align-items: center;

    .title {
      font-style: normal;
      font-family: Inter-Bold;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: var(--text-primary);
      margin: 0;
      text-transform: capitalize;
    }

    .close {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: var(--account-background-surface-2);
      cursor: pointer;
      margin-right: -8px;
    }
  }

  .content {
    padding: 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .input {
      margin-bottom: 16px;

      h6 {
        font-style: normal;
        font-family: Inter-Bold;
        font-size: 12px;
        line-height: 15px;
        color: var(--text-primary);
        margin-bottom: 8px;
        text-align: left;
      }

      .inner_input {
        width: 100%;
        background: var(--background-surface-1);
        border: 1px solid var(--border-primary);
        border-radius: 8px;
        padding: 12px;

        p {
          margin: 0;
          font-style: normal;
          font-family: Inter;
          font-size: 12px;
          line-height: 15px;
          color: var(--text-primary);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .copy {
        height: 40px;
        width: 100%;
        background: #ffbf17;
        border-radius: 7px;
        font-style: normal;
        font-family: Inter-Bold;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #000000;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 8px;

        .copy_icon {
          margin-right: 10px;
        }

        .copied {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          position: absolute;
          top: 95px;
          right: 0;
          height: 34px;
          background: #1f212c;
          border-radius: 2px;
          font-family: Inter;
          font-size: 14px;
          line-height: 22px;
          color: var(--button-primary-text);
          padding: 8px;

          .arrow_down {
            margin: 0 auto;
            width: 8px;
            height: 8px;
            background: #1f212c;
            border-radius: 0px 0px 2px 0px;
            transform: rotate(45deg);
            flex: none;
            order: 0;
            flex-grow: 0;
          }
        }
      }
    }

    .socials {
      p {
        font-style: normal;
        font-family: Inter-Bold;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: var(--text-primary);
        margin: 0;
      }

      .social_icons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 11px;

        .icon {
          background: white;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 8px;
          height: 40px;
          width: 40px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          margin: 8px 8px 0;
          cursor: pointer;

          svg {
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .how_it_works_container_modal {
    .content {
      .prize {
        .table {
          .row {
            .pp {
              text-align: initial;
              width: 15%;
            }

            .level {
              width: 20%;
            }

            .prize {
              width: 30%;
              text-align: center;
            }

            &:nth-child(3) {
              & > div {
                justify-content: center !important;
              }
            }
          }

          .header span {
            width: unset;
          }
        }
      }
    }
  }

  @supports (-webkit-touch-callout: none) {
    .how_it_works_container_modal {
      .content {
        margin-bottom: 15px;
      }
    }
  }

  @supports not (-webkit-touch-callout: none) {
    .how_it_works_container_modal {
      .content {
        margin-bottom: 15px;
      }
    }
  }
}

//tablet
.tablet.refer_a_friend_container {
  width: 450px;

  .steps {
    flex-wrap: wrap;

    .step {
      height: unset;
    }
  }

  .collection_wrapper {
    flex-direction: column;

    .collection {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .bottom_Section {
    flex-direction: column;
    margin-top: 0 px;

    .generate_link,
    .right_hand {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .condition button {
    width: 100%;
  }

  .terms_and_condition_modal,
  .referredFriendsList_modal {
    width: 450px;
    top: 100px;
    max-height: 600px;

    .content {
      overflow: auto;
    }
  }
}
