@import '../sizes.scss';

$maxWidth: 1440px;
$headerTopLineHeight: 74px;
$headerBottomLineHeight: 55px;
$socialIconHeight: 36px;

.desktop {
  &.template_with_sidebar {
    .header-1Row-sidebar {
      .home_container,
      .page_container,
      .help-center__articles__holder {
        margin-top: calc($mobileHeaderTopLineHeight-2Row_with_nl + $mobileHeaderBottomLineHeight_2Row_with_nl + 10px);
      }

      .casinoTournament #tournament,
      .poker #tournament,
      .race #raceIframe,
      .staticIframe #staticIframe,
      .betting #sport,
      .betting #sport_frame,
      .tombala #tombala,
      .blog #blogIframe,
      .fun-mission #funMissionIframe {
        height: calc(100vh - $headerTopLineHeight);
        margin-top: $headerTopLineHeight auto 0;
        visibility: visible;
      }
    }

    .page_content {
      max-width: $maxWidth;
      margin: 88px auto 0;
      padding: 0 24px;
      min-height: 518px;
    }

    .footer_with_btn {
      right: 0;
    }

    .footer_container {
      max-width: 1440px;
      margin: 0 auto;
      padding: 25px 24px;
    }

    .footer_outer_container {
      position: relative;
      bottom: 0;
      width: 100%;

      .footer_container_inner {
        padding: 0;
      }
    }

    .template_with_sidebar {
      ul.secondary-outlined-btn {
        background-color: var(--background-surface-2);
      }
      .sidebar_is_open {
        .home_container,
        .promotions__container,
        .casinoTournament #tournament,
        .race #raceIframe,
        .staticIframe #staticIframe,
        .betting #sport_frame,
        .betting #sport,
        .tombala #tombala,
        .iframe_wrapper,
        .blog #blogIframe,
        .fun-mission #funMissionIframe {
          padding: 0;
        }

        .desktop_header {
          max-width: calc(100% - 260px);
          left: 260px;
          -webkit-transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          -o-transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        }
      }

      .desktop_header {
        width: calc(100% - 72px);
        margin: 0 auto;
        left: 72px;
        -webkit-transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        -o-transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      }

      .home_container {
        margin: calc($headerLineHeight-1Rows_siderbar + 20px) auto 0;
      }

      .page_container {
        padding: 0 35px;

        .page_container_first_section {
          .banner_container {
            .small_bannerItem {
              &:first-child {
                margin-right: 8px;
              }

              &:last-child {
                margin-left: 8px;
              }
              img {
                height: 100%;
              }
            }
          }
        }

        .page_container_second_section {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;

          .modal_search_wrapper {
            width: 76%;

            .modal_search_container {
              margin: 20px 0;
            }
          }

          .provider_dropdown_view {
            width: 23%;
            -webkit-box-align: baseline;
            -ms-flex-align: baseline;
            align-items: baseline;
            margin: 20px 0 0 9px;

            .provider_wrapper {
              width: 95%;
              right: 0;
            }
          }
        }
      }

      .home_container,
      .page_container,
      .promotions__container {
        display: block;
        border-radius: 4px;
        padding: unset;
        max-width: $maxWidth;
      }

      .footer_container_with_btn.show {
        display: flex;
        align-items: center;
      }

      .footer_container_inner {
        max-width: $maxWidth;
        margin: 0 auto;
      }

      .promotions {
        width: 1200px;

        .promotions__inner {
          width: 1200px; //special width for vertical providers
        }
      }

      .casinoTournament #tournament,
      .race #raceIframe,
      .staticIframe #staticIframe,
      .betting #sport_frame,
      .betting #sport,
      .tombala #tombala,
      .blog #blogIframe,
      .fun-mission #funMissionIframe {
        display: block;
        border-radius: 4px;
        padding: unset;
        max-width: $maxWidth;
        height: calc(100vh - $headerLineHeight-1Rows_siderbar - 20px);
        margin-top: 20px;

        &.fullScreen {
          margin: 0 auto;
          height: 100vh;
        }
      }
    }
  }
}

.mobile {
  &.template_with_sidebar {
    .gameBox_toggle_wrapper {
      margin: 0 5px;
    }
    margin: 0 auto $mobileFixedMenuHeight;
    .header-1Row {
      .home_container,
      .page_container,
      .promotions {
        margin: $mobileHeaderTopLineHeight-1Row 0 0;
      }

      &.casinoTournament #tournament,
      &.poker #tournament,
      &.race #raceIframe,
      &.staticIframe #staticIframe,
      &.betting #sport,
      &.betting #sport_frame,
      &.tombala #tombala,
      &.blog #blogIframe,
      &.fun-mission #funMissionIframe {
        height: calc(100vh - $mobileHeaderTopLineHeight-1Row - $mobileFixedMenuHeight);
        margin-top: $mobileHeaderTopLineHeight-1Row;
        visibility: visible;
      }
      .page_container_first_section {
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 6px;
      }
      .page_container_second_section {
        .simple_row_container {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
      .page_container_third_section {
        .gametags_container {
          padding-left: 4px;
          padding-right: 4px;
        }
      }
    }
    .casinoTournament #tournament,
    .race #raceIframe,
    .staticIframe #staticIframe,
    .betting #sport_frame,
    .betting #sport,
    .tombala #tombala,
    .blog #blogIframe,
    .fun-mission #funMissionIframe {
      display: block;
    }
  }
}

.vertical_wrapper {
  button:not(.viewAll), .nav_item_title, .tab-btn {
    text-transform: capitalize!important;
  }
  .tag_title {
    color: var(--text-tag)!important;
  }
  .gameTag.selected {
    color: var(--tag-primary-selected-border);
  }
  .game_container {
    .game_container_header {
      .viewAll {
        background: var(--background-surface-2);
      }
    }
  }
  .mobile_header_wrapper {
    background-color: var(--background-surface-2);
    .mobile_vertical_menu_button {
      > div {
        background: var(--background-surface-1);
        padding: 5px;
      }
    }
  }
  .modal_search_container, .checkbox-dropdown {
    border-radius: 4px!important;
  }
  .search_mobile_container,.providers_filter_mobile_container {
    margin-top: 8px;
    button {
      background-color: var(--background-surface-2);
      border-radius: 4px
    }
  }
  .backToTopBtn_wrapper {
    button {
      border: 1px solid var(--button-primary);
    }
  }
  .frame {
    display: none;
  }
}

.mobile {
  .vertical_wrapper {
    .game_container {
      .game_container_header {
        .viewAll {
          color: var(--text-primary);
          border: 1px solid var(--button-primary);
          padding: 0 8px;
          border-radius: 4px;
          width: auto;
        }
      }
    }
    .bordered_row .gameTag__label {
      right: unset;
    }
  }
}