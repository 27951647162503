@import '../../../styles/sizes.scss';

.deposit_wrapper {
  margin: 0 5px;
  height: $headerBtnDesktopHeight;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  outline: none;

  button {
    width: $depositDesktopWidth;
    border-radius: 8px;

    p {
      margin: 0;
      text-transform: uppercase;
    }
  }
}

.desktop .deposit_wrapper {
  button {
    height: inherit;
  }
}

.tablet .deposit_wrapper {
  button {
    width: inherit;
  }
}

.mobile .deposit_wrapper {
  width: $depositMobileWidth;
  height: $headerBtnIconMobileHeight;
  margin: 0;
  border-radius: 4px;

  button {
    width: inherit;
    max-width: inherit;
    height: $headerBtnIconMobileHeight;
    font-size: 13px;
    margin: 0 !important;
    margin-right: unset !important;
    padding: 0 3px;
  }
}
