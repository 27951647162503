@import '../../../styles/mixins.scss';
@import '../../../styles/constants';

.currency_select__wrapper {
  .label {
    white-space: nowrap;
    font-size: 14px;
    font-family: Inter-SemiBold;
    text-align: left;
    color: hsla(0, 0%, 100%, 0.8);
    margin-bottom: 0px !important;
    text-transform: capitalize;
    line-height: 1.5;

    span {
      color: red;
    }
  }

  .radio_buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .radio-label {
      font-size: 12px;
      font-family: Inter;
      line-height: 35px;
      text-align: left;
      color: var(--text-secondary);
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      margin-right: 12px;
    }

    .radio-input {
      margin: 0;
      visibility: hidden;
      height: 0px;
    }

    .radio-input:checked + span {
      border: 2px solid #f4b400;
    }

    .radio-input:checked + span:after {
      opacity: 1;
    }

    .custom-radio {
      left: 0px;
      top: 4px;
      cursor: pointer;
      width: 16px;
      height: 16px;
      border: 2px solid #9a9a9a;
      border-radius: 50%;
      margin-right: 5px;
      display: inline-block;
      position: relative;
    }

    .custom-radio::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      background: var(--button-brand);
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
}
