@import '../../../../configs/medias.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/constants';

.desktop,
.tablet {
  .content_with_image_wrapper {
    display: flex;
    position: relative;
    margin: 0;
    padding: 0 10px;

    .content_img {
      width: 388px;
      @include center();

      .img {
        border-radius: 8px;
      }
    }

    .form {
      padding: 50px 0 0 16px;
      width: 388px;
      height: 560px;

      .title {
        font-family: Inter-Bold;
        font-size: 24px;
        line-height: 29px;
        color: var(--text-primary);
        margin-bottom: 12px;
      }
    }
  }

  .content_without_image_wrapper {
    width: 388px;
    padding: 20px 32px;

    .title {
      display: block;
      text-align: center;
      font-family: Inter-Bold;
      font-size: 24px;
      line-height: 29px;
      color: var(--text-secondary);
      margin: 15px auto;
    }

    .without_image__logo-wrapper {
      height: 40px;
      text-align: center;
      max-width: 60%;
      margin: 0 auto;

      img {
        height: 40px;
        text-align: center;
        position: relative;
        object-fit: contain;
        width: fit-content;
      }
    }

    .form_wrapper {
      max-height: 75vh;
    }
  }
}

.mobile {
  .content_with_image_wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
    flex-direction: column;
    background-color: var(--background-surface-1);

    .right_content {
      padding: 0 20px;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      border: 1px solid var(--border-primary);
      background-color: var(--background-surface-1);
      height: 100%;
      border-bottom: none;
      position: relative;
      overflow: hidden;

      .form_wrapper {
        overflow-y: auto;
      }
    }

    @supports (-webkit-touch-callout: none) {
      .right_content {
        .form_wrapper {
          overflow-y: auto;
          padding: 0 8px 40px 0;
        }
      }
    }

    .title {
      font-family: Inter-Bold;
      font-size: 16px;
      text-align: center;
      color: var(--text-secondary);
      padding: 20px 20px 0;
      display: flex;
      justify-content: center;
    }

    .content_img {
      @include center();

      height: 220px;
      position: relative;
      flex-direction: column;
      top: 0;

      .img {
        position: relative;
        width: 100vw;
        height: 220px;
      }
    }

    .form {
      padding: 20px 20px 16px;
      width: 388px;
      overflow: auto;

      .title {
        font-family: Inter-Bold;
        font-size: 24px;
        line-height: 29px;
        color: var(--text-secondary);
        margin-bottom: 12px;
      }
    }
  }

  .content_without_image_wrapper {
    position: absolute;
    // z-index: $auth-modal__content-without-image-wrapper-mobile--index;
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
    background-color: var(--background-surface-1);

    .auth_header {
      @include center();

      background-color: var(--background-surface-2);
      height: 60px;
      padding: 0 15px;
      position: relative;
      width: 100%;
      top: 0;
      margin-bottom: 20px;

      img {
        height: 30px;
      }
    }

    .withoutImage_body {
      padding: 0 25px;
    }

    .auth_mobile_title {
      @include center();

      font-family: Inter-Bold;
      font-size: 16px;
      text-align: center;
      color: var(--text-secondary);
      margin-bottom: 12px;
    }
  }
}

.tablet {
  .content_with_image_wrapper {
    .form {
      padding: 50px 10px 0;
    }

    .signup_form{
      padding: 0 10px;
    }
  }
}
