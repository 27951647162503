.providerBlock_wrapper {
  font-size: 14px;
  font-family: Inter;
  line-height: 17px;
  grid-area: provider_block;
  color: var(--text-secondary);
  margin: 0;
  display: flex;
  align-items: center;
}

.link-to-term-and-conditions {
  text-decoration: underline;
  cursor: pointer;
  font-family: Inter-Bold;
  margin: 0px 4px;
}

.mobile .providerBlock_wrapper {
  width: 100%;
  font-size: 12px;
  line-height: 12px;
  margin: 20px 0;
}
