@import '../../../styles/mixins.scss';
@import '../../../styles/sizes.scss';

.navigation_wrapper {
  height: inherit;
  width: inherit;
  border-radius: 8px;
  background-color: var(--navigation-background);

  .navigation-container {
    display: flex;
    align-items: center;
    height: inherit;
    width: 100%;
    border-radius: 8px;

    &.navigation-container-align {
      justify-content: space-between;
      padding: 0 20px;
    }

    &.navigation-container-center {
      justify-content: flex-start;
      padding: 0 20px;
    }
  }

  &__skeleton {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;

    span {
      background: var(--bg-main-color-4) !important;
      width: 100% !important;
    }
  }
}

.nav_item {
  height: inherit;
  width: fit-content;
  color: var(--navigation-text);
  cursor: pointer;
  white-space: nowrap;
  font-family: Inter-SemiBold;
  font-size: 16px;
  line-height: 19px;

  &--first-children {
    svg {
      g,
      defs,
      path {
        fill: var(--navigation-text);
      }
    }
    &:hover {
      color: var(--navigation-text-hover) !important;
      svg {
        width: auto;
        max-width: 35px;

        g,
        defs,
        path {
          fill: var(--navigation-text-hover) !important;
        }
      }
    }
  }

  &.navItem_placement_right {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin: 0 20px 0 0;

    div {
      margin-left: 5px;
    }

    .nav_icon {
      margin-left: 10px;
    }
  }

  &.navItem_placement_bottom {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;

    div {
      margin-top: 3px;
    }

    .nav_icon {
      margin-top: 3px;
    }
  }

  &.with_label {
    position: relative;

    .label-image {
      position: absolute;
      right: 0;
      top: 0px;
      max-height: 16px;
    }
  }

  .navItem_divider {
    // divider as a part of text
    background-color: var(--text-primary);
    opacity: 0.3;
    height: 30px;
    width: 2px;
    position: absolute;
    right: -15px;
  }

  &.navItem_placement_left {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 20px 0 0;

    &.md_icon {
      div svg {
        height: 24px;
      }
    }

    &:not(md_icon) {
      div {
        margin-right: 5px;

        svg {
          height: $category_svg_height;
          width: auto;
          max-width: 35px;

          g,
          rect,
          path {
            fill: var(--navigation-text);
          }
        }
      }
    }

    .nav_icon {
      margin-right: 10px;
    }
  }

  &.navItem_placement_top {
    height: inherit;
    display: flex;
    align-items: center;
    margin: 0 2vw 0 0;

    .more_nav_item {
      .more_icon {
        height: 42px;

        rect,
        path {
          fill: var(--navigation-text);
        }
      }
    }

    img,
    svg {
      margin-bottom: 10px;
    }

    svg {
      height: 33px;
      width: 33px;

      g,
      defs {
        fill: var(--icon-color-secondary);
      }
    }

    &.underlined {
      height: unset;

      span {
        &.nav_item--first-children {
          display: flex;
          align-items: center;
          height: calc(100% - 3px);
          min-width: 100%;
          position: relative;
        }
      }
    }

    span {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--text-secondary); //TODO correct work only for viva skin
      font-size: 14px;
      font-family: Inter;
      .nav_icon {
        margin-right: 10px;
      }
      &:hover {
        color: var(--text-primary-hover);
      }
    }
  }

  &.selected {
    color: var(--navigation-text-hover);

    div {
      svg {
        path,
        g {
          fill: var(--text-primary);
        }
      }
    }
  }

  &.underlined {
    display: flex;
    flex-direction: column;

    span {
      &.nav_item--first-children {
        display: flex;
        align-items: center;
        height: calc(100% - 3px);
        min-width: 100%;
        position: relative;
        &:hover {
          // span {
          //   color: var(--text-secondary);
          // }

          svg {
            width: auto;
            max-width: 35px;

            g,
            defs {
              fill: var(--text-secondary);
            }
          }
        }
      }
    }

    .navItem_active {
      height: 3px;
      background-color: var(--text-brand);
      width: 100%;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      position: relative;
      bottom: -5px;
    }

    &:last-child {
      .navItem_active {
        margin-left: 5px;
      }
    }
  }

  &.colored {
    &:hover {
      color: var(--text-brand);
      @include svg-color(var(--icon-color-secondary));

      .nav_icon,
      svg path {
        color: var(--icon-color-secondary);
      }
    }

    .nav_icon {
      font-size: 19px;
    }
  }

  .nav_icon {
    font-size: 19px;
  }
}

.dropdown-nav-menu {
  position: relative;
  margin-right: 0 !important;

  &.navItem_placement_top {
    .more {
      margin-top: 0px;
    }
  }

  .more {
    margin-top: 3px;
  }

  .navItem_active {
    position: absolute !important;
    bottom: 0;
    &:hover {
      color: var(--text-primary-hover);
      svg {
        g,
        defs,
        path {
          fill: var(--text-primary-hover);
        }
      }
    }
  }

  .nav_item_more_container {
    height: inherit;
    display: flex;
    align-items: center;

    svg {
      height: $category_svg_height;
      width: auto;
      max-width: 35px;

      g,
      path,
      rect {
        fill: var(--icon-color-secondary);
      }
    }
  }

  .dropdown-menu-list {
    position: absolute;
    top: 0px;
    right: 0;
    height: fit-content;
    width: auto;
    list-style-type: none;
    margin: 0;
    padding: 4px 0px 0px;
    opacity: 0;
    background: transparent;
    visibility: hidden;
    margin-right: 0px !important;

    &__items {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background: var(--background-surface-3);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 12px 8px;
      gap: 4px;
    }

    .nav_item {
      background: var(--background-surface-2);
      border-radius: 4px;
      padding: 10px;
      min-width: 171px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 0px !important;
      & div {
        width: auto !important;
      }
      &.selected {
        background: var(--background-surface-1);
      }
    }
  }

  &.open > .dropdown-menu-list {
    opacity: 1;
    visibility: visible;
    top: 100%;
    -webkit-transition: all 0.3s, background, 0.5s 0.5s linear;
    transition: all 0.3s, background 0.5s 0.5s linear;
  }
}

.user_balance {
  svg path {
    fill: var(--text-primary);
  }
}

// medias
.desktop {
  .dark-light-theme-1 {
    .entries_table_caption,
    .login_history_container {
      border-radius: 30px;
    }

    .entries_table_caption__item {
      color: var(--text-brand);
    }

    .login_history_container {
      border: 1px solid var(--border-primary-selected);
    }

    .navigation_wrapper {
      .navigation_wrapper__skeleton {
        background-color: var(--background-surface-2);
        min-width: 80%;
      }
    }
  }
}

.mobile .navigation_wrapper {
  padding: 0 40px;
}
