@import '../../styles/sizes.scss';
@import '../../styles/mixins.scss';
@import '../../styles/constants';

.desktop .footer_container,
.footer_container_inner {
  padding: 20px;

  &.footer_horizontal_default {
    padding: 25px;
    position: relative;
    margin: 0 auto;
    display: grid;
    grid-template-areas:
      'logo logo bottom_navigation bottom_navigation bottom_navigation bottom_navigation bottom_navigation'
      'gamble_aware licence provider_block provider_block provider_block provider_block provider_block'
      'ageInfo ageInfo terms_brief_info_block terms_brief_info_block terms_brief_info_block terms_brief_info_block terms_brief_info_block'
      'social_icons social_icons operator_block operator_block operator_block operator_block operator_block'
      'back_to_top back_to_top back_to_top back_to_top back_to_top back_to_top back_to_top'
      'copyright copyright copyright copyright copyright copyright copyright';
    gap: 0 20px;
    row-gap: 20px;
  }

  &.footer_vertical_default {
    @include center();

    padding: 20px 40px 60px;
    flex-wrap: wrap;
    border-top: 2px solid hsla(33, 47%, 93%, 0.169);

    .ageInfo_container {
      max-width: 650px;
    }

    .bottom_navigation_wrapper {
      justify-content: center;
    }

    .social_networks_container,
    .gambleAware_wrapper {
      margin: 20px 60px;
    }
  }
}

@keyframes footerUp {
  from {
    height: 300px;
  }

  to {
    height: calc(100vh - 16px);
  }
}

// TO DO if it crash in other templates we must add template rules
.footer_with_btn {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: $footer__footer-with-btn--index;
  margin: 0 auto;

  &.open {
    z-index: $footer__footer-with-btn-open--index;
    width: 100vw;
    left: 0;
    right: 0;
  }

  .footer_icon_btn {
    background-color: var(--button-primary);
    border-radius: 0 0 3px 3px;
    cursor: pointer;
    margin: 1px auto 0;
    transform: rotate(180deg);
    width: 56px;
    height: 16px;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;

    svg path {
      fill: white;
    }

    div {
      width: inherit;
      height: inherit;
    }

    &.rotateArrow {
      position: absolute;
      transform: rotate(0);
      border-radius: 3px 3px 0 0;
      z-index: $icon-index;
      left: 0;
      right: 0;
      top: 5px;
    }
  }

  .footer_container_with_btn {
    height: 0;
    max-height: 0;
    transition: all 0.8s ease-in-out;
    overflow: hidden;
    box-sizing: border-box;
    background-color: var(--footer-background);

    .footer_container_inner {
      padding: 0 24px;
      color: var(--text-primary);
      font-family: Inter;
      line-height: 16px;
      padding: 20px;

      &.footer_template_3 {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }

    &.show {
      background-color: var(--footer-background);
      height: 100vh;
      max-height: 100vh;
      overflow-y: auto;
      padding-top: 22px;

      @include scrollbar();
    }
  }
}

// TO DO if it crash in other templates we must add template rules
.template_1 .footer_with_btn {
  max-width: $maxWidth;
}

.footer_outer_container {
  background-color: var(--footer-background);
  color: var(--text-primary);
  font-family: Inter;
  line-height: 16px;

  &.footer_template_3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: $maxWidth;
    margin: 0 auto;
  }

  .footer_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 26px 0;
    border-bottom: 1px solid #262728;
    line-height: 20px;

    img {
      &:nth-child(1) {
        height: 41px;
        width: 98px;
      }
    }
  }
}

.mobile_footer_container {
  background-color: var(--background-surface-2);

  &.footer_left_alignement {
    .footer_row {
      display: flex;
      width: 100%;

      .logo {
        margin: 0 auto;
      }

      &:first-child {
        flex-direction: row;
        flex-wrap: wrap;
        padding: 20px 10px;
      }

      &:last-child {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        // justify-content: space-between;
        justify-content: flex-end;
        padding-left: 10px;
      }
    }
  }

  &.footer_center_alignement {
    .footer_wrapper {
      padding: 10px 0 50px;

      .footer_row {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 10px;

        &:nth-child(3) {
          flex-direction: row;
          justify-content: space-around;
        }
      }
    }
  }
}

.tablet {
  .tablet_outer_container {
    .footer_container,
    .footer_container_inner {
      padding: 0 24px;

      &.footer_horizontal_default {
        display: grid;
        grid-template-areas:
          'logo logo logo'
          'bottom_navigation bottom_navigation bottom_navigation'
          'provider_block provider_block provider_block'
          'terms_brief_info_block terms_brief_info_block terms_brief_info_block'
          'operator_block operator_block operator_block'
          'gamble_aware licence ageInfo'
          'social_icons social_icons back_to_top'
          'copyright copyright copyright';
        row-gap: 30px;
      }

      &.footer_vertical_default {
        @include center();

        padding: 20px 40px 60px;
        flex-wrap: wrap;
        border-top: 2px solid hsla(33, 47%, 93%, 0.169);

        .ageInfo_container {
          max-width: 650px;
        }

        .bottom_navigation_wrapper {
          justify-content: center;
        }

        .social_networks_container,
        .gambleAware_wrapper {
          margin: 20px 60px;
        }
      }
    }
  }
}
