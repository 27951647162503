@import '../../../styles/sizes.scss';
@import '../../../styles/constants';

.logo {
  cursor: pointer;
  grid-area: logo;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: $icon-index;

  img,
  svg {
    width: inherit;
    height: inherit;
  }
}

// header logo
.desktop .desktop_header .logo {
  max-height: $logoDesktopHeight;
  margin-right: 10px;

  img {
    height: inherit;
    max-height: $logoDesktopHeight;
    width: 100%;
  }
}
@media screen and (min-width: 1600px) {
  .desktop .desktop_header .logo-closed-bar {
    img {
      width: 50px;
      position: absolute;
      left: 20px;
    }
  }
}

.hide-logo {
  display: none;
}

.desktop .desktop_header .logo-closed-bar {
 margin-left: -10px!important;
}

// footer logo
.desktop .footer_container .logo img,
.desktop .footer_container_inner .logo img {
  height: 43px;
}

.mobile .mobile_footer_container .logo img {
  max-height: 35px;
  margin: 0;
}

.mobile .logo img {
  max-height: 40px;
  margin: 0;
  height: 38px;
}

.tablet .logo img {
  height: 32px;
}
