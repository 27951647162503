@import '../../../../../styles/constants';

.failure-reg-container {
  .dialog-template {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // z-index: $registration-failure__container-dialog-template--index;
    z-index: $icon-index;
  }

  .description-wrapper {
    position: relative;
    background: var(--bg-main-color-0);
    display: flex;
    height: 539px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 375px;
    color: var(--text-primary);

    .text {
      width: 80%;
      text-align: center;
      color: var(--text-primary);
      font-family: Inter-Medium;
    }
    .btn_wrapper {
      position: absolute;
      bottom: 50px;
    }
    .title {
      width: 80%;
      text-align: center;
      font-family: Inter-Bold;
      font-size: 20px;
      font-style: normal;
      line-height: normal;
      padding-bottom: 12px;
    }
  }
  .success-filled-btn {
    max-width: 319px;
    display: flex;
    width: 351px;
    height: 44px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    font-family: Inter-SemiBold;
  }
  .icon-httpslock {
    color: red;
    padding: 12px;
    font-size: 22px;
  }
  .icon-icon-x {
    font-size: 24px;
    color: #313341;
    position: absolute;
    top: 12px;
    right: 12px;
    &:hover {
      transform: scale(1.2);
      transition: 0.3s;
    }
  }
}
