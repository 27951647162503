.successfully_reged_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;

  .title {
    font-family: Inter-Medium;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: var(--text-secondary);
  }

  .tab_container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;

    .tab {
      .step {
        width: 30px;
        height: 30px;
        background: var(--text-primary);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-family: Inter-SemiBold;
        color: var(--text-primary);
      }
      .title {
        font-family: Inter-SemiBold;
        font-size: 14px;
        text-align: center;
        color: var(--text-primary);
        margin-top: 6px;
      }

      &.selected,
      &.done {
        .step {
          background: var(--button-primary);
          color: var(--text-secondary);
        }

        .title {
          color: var(--text-secondary);
        }
      }
    }
  }

  .success_title {
    font-size: 20px;
    font-style: normal;
    font-family: Inter-Bold;
    line-height: normal;
    text-align: center;
    margin-top: 12px;
    color: var(--text-secondary);
  }

  .reg_success_container {
    margin: 28px 0;
    display: block;

    p {
      font-family: Inter-Medium;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      font-style: normal;
      color: var(--text-secondary);
    }
  }

  .buttons_container {
    .primaryButton {
      width: 100%;
      height: 51px;
      border-radius: 10px;
      border: none;
      font-size: 16px;
      text-transform: uppercase;
      margin: 15px auto 10px;

      &:disabled {
        background: var(--background-surface-3);
        color: var(--text-primary);
      }
    }
  }
}

@media (max-width: 480px) {
  .buttons_container {
    .primaryButton {
      margin: 10px 0;
    }
  }
}
