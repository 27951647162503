@import '../../../styles/mixins';

.typography {
  &_variant {
    &_inherit {
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
    }

    &_subtitle1 {
      font-size: 1.5rem;
      font-family: Inter-Medium;
      line-height: 1.4;
    }

    &_subtitle2 {
      font-size: 1.125rem;
      font-family: Inter-Medium;
      line-height: 1.4;
    }

    &_subtitle3 {
      font-size: 1rem;
      font-family: Inter-Medium;
      line-height: 1.4;
      margin-bottom: 0;
    }

    &_caption {
      font-size: 0.625rem;
      font-family: Inter;
      line-height: 1.3;
    }

    &_body1 {
      font-size: 1.125rem;
      font-family: Inter;
      line-height: 1.5;
    }

    &_body2 {
      font-size: 1rem;
      font-family: Inter;
      line-height: 1.5;
    }

    &_body3 {
      font-size: 0.875rem;
      font-family: Inter;
      line-height: 1.5;
      margin: 0;
    }

    &_body4 {
      font-size: 12px;
      font-family: Inter;
      margin: 0;
    }

    &_body5 {
      font-size: 11px;
      font-family: Inter-Medium;
      line-height: 1.5;
    }

    &_h1 {
      font-size: 2.5rem;
      font-family: Inter-Bold;
      line-height: 1.4;
    }

    &_h2 {
      font-size: 2rem;
      font-family: Inter-Bold;
      line-height: 1.4;
    }

    &_h3 {
      font-size: 1.2rem;
      font-family: Inter-Bold;
      line-height: 1.4;
    }

    &_h4 {
      font-size: 1rem;
      font-family: Inter-Bold;
      margin: 0;
    }

    &_h5 {
      font-size: 0.8rem;
      font-family: Inter-Bold;
      line-height: 1.4;
      margin: 0;
    }

    &_h6 {
      font-size: 0.6rem;
      font-family: Inter-Bold;
      line-height: 1.4;
      margin-bottom: 0;
    }
  }

  &_color {
    &_error {
      color: red;
      font-size: 12px;
      margin: 10px 0;
    }

    &_white {
      color: #fff;
    }

    &_grey {
      color: #a2a2a2;
    }
  }

  &_uppercase {
    text-transform: uppercase;
  }

  &_capitalize {
    text-transform: uppercase;
  }
}
