@import '../../../../../../../styles/sizes.scss';
@import '../../../../../../../styles/mixins.scss';
@import '../../../../../../../configs/medias.scss';

.info_container {
  @include scrollbar();

  display: block;
  margin: 0 auto;
  overflow: auto;
  height: 100%;

  .loader_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    //account modal  height - header and paddings
    height: 70vh;
  }
}

@media (max-width: $tablet) {
  .info_container {
    color: var(--text-secondary);
    padding: 10px 20px 50px;
    overflow: auto;
    // for some apple devices which can use svh
    max-height: 90vh;
    max-height: 90svh;

    p,
    ul {
      width: 95%;
      margin: 0 auto 25px;
      text-align: initial;
      font-family: Inter;
      font-size: 14px;
      line-height: 25px;
      padding: 0;
    }
  }
}

@media (min-width: 481px) and (max-width: 1023px) {
  .info_container {
    width: inherit;
    padding: 0;

    p,
    ul {
      width: inherit;
    }
  }
}
