@import '../../../styles/sizes.scss';
@import '../../../styles/constants';

.simple_search_container {
  height: 43px;
  width: 50%;
  background: transparent;
  display: flex;
  border: 1px solid var(--search-border);
  border-radius: 6px;

  .simple_search_container_btn {
    width: 100%;

    input::placeholder {
      font-family: Inter-SemiBold;
      font-size: 14px;
    }
  }

  .icon {
    position: relative;
    right: 35px;
    top: 10px;
    cursor: pointer;

    svg path {
      fill: var(--text-primary);
    }

    div {
      position: relative;
      right: -46px;
      top: -4px;
    }
  }
}

.modal_search_container {
  height: $search_container_height;
  width: 100%;
  margin: 25px 0;
  display: flex;
  border: 1px solid var(--search-border);
  border-radius: 10px;
  background-color: var(--search-background-surface-1);
  position: relative;
  z-index: $modal-index;

  &.focused {
    border: 1px solid var(--search-border-selected);
    z-index: $focused-modal-index;
  }

  .icon_wrapper {
    width: 63px;
    height: calc($search_container_height - 2px);
    background-color: var(--search-background-surface-2);
    border-bottom-left-radius: inherit;
    border-top-left-radius: inherit;
    margin-left: 0;

    .icon {
      position: relative;
      right: 35px;
      top: 13px;
      cursor: pointer;

      svg path {
        fill: var(--text-primary);
      }

      div {
        position: relative;
        right: -34px;
        top: -3px;
      }
    }
  }

  .clear-icon_wrapper {
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translateY(-50%);
    cursor: pointer;

    svg path {
      fill: var(--text-primary);
      transition: 0.35s ease-in-out;
    }

    &:hover {
      svg path {
        fill: var(--text-primary);
      }
    }
  }
}

.search_game_input {
  width: 100%;
}

.search_modal_container {
  color: var(--text-primary);
  background: var(--background-surface-2);
  border: 1px solid var(--border-primary);
  border-radius: 10px;
  padding: 20px;
  position: relative;
  min-height: 180px;
  z-index: $focused-modal-index;
  display: flex;
  flex-wrap: wrap;
  width: inherit;

  .content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .title {
    font-style: normal;
    font-family: Inter-SemiBold;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-primary);
    width: inherit;
    margin: 0 0 10px;

    span {
      margin-left: 5px;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 28px;

    .subTitle {
      font-style: normal;
      font-family: Inter-SemiBold;
      font-size: 14px;
      line-height: 17px;
      color: var(--text-primary);
      margin-bottom: 20px;
    }

    .result_search_icon {
      svg {
        height: 54px;
        width: 54px;

        path {
          fill: var(--text-primary);
        }
      }
    }
  }
}

.tablet .search_container {
  height: $search_container_height;
  width: 300px;
  margin: 20px 0;
  background: var(--background-surface-3);
  display: flex;
  border: 1px solid var(--search-border);
  border-radius: 10px;

  input {
    border: none;
    color: var(--text-primary);
    font-size: 16px;
    padding: 0 20px;
    width: inherit;
    height: inherit;
    background-color: transparent;

    &::placeholder {
      font-style: normal;
      font-family: Inter-SemiBold;
      font-size: 14px;
      line-height: 17px;
      color: var(--text-primary);
    }
  }

  .icon_wrapper {
    width: 63px;
    height: 37.5px;
    background-color: var(--background-surface-3);
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    margin-right: -1px;

    .icon {
      position: relative;
      right: 35px;
      top: 10px;
      cursor: pointer;

      div {
        position: relative;
        right: -36px;
        top: -3px;
      }
    }
  }
}

.mobile {
  .search_mobile_container {
    width: 15%;
    border-radius: 8px;

    button {
      height: $search_btn_size;

      svg {
        height: 18px;
        width: 19px;
      }
    }
  }
}
