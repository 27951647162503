// font-weight: 100
@font-face {
  font-family: 'Inter-Thin';
  src: url('/fonts/Inter-Thin.ttf');
}
// font-weight: 200
@font-face {
  font-family: 'Inter-ExtraLight';
  src: url('/fonts/Inter-ExtraLight.ttf');
}
// font-weight: 300
@font-face {
  font-family: 'Inter-Light';
  src: url('/fonts/Inter-Light.ttf');
}
// font-weight: 400
@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Regular.ttf');
}
// font-weight: 500
@font-face {
  font-family: 'Inter-Medium';
  src: url('/fonts/Inter-Medium.ttf');
}
// font-weight: 600
@font-face {
  font-family: 'Inter-SemiBold';
  src: url('/fonts/Inter-SemiBold.ttf');
}
// font-weight: 700
@font-face {
  font-family: 'Inter-Bold';
  src: url('/fonts/Inter-Bold.ttf');
}
// font-weight: 800
@font-face {
  font-family: 'Inter-ExtraBold';
  src: url('/fonts/Inter-ExtraBold.ttf');
}
// font-weight: 900
@font-face {
  font-family: 'Inter-Black';
  src: url('/fonts/Inter-Black.ttf');
}
