@import '../../../../styles/constants';

.zendesk_chat_conatiner {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  left: 15px;
  background-color: transparent;
  border: none;
  padding: 0;
  // z-index: $zendesk-chat__conatiner--index;
  z-index: $chat-index;

  .chat_icon {
    width: 80px !important;
    height: 80px !important;
  }
  .zendesk_icon {
    border-radius: 50%;
    padding: 22px;
    transform: rotateY(180deg);
  }
}
