@import '../../../styles/constants/';
@import '../../../styles/mixins';

.error {
  font-size: 12px;
  color: var(--red);
  position: relative;
  top: -9px;
  left: 4px;
}

.info {
  display: block;
  width: 100%;
  border: 1px solid var(--border-primary);
  border-radius: 10px;
  background-color: var(--background-surface-2);
  color: var(--text-secondary);
  height: 134px;
  margin-bottom: 10px;

  &_section {
    padding: 12px 12px 10px;

    &:nth-child(1) {
      border-bottom: 1px solid var(--border-primary);
      padding: 10px 12px 12px;
    }

    .info_label {
      font-family: Inter;
      font-size: 14px;
      color: var(--text-secondary);
      margin-bottom: 8px;
    }

    .info_value {
      font-family: Inter;
      font-size: 16px;
      line-height: 19px;
      color: var(--text-secondary);
      text-transform: uppercase;
    }
  }
}

.cpf__input {
  height: 64px;
  margin-bottom: 16px;

  input {
    display: block;
    width: 100%;
    font-size: 12px;
    font-family: Inter;
    line-height: 35px;
    padding: 0 0 0 8px;
    background-color: var(--background-surface-2);
    border: 1px solid var(--border-primary);
    border-radius: 10px;
    height: 40px;
    color: var(--text-secondary);
  }
}

.mobile {
  .info {
    display: none;
  }
}
