.tab-btn {
  background-color: var(--tag-primary);
  color: var(--text-tag);
  border: 1px solid var(--tag-primary-border);

  &.selected {
    border: 1px solid var(--tag-primary-selected-border);
    background-color: var(--tag-primary-selected);
  }
}

.tab-image-btn {
  border: 1px solid var(--tag-primary-border);
  background-color: var(--tag-primary);
  color: var(--text-tag);

  svg path {
    fill: var(--text-tag);
  }

  &.selected {
    border: 1px solid var(--tag-primary-selected-border);
    background: var(--tag-primary-selected);
  }

  &.active {
    border: 1px solid var(--tag-primary-selected-border);
  }
}

.desktop,
.tablet {
  .tab-btn,
  .tab-image-btn {
    &:hover {
      border: 1px solid var(--tag-primary-hover-border);
      background-color: var(--tag-primary-hover);
    }
  }
}
