@import '../../../../../styles/sizes.scss';
@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/constants';

.desktop .provider_dropdown_view,
.tablet .provider_dropdown_view {
  @include center();

  z-index: $provider-filter__provider_dropdown--index;

  .checkbox-dropdown {
    border-radius: 10px;
    background-color: var(--background-surface-2);
    border: 1px solid var(--border-primary);
    border-radius: 10px;
    width: 100%;
    position: relative;
    margin: 0 auto;
    user-select: none;
    height: 45px;
    cursor: pointer;

    .provider_title {
      font-size: 14px;
      color: var(--text-primary);
      margin: 0;
      padding: 10px;
    }

    .selected_providers_wrapper {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin-top: 1px;
      align-items: center;

      .selected_providers_title {
        display: flex;
        align-items: center;
        margin: 0;

        .count_badge {
          @include center();
          height: 24px;
          border-radius: 50%;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          color: var(--text-secondary);
        }
      }
    }

    svg path {
      fill: white;
    }

    &-icon {
      &:after {
        content: '';
        height: 0;
        position: absolute;
        width: 0;
        border: 6px solid transparent;
        border-top-color: white;
        top: 50%;
        right: 10px;
        margin-top: -3px;
      }
    }

    &.is-active {
      .checkbox-dropdown-list {
        @include scrollbar();

        opacity: 1;
        pointer-events: auto;
        z-index: $provider-filter__active-checkbox-dropdown-list--index;
        margin-top: 5px;
        border-radius: 10px;
        background-color: var(--background-surface-2);
      }

      &:after {
        border-bottom-color: white;
        border-top-color: transparent;
        margin-top: -9px;
      }

      .provider_search_no_result {
        @include center();

        height: 74%;
      }
    }

    .checkbox-dropdown-list {
      @include scrollbar();

      list-style: none;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 100%;
      border: inherit;
      border-top: none;
      left: -1px;
      right: -1px;
      opacity: 0;
      transition: opacity 0.4s ease-in-out;
      height: 40vh;
      overflow: scroll;
      overflow-x: hidden;
      pointer-events: none;

      .search_wrapper {
        height: 40px;
        border: 1px solid var(--border-primary);
        border-radius: 4px;
        margin: 13px 8px 0;

        .search_icon {
          position: relative;

          svg {
            height: 20px;
          }
        }

        input {
          background-color: transparent;
          border: none;
          margin-left: 8px;
          width: 100%;
          color: var(--text-secondary);
        }
      }

      .container {
        display: block;
        position: relative;
        padding-left: 25px;
        cursor: pointer;
        font-size: 10px;
        user-select: none;
        text-transform: uppercase;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;

          &:checked ~ .checkmark:after {
            display: block;
          }

          &:checked ~ .checkmark {
            background-color: #8cd582;
            border: 2px solid #8cd582;

            &::after {
              @include center();

              content: '\2713';
            }
          }
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 16px;
          width: 16px;
          background-color: transparent;
          border: 2px solid var(--border-primary);
          border-radius: 2px;
        }
      }

      li {
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--border-primary);
        padding: 10px;
        height: 46px;
        position: relative;

        label {
          display: flex;
          transition: all 0.2s ease-out;
          color: white;
          margin: 0 5px 0 0;

          input[type='checkbox'] {
            margin-right: 10px;
          }
        }

        .provider_image_wrapper {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
        .provider_image_wrapperForLightTheme {
          fill: black;
        }
        .checkbox_label {
            position: absolute;
            text-align: left;
            padding-left: 28px;
        }
      }
    }
  }
}
