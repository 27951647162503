.footer_logos_container {
  gap: 30px;
  margin: 20px auto;
  width: 100%;
  justify-content: center;
  display: flex;
  >:first-child {
    img {
      cursor: pointer;
    }
  }
  img {
    max-width: 130px;
  }
}
