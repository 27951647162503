@import '../../../../styles/sizes.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/constants';

.mobile_menu_container {
  text-align: center;
  background: var(--background-surface-1);
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // z-index: $mobile-menu__container--index;
  z-index: $mobile-full-page-widgets-index;
  visibility: visible;
  position: fixed;
  overflow: auto;

  .logo {
    height: 30px;
    position: relative;
    top: 15px;
    display: flex;
    justify-content: center;
  }

  .menu_icon_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid var(--border-primary);
    height: 60px;

    .title {
      display: flex;
      justify-content: space-between;
      width: $modal_title_view;
      align-items: center;
      font-family: Inter-SemiBold;
      font-size: 16px;
      line-height: 17px;
      color: var(--text-primary);
      margin: 0;
    }

    .close_menu_wrapper {
      @include center();

      background: var(--background-surface-3);
      border-radius: 50%;
      height: $close_menu_wrapper_size;
      width: $close_menu_wrapper_size;

      & > div {
        margin: 0;
      }
    }
  }

  .menu_content_wrapper {
    padding: 5px 15px 15px;
    height: calc(100% - 90px);
    overflow-x: hidden;

    .promotions_section {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
      flex-wrap: wrap;

      .promotions_section_btn {
        display: flex;
        height: auto;
        padding: 0;
        justify-content: center;

        //TO DO: FIX THIS LOGIC
        &:nth-child(1),
        &:nth-child(2) {
          flex: 0 0 50%;
        }

        &:nth-child(3) {
          flex: 1 0 100%;
          margin-top: 6px;
        }
      }

      img {
        height: 60px;
      }
    }

    .refer_a_friend_section {
      margin: 6px 0 15px;
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 70px;
      background-repeat: no-repeat;
      background-size: cover;

      .referAFriend {
        background-image: url('./PT.webp');
        background-size: contain;
      }

      button {
        display: flex;
        justify-content: center;
        padding: 0;
        position: absolute;
        color: var(--button-primary-text);
        background-repeat: no-repeat;
        background-size: cover;
        height: -webkit-fill-available;

        svg {
          margin-right: 8px;
        }
      }

      img {
        height: 16vw;
        margin: 5px;
      }
    }

    .accordion {
      // position: relative;
      cursor: pointer;
      // padding: 0;
      width: 100%;
      text-align: left;
      outline: none;
      font-size: 16px;
      height: 45px;
      color: var(--text-primary);
      transition: 0.4s;
      display: flex;
      justify-content: space-between;
      font-family: Inter-Bold;
      align-items: center;
      background-color: var(--background-surface-2);
      border: 1px solid var(--border-primary);
      border-radius: 4px;
      margin: 5px 0;
      padding: 0 10px;

      .inner_accordion {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      &.lg-item {
        height: 53px;
      }

      img {
        filter: brightness(0) invert(1);
        height: 25px;
      }

      div {
        display: flex;
        align-items: center;

        img {
          margin: 0 15px 0 0 !important;
        }

        .category_icon {
          margin-right: 10px;

          svg {
            fill: var(--text-primary);
            height: 24px;
            width: 24px;

            path,
            g,
            defs {
              fill: var(--text-primary);
            }
          }
        }
      }

      .label {
        margin: unset !important;
        filter: unset;
        max-height: 16px;
        margin-left: 15px !important;
      }

      .menu_content_text {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .lngBar {
        img {
          border-radius: 50%;
          width: 21px;
          height: 20px;
          object-fit: fill;
          margin-right: 4px!important;
        }
      }

      span {
        margin-right: 10px;
      }

      .arrow {
        transform: rotate(0deg);
        margin-right: 0;
      }

      .rotateArrow {
        transform: rotate(180deg);
        border-radius: 3px 3px 0 0;
      }

      .promotions {
        text-transform: uppercase;

        span {
          margin-right: 10px;
          color: var(--text-brand);
        }

        .btn_promotion_icon {
          font-size: 14.5px;
          margin-right: 10px;
        }
      }
    }
  }

  .underLiner {
    width: 100%;
    margin: 12px 0px;
    border-bottom: 1px solid var(--border-primary);
  }

  .panel {
    padding: 0 18px;
    overflow: hidden;
    list-style: none;
    text-align: initial;
    padding-left: 25px;
    background-color: var(--background-surface-2);
    border: 1px solid var(--border-primary);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin: -7px 0 5px;
    padding: 0 0 0 15px;

    li {
      height: 37px;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      align-items: center;
      display: flex;
      color: var(--text-secondary);
      cursor: pointer;
    }
  }
}
.mobile_menu_vertical {
  background-color: var(--background-surface-2);
  .accordion {
    background-color: var(--button-secondary)!important;
  }
  .menu_icon_wrapper {
    .close_menu_wrapper {
      background: var(--background-surface-1);
      border-radius: 16px;
      color: var(--text-secondary);
    }
  }
}

@media (max-width: 480px) {
   .mobile_menu_container {
     .lngBar {
       ul {
         padding: 5px 6px;
         width: 93px;
       }
     }
   }
 }
