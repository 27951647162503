.featured_slots_wrapper {
  .game_container {
    background-color: transparent !important;
    padding: 0;

    .game_container_header {
      padding: 30px 0 0;
    }
  }
}

.mobile {
  .featured_slots_wrapper {
    padding: 0 0 0 12px;
  }
}
