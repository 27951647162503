@import '../../../styles/mixins.scss';

.gameCare_container {
  grid-area: gamble_care;

  img {
    cursor: pointer;
  }
}

.gameCare {
  background-color: white;
  color: var(--main-text-color-4);
  padding: 10px 20px;
  overflow: hidden;

  .game_care_header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dadada;
    padding: 10px 0;

    .game_care_close {
      border-radius: 50%;
      background: var(--background-surface-3);
      cursor: pointer;
    }

    .gameCare_title {
      font-family: Inter-Bold;
      font-style: normal;
      margin: 0 auto;
      line-height: 24px;
      color: var(--main-text-color-4);
      overflow: hidden;
    }
  }

  .gameCare_content {
    @include lightScrollbar();

    color: var(--main-text-color-4);
    overflow-y: auto;

    p {
      margin: 20px 0 0 0;
      line-height: 26px;
    }

    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 10px 0 0 0;
    }

    li {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -15px;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--background-surface-1);
      }
    }
  }
}

.gam-care {
  &__contacts-wrapper {
    border-radius: 6px;
    font-family: Inter-Bold;
    width: 80%;
    margin: 20px auto 0 auto;
    background-color: #efefef;

    @media screen and (max-width: 915px) {
      width: 100%;
    }
  }

  &__contact {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & + .gam-care__contact {
      border-top: 1px solid var(--main-text-color-3);
    }
  }

  &__contact-value {
    margin-left: 6px;
  }
}

@media (max-width: 480px) {
  .gameCare {
    font-size: 12px;

    .game_care_header {
      .game_care_close {
        height: 20px;
        width: 20px;

        @include svg-size(20px);
      }
    }

    .gameCare_title {
      font-size: 16px;
    }

    .gameCare_content {
      font-size: 12px;
      max-height: 60vh;

      ul {
        li {
          line-height: 14px;
          margin-bottom: 10px;
        }
      }
    }
    .gameCare_content_android {
      max-height: 72vh;
    }
  }
}

@media (min-width: 481px) and (max-width: 1200px) {
  .gameCare {
    width: 70vw;
    min-height: 50vh;
    max-width: 950px;
    min-width: 310px;
    max-height: 80%;
    border-radius: 10px;
    font-size: 20px;

    .game_care_header {
      .game_care_close {
        height: 15px;
        width: 15px;

        @include svg-size(15px);
      }
    }

    .gameCare_title {
      font-size: 30px;
    }

    .gameCare_content {
      font-size: 18px;
      max-height: 73vh;

      ul {
        li {
          line-height: 20px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media (min-width: 1201px) {
  .gameCare {
    min-height: 80vh;
    min-width: 310px;
    width: 900px;
    font-size: 20px;

    .gam-care {
      .game_care_close {
        height: 24px;
        width: 24px;

        @include svg-size(24px);
      }
    }

    .gameCare_title {
      font-size: 20px;
    }

    .gameCare_content {
      font-size: 18px;
      max-height: 80vh;

      ul {
        li {
          line-height: 20px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
