.light_theme {
  .providerList_container {
    padding: 25px 10%;
    
    .provider img {
      background-color: #7d7e84;
    }
  }

  .advertisementList_container img:first-child,
  .awards_container img:nth-child(4),
  .ageInfo_container img,
  .gambleAware_wrapper img,
  .gameCare_container img {
    filter: invert(1);
  }
}
