@import '../../../../styles/constants';
@import '../../../../configs/medias.scss';

.modalHeader {
  position: absolute;
  width: 100%;
  padding: 20px 65px 0;

  &_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 24px;
    right: 24px;
    height: 30px;
    width: 30px;
    background: var(--background-surface-3);
    border-radius: 50%;
    // z-index: $modal-header__icon--index;
    z-index: $icon-index;
  }

  &_title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter-Bold;
    font-size: 24px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
  }
}

@media (min-width: $laptop) {
  .modalHeader {
    padding: 20px 65px 0;
  }
}

@media (min-width: $tablet) and (max-width: $laptopMin) {
  .modalHeader {
    padding: 15px 45px 0;
  }
}

@media (max-width: $tabletMin) {
  .modalHeader {
    padding: 20% 20px 0;

    &_title {
      font-size: 16px;
      text-align: center !important;
    }

    &_icon {
      right: 15px;
      top: 20%;
    }
  }
}
