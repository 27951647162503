@import '../../../../styles/sizes.scss';
@import '../../../../styles/constants';
@import '../../../../styles/mixins.scss';

.provider_container {
  text-align: center;
  background: var(--background-surface-1);
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: $mobile-full-page-widgets-index;
  visibility: visible;
  position: relative;
  overflow: hidden;

  .logo {
    height: 30px;
    position: relative;
    top: 15px;
    display: flex;
    justify-content: center;
  }

  .menu_icon_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 16px;
    padding: 11px 12px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid var(--border-primary);
    height: 60px;

    .title {
      display: flex;
      justify-content: space-between;
      width: 295px;
      align-items: center;
      font-family: Inter-SemiBold;
      font-size: 16px;
      line-height: 17px;
      color: var(--text-secondary);
      margin: 0;
    }

    .close_menu_wrapper {
      background: var(--background-surface-3);
      border-radius: 50%;
      border-radius: 50%;
      height: 30px;
      width: 30px;
    }
  }

  .content_wrapper {
    padding: 0 12px;
    display: flex;
    flex-direction: column;

    .provider_list {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      overflow-y: auto;

      .provider_item {
        height: 40px;
        background: var(--background-surface-3);
        border: 1px solid #313341;
        border-radius: 8px;
        color: var(--text-secondary);
        margin: 4px;
        font-family: Inter-Medium;
        font-size: 12px;
        line-height: 15px;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        padding-left: 12px;
        cursor: pointer;

        &:nth-child(1) {
          width: 100%;
          margin-top: 12px;
        }

        &:not(:first-child) {
          width: 47%;
        }
      }

      .selected {
        border: 1px solid var(--border-primary-selected);
      }
    }

    .provider_multiSelect_list {
      user-select: none;
      cursor: pointer;
      overflow-y: auto;
      margin: 10px 0 18px;
      overflow-x: hidden;

      .checkbox_dropdown_list_item {
        display: flex;
        align-items: center;
        border: 1px solid var(--border-primary);
        padding: 10px;
        height: 70px;
        margin: 0 0 10px;
        border-radius: 4px;
        position: relative;
        p {
           text-transform: capitalize;
        }
        .provider_image_wrapper {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          position: absolute;
          padding-right: 20px;
            span {
              color: var(--text-primary);
              text-transform: capitalize;
            }
          
        }
        .provider_image_wrapperForLightTheme {
          svg path{
            fill:black
          }
        }

        label {
          display: flex;
          transition: all 0.2s ease-out;
          color: var(--text-secondary);
          margin: 0 5px 0 0;

          input[type='checkbox'] {
            margin-right: 10px;
          }
        }
      }
      .checkbox_dropdown_title {
        p {
          position: absolute;
          text-align: left;
          padding-left: 28px;
        }
      }
    }

    .result_body_wrapper {
      margin-top: 20px;
      text-align: initial;
      margin-bottom: 10px;

      .result_search_title {
        font-style: normal;
        font-family: Inter-SemiBold;
        font-size: 14px;
        line-height: 17px;
        color: var(--text-secondary);
        margin-bottom: 10px;
      }

      .result_body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow: auto;
      }
    }

    .search_input {
      display: flex;
      align-items: center;
      width: 100%;

      div:nth-child(1) {
        width: 100%;
      }

      .search_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 13px;
        margin-bottom: 10px;
        height: 38px;
        width: $search_icon_width;
        background: var(--background-surface-3);
        border-radius: 0px 8px 8px 0px;

        svg path {
          fill: #636770;
        }
      }

      .input_close_icon {
        position: absolute;
        right: 70px;
        margin-bottom: 10px;
        font-size: 13px;
      }
    }

    .no_result_body {
      margin-top: 24px;

      .result_search_icon {
        color: var(--text-secondary);
        height: 63px;
        margin-bottom: 15px;

        svg {
          font-size: 53px;
        }
      }

      .title {
        font-style: normal;
        font-family: Inter-SemiBold;
        font-size: 18px;
        line-height: 22px;
        color: var(--text-secondary);
      }

      .subTitle {
        font-style: normal;
        font-family: Inter-Medium;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: var(--text-secondary);
        width: 226px;
        margin: 0 auto;
      }
    }
  }

  @supports (-webkit-touch-callout: none) {
    .content_wrapper {
      height: calc(100vh - 220px);
    }
  }

  @supports not (-webkit-touch-callout: none) {
    .content_wrapper {
      height: calc(100vh - 170px);
    }
  }
}

