@import '../../../styles/sizes.scss';

.desktop .auth_container {
  width: fit-content;
  margin: 0 5px;

  .button_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .loginButton,
    .registerButton {
      text-transform: uppercase;
      font-family: Inter-Bold;
      height: $headerBtnDesktopHeight;
      border-radius: 8px;
    }

    .loginButton {
      margin: 0 10px;
    }
  }
}

.mobile .auth_container {
  width: unset;

  .button_row {
    display: flex;

    button {
      margin: 0;
    }

    &:last-child {
      margin: 0;
    }

    .loginButton,
    .registerButton {
      text-transform: uppercase;
      font-size: 12px;
      height: $headerBtnMobileHeight;
      margin-left: 8px;
      font-family: Inter-SemiBold;
      border-radius: 8px;
      padding: 0 16px!important;
    }
  }
}

.tablet .auth_container {
  .button_row {
    display: flex;

    button {
      margin: 0 5px;
    }
  }
}
