@import '../../styles/sizes.scss';
@import '../../configs/medias.scss';
@import '../../styles/constants';

.tab_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;

  .tab {
    .step {
      width: 30px;
      height: 30px;
      background: var(--background-surface-3);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: Inter-SemiBold;
      color: var(--text-secondary);
    }

    &.done:after {
      content: '';
      display: block;
      position: absolute;
      left: 37.5%;
      top: 15px;
      height: 1px;
      width: 25%;
      background: transparent;
      border: 1px dashed #0f5903;
      z-index: $sign-up__tab-container-done-after--index;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 15px;
      left: 37.5%;
      height: 1px;
      width: 25%;
      background: transparent;
      border: 1px dashed #666666;
    }

    .title {
      font-family: Inter-SemiBold;
      font-size: 14px;
      text-align: center;
      color: var(--text-primary);
      margin-top: 6px;
    }

    &.selected,
    &.done {
      .step {
        background: #0f5903;
        color: #ffffff;
      }

      .title {
        color: var(--text-primary);
      }
    }
  }
}

.input_container {
  input {
    display: block;
    width: 100%;
    font-size: 12px;
    font-family: Inter;
    line-height: 35px;
    padding: 0 0 0 8px;
    border-radius: 10px;
    height: 40px;
    // background-color: var(--bg-main-color-2);

    &::placeholder {
      font-size: 12px;
      font-family: Inter;
      height: 35px;
    }
  }

  input {
    & + p {
      position: absolute;
      color: red;
      font-size: 11px;
      font-family: Inter-Medium;
      line-height: 1.5;
    }
  }
}

.rules {
  display: flex;
  justify-content: center;
  height: 30px;

  p {
    font-family: Inter-Medium;
    font-size: 0.8rem;
    line-height: 19px;
    text-align: center;
    color: var(--text-primary);
  }

  span {
    color: red;
    margin: 0 5px 40px;
  }
}

@media (min-width: 1200px) {
  .tab_container {
    .tab {
      &.done:after,
      &:before {
        top: 38%;
      }
    }
  }
}

@media (min-width: $tablet) and (max-width: $laptopMin) {
  .tab_container {
    .tab {
      &.done:after,
      &:before {
        top: 37%;
      }
    }
  }

  .form {
    margin-top: 85px;
  }
}

@media (max-width: $tabletMin) {
  .tab_container {
    .tab {
      &.done:after,
      &:before {
        top: 25%;
      }
    }
  }
}
