@import '../../../../../../../../styles/sizes.scss';
@import '../../../../../../../../configs/medias.scss';
@import '../../../../../../../../styles/constants';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  .content {
    display: flex;
    flex-wrap: wrap;

    .note {
      background: var(--account-background-surface-3);
      border: 1px solid var(--border-primary);
      border-radius: 10px;
      padding: 16px 19px;
      height: fit-content;
      margin: 15px 0 0 47px;

      p {
        margin-bottom: 9px;
      }

      p,
      ul li {
        font-family: Inter-Medium;
        font-size: 12px;
        line-height: 15px;
        color: var(--text-primary);
      }

      ul {
        list-style: circle;
        margin: 0;
        padding: 0 15px;
      }
    }
  }

  &__item {
    width: $change_password_width;
    position: relative;
    display: flex;
    flex-direction: column;

    &_input {
      input {
        background: var(--background-surface-2);
        border: 1px solid var(--border-primary);
        border-radius: 8px;
        font-size: 12px;
        font-family: Inter;
        color: var(--text-secondary);
        padding: 0 0 0 8px;
        height: $input_height;
        margin-top: 7px;

        &:focus {
          border: 1px solid var(--input-border-typing);
        }
      }

      p {
        margin-bottom: 8px;
      }
    }

    &__error_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      color: red;

      &_icon {
        font-size: 16px;
        font-family: Inter;
        margin: 12px 14px 12px 12px;
        color: var(--text-primary);
      }

      &_error {
        font-size: 12px;
        position: relative;
        top: -10px;
      }
    }

    &_button {
      width: 100%;
      border-radius: 8px;
      height: $btn-lg-height;

      p {
        text-transform: uppercase;
        font-family: Inter-Bold;
        font-size: 16px;
        line-height: 19px;
      }
      &.disabled {
        background: var(--background-surface-3) !important;
        color: var(--text-secondary) !important;
      }
    }

    &:last-child {
      margin-top: 20px;
    }
  }
}

@media (max-width: $tablet) {
  .container {
    padding: 0 1rem;
    text-align: left;

    .note {
      color: rgba(187, 187, 189, 0.9);
      margin: 0 auto;
    }

    &__item {
      height: fit-content;
      margin: 0 auto;

      &_input {
        &_wrapper {
          margin-top: 5px;
        }

        p {
          font-size: 12px;
        }
      }

      &_button {
        padding: 10px 40px;
        height: $input_height;
      }
    }
  }
}
