@import '../../../styles/mixins.scss';
@import '../../../styles/sizes.scss';

#widget-App {
  .navigation {
    .nav-link {
      color: white !important;
    }
    .nav-item {
      margin-left: 14px;
      margin-right: 14px;
      select, option, a {
        color: var(--text-secondary) !important;
      }
      select {
        background: var(--background-surface-1);
        border: 1px solid white;
        &:hover {
          border-color: white;
        }
      }
    }
    .tabs {
      background: var(--background-surface-1);
      .nav-link--active {
        background: var(--background-surface-2);
      }
      .nav-link--active, .nav-link {
        &:hover {
          background: var(--background-surface-2) !important;
        }
      }
    }
  }

  #widget-Scene,
  .widget-App #widget-Scene {
    background: var(--background-surface-2) !important;
    border-radius: 8px;

    .games-table {
      th,
      td {
        color: white;
      }
    }
  }

  table tbody tr:nth-child(odd),
  .widget-App table tbody tr:nth-child(odd) {
    background: var(--background-surface-1) !important;
  }
}