@import 'bootstrap/scss/bootstrap';
@import 'react-datepicker/dist/react-datepicker.css';
@import './configs/medias.scss';
@import './style.css';
@import './styles/sizes.scss';
@import './styles/mixins.scss';
@import './styles/constants';

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-surface-1);
  user-select: none;

  @include scrollbar();
}

.mobilereferAFriend.referAFriend {
  display: block !important;
}

* {
  box-sizing: border-box;
}

/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.ant-picker {
  .ant-picker-active-bar {
    background: var(--secondary-4);
  }

  .ant-picker-separator,
  .ant-picker-suffix {
    svg path{
      fill: var(--text-secondary);
    }
  }

  .ant-picker-input {
    input {
      color: var(--text-secondary);
    }

    input::placeholder {
      color: var(--text-secondary);
    }
  }
}

.fixed-body {
  position: fixed;
  margin: 0 auto;
  width: 100%;
}

.overlay {
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    inset: 0;
    z-index: $main__overlay-before--index;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.error-boundary {
  color: var(--text-secondary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  flex-direction: column;

  button {
    text-transform: uppercase;
    border-radius: 4px;
    width: 250px !important;

    &:active {
      transform: scale(0.95);
    }
  }
}

iframe{
  border: none;
}