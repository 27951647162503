@import '../../../styles/sizes.scss';

.wagering_wrapper {
  width: $wageringWidth;
  height: $headerBtnDesktopHeight;
  cursor: pointer;
  background-color: var(--background-surface-3);
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;

  button {
    width: $wageringWidth;
    height: $headerBtnDesktopHeight;
    width: inherit;
    background-color: transparent;
    border: none;
    padding: 0;

    svg {
      width: $wageringWidth;
      height: $wageringHeight;
    }

    &:hover {
      background-color: transparent;
      border: transparent;
    }
  }
}

.mobile .wagering_wrapper {
  width: 40px !important;
  height: $headerBtnMobileHeight;

  button {
    min-width: 40px !important;
    font-size: 12px;
    padding: 0;

    svg {
      width: 15px !important;
      margin: 0 auto;
    }
  }
}
