@import '../../styles/constants';
@import '../../styles/mixins';
@import '../../configs/medias.scss';

.login_page_container {
  width: 100vw;
  height: 100vh;
  height: 100svh;
  position: relative;
  background-color: var(--background-surface-2);
  overflow: hidden;
  z-index: $login-page-index;

  .login_page_inner_container {
    @include center();

    height: 100%;

    .inner_wrapper {
      width: 480px;
      padding: 36px 24px;
      background-color: var(--surface-1);
      border-radius: 12px;

      .language_container {
        @include center();

        margin: 32px 0 0;
      }
    }
  }

  .logo_container {
    @include center();

    flex-direction: column;
    align-items: center;

    img {
      height: 37px;
    }

    .title {
      color: var(--text-primary);
    }
  }

  .loginModal__content {
    max-width: 443px;
    color: var(--text-secondary);
    margin: 0 auto;

    .loginButton {
      width: 100%;
      height: 47px;
      border-radius: 10px;
      font-size: 16px;
      text-transform: uppercase;

      &:disabled {
        background: var(--surface-3);
        color: var(--text-primary);
      }
    }

    .signin_error {
      text-align: center;
    }
  }
}

.mobile {
  .login_page_container {
    .login_page_inner_container {
      background-color: var(--background-surface-1);
    }
  }
}
