@import '../../../styles/sizes.scss';

.download_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  grid-area: app_download;
  margin: 0 auto;

  .btn {
    height: $headerBtnDesktopHeight;
    width: max-content;
    padding: 0;
    border-radius: 28px;
    margin: 5px;

    img {
      height: $headerBtnDesktopHeight;
      width: 160px;
    }

    &:hover {
      box-shadow: 0 0 11px rgba(255, 204, 0, 0.8);
    }
  }
}

.mobile {
  .download_container {
    margin: 20px auto;
    display: flex;
    justify-content: center;
  }
}
