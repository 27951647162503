@import '../../styles/sizes.scss';
@import '../../styles/mixins.scss';
@import '../../styles/constants';

.fullScreen {
  max-width: 100%;
}

.noFullScreen {
  max-width: $maxWidth;
}

.section_loader_container {
  @include center();

  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: var(--background-surface-1);
  color: var(--text-brand);
  // z-index: $section-loader-page__container--index;
  z-index: $section-loader-index;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .img {
    height: 18%;
  }

  span {
    top: 40%;
    position: absolute;
  }
}

.mobile {
  .section_loader_container {
    margin-left: 0;
    width: 100vw;
    top: $mobileHeaderHeight;
    position: fixed;
    //height: calc(100% - $mobileFixedMenuHeight - $mobileHeaderHeight);
    // z-index: $section-loader-page__container-mobile--index;
    display: initial;
    transform: unset;
    left: 0;
  }

  &.casinoTournament {
    .section_loader_container {
      height: calc(100% - $mobileFixedMenuHeight);
    }
  }
}
