.primary-filled-btn {
  background-color: var(--button-brand);
  color: var(--button-brand-text);
  border-radius: 8px;

  svg path {
    fill: var(--button-brand-text);
    stroke: var(--button-brand-text);
  }

  &:hover {
    background-color: var(--button-brand-hover);
  }

  &:active {
    transform: scale(0.95);
  }
}

.primary-outlined-btn {
  background-color: transparent;
  color: var(--button-brand-text);
  border: 1px solid var(--button-primary-border);
  border-radius: 8px;

  svg {
    path {
      fill: var(--icon-color-secondary);
    }
  }

  &:hover {
    background: var(--button-primary-hover);
    color: var(--button-primary-text);

    svg {
      path {
        fill: var(--icon-color-primary);
      }
    }
  }

  &:active {
    transform: scale(0.95);
  }
}

.success-filled-btn {
  background-color: var(--button-primary);
  color: var(--button-primary-text);
  text-transform: uppercase;
  border-radius: 8px;

  svg path {
    fill: var(--button-primary-text);
  }

  &:hover {
    background-color: var(--button-primary-hover);
  }

  &:active {
    transform: scale(0.95);
  }
}

.success-outlined-btn {
  background-color: transparent;
  color: var(--button-primary-text);
  text-transform: uppercase;
  border: 1px solid var(--button-primary);
  border-radius: 8px;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    transform: scale(0.95);
  }
}

.brand-filled-btn {
  background-color: var(--button-brand);
  color: var(--button-brand-text);
  text-transform: uppercase;
  border-radius: 8px;

  svg path {
    fill: var(--button-brand-text);
  }

  &:hover {
    background-color: var(--button-primary-hover);
  }

  &:active {
    transform: scale(0.95);
  }
}

.secondary-btn {
  background-color: var(--button-secondary);
  color: var(--button-secondary-text);
  border-radius: 8px;
  text-transform: uppercase;

  button {
    color: var(--button-secondary-text);
  }

  svg {
    g path,
    path {
      fill: var(--button-secondary-text) !important;
    }
  }

  &:active {
    transform: scale(0.95);
  }

  &:hover {
    background-color: var(--button-secondary-hover);
  }
}

.secondary-outlined-btn {
  border: 1px solid var(--button-secondary-border);
  color: var(--button-secondary-text);
  border-radius: 8px;
  text-transform: uppercase;

  svg {
    g path,
    path {
      fill: var(--icon-color-secondary) !important;
    }
  }

  &.selected {
    background-color: var(--button-secondary);
  }

  &:hover {
    background-color: var(--button-secondary-hover);
  }
}

.secondary-btn-without-icon {
  background-color: var(--button-secondary);
  color: var(--button-secondary-text);
  border-radius: 8px;

  &:active {
    transform: scale(0.95);
  }

  &:hover {
    background-color: var(--button-secondary-hover);
  }
}

.tertiary-outlined-btn {
  color: var(--button-secondary-text);
  border-radius: 8px;
  border: 1px solid var(--background-surface-3);

  svg {
    g path,
    path {
      fill: var(--button-secondary-text);
    }
  }

  &:hover {
    border: 1px solid var(--background-surface-3);
  }
}

.light-btn {
  color: var(--text-primary);
  background-color: transparent;
  border-radius: 8px;

  &:hover {
    opacity: 0.7;
  }
}
