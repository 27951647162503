@import '../../../styles/sizes.scss';
@import '../../../styles/mixins.scss';

.copyRight_container {
  grid-area: copyright;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;

  .copyRight {
    margin: 0 5px;
    font-size: 12px;
    color: var(--text-primary);
    text-align: center;
  }

  .copyRight_subContainer {
    @include center();

    height: $copyRight_container_height;
    margin: 0 auto;
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  .copyRight_container {
    width: 100%;
    margin: 20px auto;
  }
}
