.newsLetter_container {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.desktop .newsLetter_container,
.tablet .newsLetter_container {
  &.dark {
    background: var(--dark);
    color: var(--light);
  }

  &.light {
    background: var(--light);
    color: var(--dark);
  }

  &.primary {
    background: var(--newsletter-background);
    .text {
      color: var(--newsletter-text);
    }
  }
}

.mobile .newsLetter_container {
  align-items: flex-start;
  background-color: var(--newsletter-background);
  border: none;
  border-radius: 0;
  height: 27px;
  line-height: 30px;
  margin: 0;
  overflow: hidden;
  width: 100%;

  .text {
    animation: moving 22s linear infinite;
    -webkit-animation: moving 22s linear infinite;
    color: var(--newsletter-text);
    font-family: Inter;
    font-size: 12px;
    position: relative;
    width: auto;
    word-break: break-all;
  }
}

@keyframes moving {
  0% {
    margin-left: 100%;
  }

  100% {
    margin-left: -150%;
  }
}

@-webkit-keyframes moving {
  0% {
    margin-left: 100%;
  }

  100% {
    margin-left: -150%;
  }
}
