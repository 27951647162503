@import '../../../configs/medias.scss';
@import '../../../styles/mixins.scss';

.bottom_navigation_wrapper {
  display: flex;
  margin: 10px 0;
  grid-area: bottom_navigation;

  &.rules_row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow-y: hidden;
    width: 100%;
    height: fit-content;

    .rule {
      padding: 0 10px 0 0;
      text-align: center;
      // this is for nav text line and must be same as text
      border-right: 1px solid var(--text-primary);
      white-space: nowrap;
      width: fit-content;
      height: fit-content;
      margin: 5px;

      &:last-child {
        border: unset;
      }

      &:first-child {
        padding: 0 10px 0 0;
      }
    }
  }

  &.rules_list {
    flex-direction: column;
  }

  .rule {
    cursor: pointer;
    user-select: none;
    color: var(--text-primary);
    text-transform: uppercase;
    font-style: normal;
    font-family: Inter-SemiBold;
    font-size: 14px;

    &.link {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.tablet .rules_wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 16px 0;

  &.row {
    flex-direction: row;
    justify-content: center;

    .rule {
      padding: 0 15px;
      text-align: center;
      text-transform: uppercase;
      font-size: 10px;
      font-family: Inter-SemiBold;
      border-right: 1px solid  var(--primary-text);
      margin: 0;

      &:last-child {
        border-right: unset;
      }
    }
  }

  &.list {
    flex-direction: column;
  }

  .rule {
    cursor: pointer;
    user-select: none;
    font-family: Inter;
    font-size: 14px;
    line-height: 16px;

    &.link {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.mobile_footer_container .rules_row {
  padding: 0;
  margin: 20px 0;
  height: auto;
  width: 100%;
}

.mobile .bottom_navigation_wrapper {
  margin: 10px 0;
  flex-direction: column;
  align-items: baseline;

  .rule {
    text-transform: uppercase;
    font-style: normal;
    font-family: Inter-Medium;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px;
    border: none;
    padding: 0;

    &:last-child {
      padding: 0;
    }

    &.link {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
