@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin svg-size($size) {
  > div {
    width: $size;
    height: $size;

    > div {
      width: $size;
      height: $size;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

@mixin svg-color($color) {
  svg {
    path {
      fill: $color;
    }
  }
}

@mixin custom-btn($background-color, $stateMode) {
  @if ($stateMode == dark) {
    &:hover {
      background: darken($background-color, 20%);
    }

    &:active {
      background: darken($background-color, 5%);
    }
  }

  @if ($stateMode == light) {
    &:hover {
      background: lighten($background-color, 20%);
    }

    &:active {
      background: lighten($background-color, 5%);
    }
  }

  @if ($stateMode == default) {
    &:hover {
      background: lighten($background-color, 5%);
    }

    &:active {
      background: darken($background-color, 10%);
    }
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 5px;
    background-color:  var(--background-surface-2);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color:  var(--background-surface-3);
  }
}


@mixin lightScrollbar {
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 5px;
    background-color:  #efefef;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color:  lightgrey;
  }
}


@mixin hideScrollbar {
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    height: 0;
  }
}