@import '../../styles/sizes.scss';
@import '../../styles/mixins.scss';
@import '../../styles/constants';

.general_loader_page_container {
  width: 100vw;
  height: 100vh;
  z-index: $general-loader-index;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--background-surface-3);
  overflow: hidden;

  .loader_container {
    @include center();
    height: 100vh;
    flex-direction: column;

    .logo {
      img {
        height: 43px;
      }
    }
  }
}
