@import '../../../styles/sizes.scss';
@import '../../../styles/mixins.scss';

.custom-scrollbars__main {
  width: 100%;

  &.sidebar {
    width: calc(100% - 72px);
    position: fixed;
    right: 0;
  }

  &.sidebar_is_open {
    width: calc(100% - 260px);
    position: fixed;
    right: 0;
    min-width: unset;
  }
}

.custom-scrollbars__container {
  display: grid;
  height: 100%;
  grid-template: auto / 1fr 10px;
  overflow: hidden;
  padding: 0;
  margin: 0 auto;
  width: 100%;

  &.sidebar {
    width: calc(100% - 72px);
    position: fixed;
    right: 0;
  }

  &.sidebar_is_open {
    width: calc(100% - 260px);
    position: fixed;
    right: 0;
    min-width: unset;
  }

  .custom-scrollbars__button {
    visibility: hidden;
    height: 0;
    width: 0;
  }
}

.custom-scrollbars__content {
  height: 100vh;
  width: calc(100% + 10px);
  overflow: auto;

  @include scrollbar();
}

.custom-scrollbars__scrollbar {
  display: grid;
  gap: 5px;
  grid-auto-flow: row;
  grid-template: auto 1fr auto / 1fr;
  padding: 0;
  place-items: center;
}

.fixed-body {
  .custom-scrollbars__content {
    overflow: hidden;
  }
}
