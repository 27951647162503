@import '../../../../styles/sizes.scss';
@import '../../../../styles/constants';

.mobile_fixed_menu_container {
  position: fixed;
  align-items: center;
  width: 100%;
  display: flex;
  padding: 0;
  height: $mobileFixedMenuHeight;
  justify-content: space-between;
  background: var(--background-surface-2);
  border: 1px solid var(--border-primary);
  backdrop-filter: blur(2px);
  bottom: 0;
  z-index: $mobile-fixed-menu__container--index;

  .btn_container {
    display: flex;
    flex-direction: column;
    justify-content: inherit;
    width: 100%;

    .element {
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      font-size: 9px;
      cursor: pointer;
      color: var(--text-primary);

      span {
        position: relative;
        font-size: 20px !important;
        color: inherit;
      }
    }

    .active {
      height: 4px;
      background-color: var(--text-brand);
      width: 95%;
      margin-left: 4px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      position: relative;
      top: 7px;
    }
  }
  .fixed_menu_icon_content {
    .element {
      gap: 0;
      img {
        width: 35px;
      }
    }
    .active {
      top:3px
    }
    &:nth-child(3) {
      .element {
        position: relative;
        gap: 5px;
        top: 1px;
        margin-top: 10px;
      }
    }
  }
}

.hide_fixed_menu {
  display: none;
}
