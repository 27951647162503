@import '../../../styles/sizes.scss';
@import '../../../styles/constants';
@import '../../../styles/mixins.scss';

.help-center {
  width: 100%;
  margin: 0 auto;

  &__items__holder {
    display: flex;
    margin: 40px auto;
    width: 600px;
    justify-content: space-around;
    color: var(--text-brand);
  }

  &__item {
    border: 1px solid var(--border-primary);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    cursor: pointer;
    width: 150px;
    max-height: 123px;
    text-align: center;
    color: var(--text-secondary);

    &:hover {
      opacity: 0.8;
      text-decoration: none;
      color: var(--main-color-5);
    }

    img {
      height: 50px;
      width: 50px;
      margin-bottom: 10px;
    }

    h6 {
      text-transform: capitalize;

      &:hover {
        color: var(--main-color-5);
      }
    }
  }

  &--popular__articles {
    width: 70%;
    margin: 0 auto;

    h3 {
      color: var(--text-secondary);
    }

    &__holder {
      display: grid;
      gap: 0 60px;
      grid-template-columns: repeat(2, 5fr);
      margin: 2rem 0;
    }
  }

  &__search {
    position: relative;

    svg {
      position: absolute;
      top: 10px;
      left: 10px;
      color: grey;
      font-size: 12px;
    }

    &__holder {
      @include center();

      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 320px;
      border-bottom: 1px solid var(--border-primary);
    }

    &__input {
      input {
        width: 400px;
      }
      &--small {
        width: 200px;
      }
    }
  }

  &__article {
    display: flex;
    border: 1px solid var(--border-primary);
    margin-bottom: 2%;
    border-radius: 4px;

    .help-center__article__title {
      text-transform: capitalize;
    }

    &__header {
      padding: 1rem;
      width: 100%;
      justify-content: space-between;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      cursor: pointer;
      color: var(--text-secondary);

      .icon-angle-down {
        font-size: 12px;

        &.angle-up {
          transform: rotate(180deg) scale(0.8);
        }
      }
    }

    &__content {
      padding: 1rem;

      .help-center__row {
        span {
          margin: 0;
        }
      }
    }

    &--expanded {
      flex-direction: column;

      .help-center__article__header {
        background-color: var(--background-surface-1);
        color: var(--text-secondary);
        border-radius: 4px 4px 0 0;
      }

      .help-center__row--selected h5 {
        color: var(--text-brand);
      }
    }

    &__title {
      color: var(--text-secondary);
    }
  }

  &__articles {
    width: 100%;
    max-width: 72%;
    margin: 20px auto;

    &__holder {
      width: 100%;
      margin: 5% auto 0;

      .help-center__nav {
        max-width: 72%;

        button {
          width: initial;
          padding: 0;
        }
      }
    }

    &--opened {
      display: flex;
      width: 100%;

      .help-center__articles__list {
        width: 50%;

        .help-center__row__text {
          padding: 6px 0;
        }
      }

      .help-center__articles--selected {
        width: 50%;
        padding: 0 1rem;
        margin-left: 3rem;
        color: var(--text-secondary);

        h3 {
          font-size: 20px;
        }

        span {
          font-size: 14px;
        }
      }
    }

    table,
    td,
    th {
      font-size: 14px;
      border: 1px solid #ffffff;
      padding: 8px;

      tr > td:first-child {
        font-family: Inter-Bold;
      }
    }

    span {
      display: block;
      margin-bottom: 10px;
    }
  }

  &__nav__link {
    color: var(--text-secondary);
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 159px auto 0;
  }

  &__row__text {
    margin-left: 0.3rem;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 14px;
    padding: 14px 0;
    color: var(--text-secondary);

    &:hover {
      opacity: 0.8;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;

    &:hover {
      opacity: 0.8;
    }
  }
}

.help-center__search-result {
  width: 50%;
  margin: 0 auto;

  &__container {
    display: flex;
    margin-top: 20px;
  }

  &-left {
    width: 40%;
    min-width: 180px;

    span {
      color: var(--text-secondary);
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin: 4px 0;
    }

    li {
      padding: 4px 8px;
      background-color: var(--background-surface-1);
      width: fit-content;
      font-family: Inter-Bold;
      color: black;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  &__header {
    font-size: 20px;
    border-bottom: 1px solid var(--border-primary);
    color: var(--text-secondary);
    margin-bottom: 12px;
  }

  &__row {
    margin-bottom: 50px;

    .title {
      cursor: pointer;
      color: var(--text-primary);
      text-align: left;
      font-size: 16px;
      font-family: Inter;
      margin-bottom: 2px;

      &:hover {
        text-decoration: underline;
      }
    }

    .content {
      font-size: 14px;
      color: var(--text-secondary);
    }
  }
}

.help-center__search-input-suggestions-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-main-color-4);
  min-width: 1280px;
}

.help-center__search-input-suggestions {
  position: absolute;
  // z-index: $help-center__search-input-and-suggestions--index;
  z-index: $help-center__search-suggestions--index;
  width: 400px;
  color: black;
  border: 1px solid var(--border-primary);
  // TO DO check replace frok --main-0
  background-color: var(--background-surface-1);
  border-radius: 8px;
  font-size: 16px;

  & > div {
    padding: 4px 16px;
  }

  & span {
    cursor: pointer;

    &:hover {
      color: var(--text-secondary);
    }
  }
}

.diamond-icon {
  width: 0;
  height: 0;
  top: -7px;
  border: 7px solid transparent;
  border-bottom-color: var(--border-primary);
  position: relative;
}

.diamond-icon:after {
  content: '';
  position: absolute;
  left: -7px;
  top: 7px;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-top-color: var(--border-primary);
}

.mobile {
  .help-center {
    padding: 0;
    margin: $mobileHeaderHeight 0 0;

    &__search__holder {
      height: 200px;
    }

    &__search__input input {
      width: 300px;
    }

    &__item {
      width: 31%;
    }

    &__items__holder {
      width: 100%;
      justify-content: space-between;
      padding: 0 10px;

      &__item {
        width: 100px;
      }
    }

    &--popular__articles {
      width: 100%;
      text-align: center;
      padding: 0 10px;

      h3 {
        color: var(--text-secondary);
      }

      &__holder {
        grid-template-columns: unset;

        .help-center__row {
          align-items: flex-start;
          justify-content: flex-start;
          margin: 0.5rem 0rem;

          &__text {
            padding: 0px;
            text-align: left;
          }
        }
      }
    }

    &__nav {
      margin: $mobileHeaderHeight 0 10px;
    }
  }

  .help-center__articles__holder {
    padding: 0px 10px;

    .help-center__nav {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;

      .help-center__search {
        width: 100%;

        .help-center__search__input {
          width: 100%;
        }
      }
    }

    .help-center__articles {
      width: 100%;
      flex-direction: column;
      margin: 10px 0;

      &.help-center__articles--opened {
        width: 100%;
        display: initial;
      }

      .help-center__articles__list {
        width: 100%;
        margin: 0 auto;

        .help-center__article {
          .help-center__article__header {
            padding: 0.5rem 1rem;

            h5 {
              font-size: 1rem;
              margin-bottom: 0;
            }

            i {
              font-size: 10px;
            }
          }
        }
      }

      .help-center__articles--selected {
        width: 100%;
        margin-left: 0;
        padding: 0.5rem;

        table {
          th,
          td {
            font-size: 11px;
            padding: 6px;

            @media screen and (max-width: 325px) {
              font-size: 8px;
              padding: 5px;
            }
          }
        }
      }
    }
  }

  .help-center__search-result {
    width: 100%;
    padding: 0px 10px;

    .help-center__nav {
      width: 100%;
      text-align: center;

      .help-center__nav__link {
        font-size: 20px;
      }
    }

    .help-center__search-result__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      .help-center__search-result-left {
        width: 100%;

        ul {
          margin: 5px 0px 15px;
          width: 100%;

          li {
            width: 100%;
          }
        }
      }

      .help-center__search-result-right {
        width: 100%;
      }
    }
  }

  @media (orientation: landscape) and (min-width: 580px) {
    .help-center {
      padding: 0px;

      &__items__holder {
        width: 90%;

        .help-center__item {
          width: 150px;
        }
      }

      &--popular__articles {
        width: 90%;
        text-align: left;
      }
    }

    .help-center__articles__holder {
      padding: 0px 30px;

      .help-center__nav {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .help-center__search {
          width: 400px;

          .help-center__search__input {
            width: 100%;
          }
        }
      }

      .help-center__articles {
        width: 100%;
        flex-direction: row;

        .help-center__articles__list {
          width: 30%;
        }

        .help-center__articles--selected {
          width: 70%;
          padding: 0rem;
          padding-left: 1rem;

          table {
            th,
            td {
              font-size: 12px;
              padding: 7px;
            }
          }
        }
      }
    }
  }
}
