.external_link {
  grid-area: externalLink;
  width: auto;
  margin: 0 5px;
  height: 43px;
  border: 1px solid var(--button-primary-border);

  button {
    padding: 0;
    margin: unset;
    height: 43px;
  }

  img {
    height: 40px;
    width: 180px;
    border-radius: 8px;
    // border: 1px solid var(--border-primary);
  }
}

.mobile {
  .external_link {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
