@import '../../../../styles/sizes.scss';
@import '../../../../styles/constants';

.search_container {
  text-align: center;
  background: var(--background-surface-1);
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  // z-index: $mobile-search__container--index;
  z-index: $mobile-full-page-widgets-index;
  visibility: visible;
  position: relative;
  overflow: auto;

  .logo {
    height: 30px;
    position: relative;
    top: 15px;
    display: flex;
    justify-content: center;
  }

  .menu_icon_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 10px 15px;
    border-bottom: 1px solid var(--border-primary);
    height: 60px;

    .title {
      display: flex;
      justify-content: space-between;
      width: $modal_title_view;
      align-items: center;
      font-family: Inter-SemiBold;
      font-size: 16px;
      line-height: 17px;
      color: var(--text-secondary);
      margin: 0;
    }

    .close_menu_wrapper {
      background: var(--background-surface-3);
      border-radius: 50%;
      border-radius: 50%;
      height: 30px;
      width: 30px;
    }
  }

  .content_wrapper {
    padding: 15px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .result_body_wrapper {
      text-align: initial;
      width: 95vw;
      margin: 20px auto 10px;
      overflow-y: auto;

      .result_search_title {
        font-style: normal;
        font-family: Inter-SemiBold;
        font-size: 14px;
        line-height: 17px;
        color: var(--text-secondary);
        margin-bottom: 10px;
      }

      .result_body {
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
      }
    }

    .search_input {
      display: flex;
      align-items: center;
      width: 100%;

      div:nth-child(1) {
        width: 100%;
      }

      .search_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 13px;
        margin-bottom: 10px;
        height: 46px;
        width: $search_icon_width;
        background: var(--background-surface-3);
        border-radius: 0px 8px 8px 0px;

        svg path {
          fill: #636770;
        }
      }

      .input_close_icon {
        position: absolute;
        right: 70px;
        margin-bottom: 10px;
        font-size: 13px;
      }
    }

    .no_result_body {
      margin-top: 24px;

      .result_search_icon {
        color: var(--text-secondary);
        height: 63px;
        margin-bottom: 15px;

        svg {
          font-size: 53px;
        }
      }

      .title {
        font-style: normal;
        font-family: Inter-SemiBold;
        font-size: 18px;
        line-height: 22px;
        color: var(--text-secondary);
      }

      .subTitle {
        font-style: normal;
        font-family: Inter-Medium;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: var(--text-secondary);
        width: 226px;
        margin: 0 auto;
      }
    }
  }
}

// @media (max-width: 480px) {
//   .search_container {
//     z-index: $mobile-search__container-mobile--index;
//   }
// }

@supports (-webkit-touch-callout: none) {
  .search_container {
    .content_wrapper {
      height: calc(100% - 185px);
    }
  }
}

@supports not (-webkit-touch-callout: none) {
  .search_container {
    .content_wrapper {
      height: calc(100% - 150px);
    }
  }
}
