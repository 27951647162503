@import '../../../styles/mixins.scss';

.desktop .logo_gif_container, .tablet .logo_gif_container {
  @include center();

  cursor: pointer;
  width: 100%;
  height: 100%;

  &_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: scale 3s ease-in forwards;
    width: 200px;
  }

  &_image {
    width: 100%;
    height: 100%;
  }
}

.mobile .logo_gif_container{
  &_image {
    width: 100px;
    height: 100%;
  }

  &_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: mobileScale 2s ease-in forwards;
    width: 200px;
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(4);
  }
}

@keyframes mobileScale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(3);
  }
}