@import '../../../../styles/sizes.scss';

.tabs_section__item_1 {
  height: $account_modal_payment_tab_height;
  width: $account_modal_payment_tab_width;
  background: var(--background-surface-1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
  border: 1px solid var(--border-primary);
  padding-top: 12px;

  .tab_image_wrapper {
    border-radius: 5px;
    text-align: center;
    margin: 0 auto;

    .tab_image {
      // width: $account_modal_payment_tab_img_width;
      height: $account_modal_payment_tab_img_height;
      object-fit: contain;

      &[alt] {
        font-size: 10px;
      }
    }
  }

  .tab_name {
    font-family: Inter-Bold;
    font-size: 12px;
    line-height: 12px;
    color: var(--text-secondary);
    text-align: center;
    margin-top: 8px;
    max-width: calc(#{$account_modal_payment_tab_width} - 10px);
  }

  &.selected {
    background-color: var(--background-surface-3);
    border: 0.5px solid var(--border-primary-selected);
  }
}

.mobile {
  .tabs_section__item_1 {
    width: 30%;
    margin: 5px;
    .tab_image_wrapper {
      .tab_image {
        width: 80px;
      }
    }

    .tab_name {
      font-style: normal;
      font-family: Inter-Bold;
      font-size: 11px;
      color: #f0f0e6;
    }
  }
}
