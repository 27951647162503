@import '../../../styles/sizes.scss';

.language_wrapper {
  height: $headerBtnDesktopHeight;
  padding: 0 5px;
  margin: 0 5px;

  & > div {
    box-shadow: none;
    text-transform: uppercase;
    font-size: 14px;
    font-family: Inter-Bold;

    img {
      margin-right: 4px;
      height: $flagDesktopHeight;
      border-radius: 4px;
      width: 26px;
      object-fit: contain;
    }
  }
}