@import '../../../styles/sizes.scss';
@import '../../../styles/mixins.scss';

.social_networks_container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  grid-area: social_icons;

  &.footer {
    &.social_networks_container {
      padding: 0;

      .social_icon_wrapper {
        img {
          height: $footer_socialIconSize;
          width: $footer_socialIconSize;
        }
      }
    }
  }

  .social_icon_wrapper {
    @include center();

    margin-right: 8px;
    cursor: pointer;

    img {
      height: $socialIconHeight;
      width: $socialIconWidth;
    }
  }
}

.mobile .footer {
  .social_networks_container {
    padding: 0;
    align-items: center;
    width: calc(100% - 60px - 10px); //60 bachto topbtn width and 10 is social icons margin value
    margin: 20px 0;

    .social_icon_wrapper {
      img {
        height: $footer_socialIconSize;
        width: $footer_socialIconSize;
      }
    }
  }
}

.sidebar {
  width: 100%;

  &.social_networks_container {
    justify-content: center;

    .social_icon_wrapper {
      background-color: var(--background-surface-1);
      margin-bottom: 8px;
      height: 36px;
      width: 36px;
      cursor: pointer;
      margin: 5px;

      img {
        width: 25px;
      }
    }
  }

  .desktop .social_networks_container {
    width: auto;
  }

  .mobile .footer {
    .social_networks_container {
      padding: 0;
      align-items: center;
      width: calc(100% - 60px - 10px); //60 bachto topbtn width and 10 is social icons margin value

      .social_icon_wrapper {
        background-color: var(--background-surface-3);
        height: calc($footer_socialIconSize + 8px);
        width: calc($footer_socialIconSize + 8px);

        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
