@import '../../../../../../../../styles/sizes.scss';
@import '../../../../../../../../configs/medias.scss';
@import '../../../../../../../../styles/mixins.scss';

.login_history_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  overflow: hidden;

  .entries_table_caption {
    width: 100%;
    background: var(--account-background-surface-2);
    border-radius: 10px;
    padding: 10px 25px;
    height: $login_history_list_item_height;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &__item {
      font-family: Inter-Medium;
      font-size: 16px;
      line-height: 19px;
      color: var(--text-secondary);
      text-transform: capitalize;
      min-width: 200px;
      width: 30%;

      &:last-child {
        text-align: end;
      }

      &:nth-child(2) {
        text-align: center;
      }
    }
  }

  .loader_wrapper {
    @include center();

    height: 100%;
    width: 100%;
  }

  .entries_table_list {
    @include scrollbar;

    width: 100%;
    height: calc(100% - 95px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
  }

  .entries_table_pagination {
    width: 100%;
    margin-top: 20px;
    text-align: center;

    .pagination {
      font-family: Inter-SemiBold;
      font-size: 12px;
      line-height: 15px;
      color: var(--text-secondary);

      :global {
        .ant-pagination-item {
          a {
            color: var(--text-secondary) !important;
          }

          &.ant-pagination-item-active {
            border-radius: 4px;
            background: var(--account-background-surface-2);
            height: 32px;
            width: 32px;
            border: none;
          }
        }

        .ant-pagination-prev,
        .ant-pagination-next {
          .ant-pagination-item-link {
            font-family: Inter-SemiBold;
            font-size: 14px;
            line-height: 15px;
            color: var(--text-secondary);

            .ant-pagination-item-container {
              .ant-pagination-item-ellipsis {
                font-family: Inter-SemiBold;
                font-size: 14px;
                line-height: 15px;
                color: var(--text-secondary);
              }
            }
          }

          &.ant-pagination-disabled {
            .ant-pagination-item-link {
              color: var(--text-primary);
            }
          }
        }

        .ant-pagination-jump-prev,
        .ant-pagination-jump-next {
          .ant-pagination-item-link {
            .ant-pagination-item-container {
              .ant-pagination-item-ellipsis,
              .anticon {
                color: var(--text-secondary);
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.login_history_container {
  width: 100%;
  padding: 0px 28px;
  height: $login_history_list_item_height;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--border-primary);
  border-radius: 10px;
  margin-bottom: 6px;

  &__item {
    font-family: Inter-Medium;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-secondary);
    text-transform: capitalize;
    min-width: 200px;
    width: 30%;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &__dot {
      background-color: red;
      margin-right: 8px;
      height: 9px;
      width: 9px;
      border-radius: 50%;

      &.success {
        background-color: var(--green);
      }
    }

    &:last-child {
      justify-content: flex-end;
    }

    &:nth-child(2) {
      justify-content: center;
    }
  }
}

@media (max-width: $tablet) {
  .login_history_container {
    text-align: left;
    padding: 0 1rem;
    height: 8vh;
    height: 8svh;

    &__item {
      min-width: unset;
      width: unset;
    }
  }

  .login_history_wrapper {
    padding: 0 12px;
    height: 90%;
    align-items: center;

    .entries_table_pagination {
      margin: 10px 0 30px;
    }

    .entries_table_caption {
      width: 100%;
      &__item {
        min-width: unset;
        width: unset;
      }
    }

    .entries_table_list {
      padding-right: 0px;
      height: 85%;
    }
  }
}
