@import '../../../../styles/constants';
@import '../../../../styles/mixins';

@keyframes modal-content {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.image_container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-surface-1);
  z-index: $modal-base__image-container--index;

  img {
    width: 100%;
  }
}

.right {
  position: absolute !important;
  right: 0;
  top: 0;
  margin: 0 !important;
}

.left {
  left: 0;
  position: absolute !important;
  top: 0;
  margin: 0 !important;
}

.center {
  left: 0;
  right: 0;
}

.portal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $modal-base__portal--index;
  overflow: hidden;
  background-color: var(--background-surface-1);
}

.modal {
  @include center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $modal-base__modal--index;
  overflow: hidden;
  pointer-events: none;
  width: 100%;
  height: 100%;

  &.fullHeight {
    .modal__content {
      height: fit-content;
    }
  }

  &.fullSize {
    .modal__content {
      height: 100%;
      width: 100%;
    }
  }

  &_open {
    pointer-events: auto;

    .modal__content {
      @include scrollbar();

      color: var(--text-secondary);
      opacity: 0;
      transform: translateY(40px);
      animation: modal-content 0.4s 0.2s forwards;
      overflow-x: hidden;
    }

    .modal__backdrop {
      opacity: 0;
      animation: show 0.15s forwards;
    }
  }

  &__backdrop {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(2px);
    will-change: opacity;
  }

  &__content {
    position: relative;
    z-index: $modal-base__modal-content--index;
    min-height: 142px;
    height: auto;
    margin: 24px;
    overflow: auto;
    border-radius: 8px;
    box-shadow: $box-shadow-modal;
    will-change: transform, opacity;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_width {
      &_min-content {
        width: initial;
        max-width: initial;
      }

      &_full {
        max-width: 100%;
      }
    }

    @media (max-width: $breakpoint-down-xs) {
      max-width: 100%;
      margin: 0;
      border-radius: 0;
      height: 100%;
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .modal {
    &.fullHeight {
      .modal__content {
        height: inherit;
        width: 100%;
      }
    }
  }
}
