.tour_modal_title {
  font-family: Inter-Bold;
  font-size: 26px;
  line-height: 1.2;
  color: var(--text-secondary);
  width: 100%;
  display: inline-block;
  text-align: center;
}

.tour_modal_text {
  padding: 0 45px;
  font-family: Inter-Medium;
  font-size: 18px;
  line-height: 1.18;
  color: var(--text-secondary);
  margin-bottom: 0;
  margin-top: 12px;
  text-align: center;
}

.tournament-results {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-radius: 8px;

  &__text {
    font-family: Inter-Bold;
    font-size: 16px;
    line-height: 19px;
    color: var(--text-secondary);
    text-align: left;
  }
  &__info-wrapper {
    margin: 12px 0 20px;
    background: #1b1d24;
    border: 1px solid var(--border-primary);
    border-radius: 8px;
    padding: 12px 10px;
    display: flex;
    justify-content: center;
  }
  &__info-item {
    position: relative;
    display: flex;
    align-items: center;
    font-family: Inter-Medium;
    font-size: 16px;
    line-height: 19px;
    & + .tournament-results__info-item {
      margin-left: 20px;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -12.5px;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        background: #d9d9d9;
        border-radius: 50%;
      }
    }
  }
  &__info-item-label {
    color: var(--text-secondary);
    text-transform: capitalize;
  }
  &__info-item-value {
    margin-left: 8px;
    color: #ffbf17;
    &--white {
      color: var(--text-secondary);
    }
  }
  &__winning-wrapper {
    margin-top: 8px;
    position: relative;
    height: 67px;
  }
  &__winning {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(31, 34, 45, 0.9);
    border: 1px solid #313341;
    backdrop-filter: blur(4px);
    border-radius: 8px;
    display: flex;
  }
  &__winning-icon-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: #313341;
    width: 55px;
    border-radius: 5px 0 0 5px;
    position: relative;
  }
  &__winning-icon {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: #1b1d24;
    &:before {
      content: '\e955';
      font-family: 'CloudSideTech' !important;
      speak: never;
      font-style: normal;
      font-family: Inter;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      color: #00bf00;
    }
  }
  &__ticket-icon {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: #1b1d24;
    &::before {
      content: '\e957';
      font-family: 'CloudSideTech' !important;
      speak: never;
      font-style: normal;
      font-family: Inter;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      color: #00bf00;
    }
  }
  &__winning-info-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 16px;
    font-family: Inter-Bold;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
  }
  &__winning-info-title {
    color: #00bf00;
  }
  &__winning-info-subtitle {
    color: var(--text-secondary);
    font-size: 16px;
    span {
      color: #00bf00;
    }
  }
}

.mobile {
  .tournament-results {
    &__info-wrapper {
      margin-top: 12px;
      flex-direction: column;
      padding: 0;
    }
    &__info-item {
      padding: 0 12px;
      height: 33px;
      & + .tournament-results__info-item {
        margin-left: 0;
        border-top: 1px solid #313341;
        &:before {
          content: none;
        }
      }
    }
    &__info-item-value {
      margin-left: auto;
    }
    &__winning-wrapper {
      margin-top: 16px;
      height: 85px;
    }
    &__winning-info-wrapper {
      font-size: 16px;
      line-height: 20px;
      padding: 0 12px;
    }
  }
}
