@import '../../../styles/sizes.scss';

.balance_wrapper {
  font-family: Inter-Bold;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  margin: 0 5px;
  flex-direction: column;
  align-items: flex-end;
  padding: 5px;

  h6 {
    padding: 0 15px;
    margin: 0;
    font-size: 10px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px;
    height: inherit;
    text-transform: capitalize;
    color: var(--text-secondary);
  }

  &.user_balance_loading button svg {
    animation: rotateSpin 0.3s infinite;
  }

  button {
    cursor: pointer;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-secondary);

    p {
      font-size: 14px;
      font-family: Inter-Bold;
    }

    &:hover {
      background-color: unset !important;
    }

    .user_balance_refresh {
      display: flex;
      width: $user_balance_refresh_icon_size;
      height: $user_balance_refresh_icon_size;
      margin-right: 10px;
      font-size: 19px;
      font-family: Inter-Bold;

      svg {
        color: inherit;
      }
    }

    @keyframes rotateSpin {
      from {
        transform: rotate(360deg);
      }

      to {
        transform: rotate(0deg);
      }
    }
  }
}

.desktop .balance_wrapper {
  height: $headerBtnDesktopHeight;

  button {
    height: $headerBtnDesktopHeight;
    width: inherit;
  }
}

.tablet .balance_wrapper {
  height: $headerBtnDesktopHeight;
}

.mobile .balance_wrapper {
  max-width: $balance_wrapper_max_width;
  height: $headerBtnMobileHeight;
  margin: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  button {
    font-size: 14px;
    width: inherit;
    max-width: inherit;
    margin: 0 !important;
    margin-right: unset !important;

    .user_balance_refresh {
      margin-right: 2px;
    }
  }
}
