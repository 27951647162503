/* stylelint-disable selector-max-compound-selectors, selector-class-pattern */
@import '../../../styles/constants';
@import '../../../styles/mixins';
@import '../../../styles/functions';
@import '../../../styles/sizes';

.button {
  @include center();

  width: 100%;
  height: inherit;
  padding: 0 16px;
  line-height: 1;
  position: relative;
  outline: none !important;
  transition: opacity $anim-time-fast, background-color $anim-time-fast, border-color $anim-time-fast,
    color $anim-time-fast;
  
  &__icon {
    margin: 0 10px 0 0;
  }

  &__right_icon {
    margin: 0 0 0 10px;
  }

  &__justify_content__flex-start {
    justify-content: flex-start;
  }
  &__justify_content__flex-end {
    justify-content: flex-end;
  }

  &__justify_content__center {
    justify-content: center;
  }

  &__justify_content__space-between {
    justify-content: space-between;
  }

  &_size_lg {
    height: $btn-lg-height;
  }

  &_size_md {
    height: $btn-md-height;
  }

  &_size_sm {
    height: $btn-sm-height;
  }

  &.button_variant_fit {
    width: fit-content;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &:active {
    box-shadow: none;
  }

  &__content {
    @include center();

    width: 100%;
    margin: 0 auto;
    height: 100%;

    div {
      margin: 0;
      color: inherit;
      display: flex;
      align-items: center;
    }

    &__typography {
      display: flex;
      align-items: center;
      white-space: nowrap;
      width: 100%;
      flex-wrap: wrap;
      width: 100%;

      p {
        margin: 0;
      }
    }
  }

  &_loading {
    pointer-events: none;
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }

  &_fontWeight {
    &_bold {
      font-family: Inter-Bold;
    }

    &_default {
      font-weight: initial;
    }
  }

  &_minWidth {
    min-width: unset;
  }

  &_disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &_padding {
    &_xs {
      padding: 0 16px;
    }

    &_sm {
      padding: 0 24px;
    }

    &_md {
      padding: 0 32px;
    }

    &_lg {
      padding: 0 40px;
    }
  }
}
