.subText {
  font-family: Inter-Medium;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--text-secondary);
}

.forgot_password_container {
  margin: 0 0 20px;
}

.recoverButton {
  height: 47px;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
}

.invalid_error {
  text-align: center;
}
