@import '../../../styles/constants';
@import '../../../styles/mixins';

@keyframes spin-the-thing {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.loader_spinner_wrapper {
  display: flex;
  position: absolute;
  top: 12px;
  right: 9px;
  height: 16px;
  width: 16px;
  animation: spin-the-thing 1.4s infinite;
  animation-timing-function: linear;
}

.input {
  position: relative;
  margin: 0 0 10px;

  .error {
    p {
      position: relative;
      top: -4px;
      left: 4px;
      color: red;
      font-size: 11px;
      text-transform: unset;
    }
  }

  &_clear {
    position: absolute;
    top: 8px;
    right: 8px;

    &_hasLabel {
      right: 10px;
      top: 38px;
    }
  }

  input {
    width: 100%;
    outline: none;
    box-shadow: none;
    transition: border-color $anim-time-fast, background-color $anim-time-fast, opacity $anim-time;
    display: flex;
    flex-direction: column;
    font-family: Inter-Medium;
    display: block;
    width: 100%;
    font-size: 12px;
    height: 35px;
    line-height: 35px;
    padding: 0 0 0 8px;
    border-radius: 8px;
    height: 40px;
    border: 1px solid var(--input-border-default);
    color: var(--text-primary);
    background-color: var(--input-background-surface-1);

    &:hover {
      border:1px solid var(--input-border-hover);
      
    }

    &.light {
      border: 1px solid var(--border-primary);
      background-color: white;
      color: var(--text-primary);

      &:focus {
        background-color: white;
      }
    }

    &.transparent {
      border: none;
      background-color: transparent;
      color: var(--text-secondary);

      &:focus {
        background-color: transparent;
        border: none;
      }
    }

    &::placeholder {
      font-size: 12px;
      font-family: Inter;
      height: 35px;
      color: var(--text-secondary);
    }

    &:focus {
      border: 1px solid var(--input-border-typing);
    }

    &:read-only {
      // need add correct color and bg
      cursor: auto;
    }

    &:disabled {
      background-color: var(--input-background-disable);
      cursor: not-allowed; // need add correct color and bg
    }
  }

  &__rightBlock {
    &_xs {
      input {
        padding-right: 28px;
      }
    }

    &_sm {
      input {
        padding-right: 36px;
      }
    }

    &_md {
      input {
        padding-right: 44px;
      }
    }

    &_lg {
      input {
        padding-right: 52px;
      }
    }

    &_left {
      left: 0;
    }
  }

  .input_currency {
    padding: 0 15px;
    border-left: 1px solid var(--border-primary);
    margin-left: 14px;
  }

  .iconContainer {
    margin: 0 15px;
    position: absolute;
    right: 0;
    top: 36px;

    svg path {
      fill: var(--text-primary);
    }
  }

  &_error {
    input {
      &:focus {
        box-shadow: none;
      }
    }
  }

  &_label {
    font-size: 14px;
    color: var(--text-primary);
    margin-bottom: 8px;
    text-transform: capitalize;

    span {
      color: red;
    }
  }

  &__rightBlock {
    @include center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: 100%;

    &_xs {
      width: 28px;
    }

    &_sm {
      width: 36px;
    }

    &_md {
      width: fit-content;
      height: 40px;
      bottom: 0;
      top: 30px;
      transform: unset;
    }

    &_left {
      left: 0;
      top: 0;
    }
  }

  &_size {
    &__lg {
      width: 500px;
    }

    &__md {
      width: 343px;
    }

    &_sm {
      width: 232px;
    }
  }

  input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  input[name='phone-code'] {
    padding: 0 !important;
    text-align: center;
  }

  .numberInput {
    border: 2px solid var(--input-border-default);
    display: inline-flex;
  }

  .numberInput,
  .numberInput * {
    box-sizing: border-box;
  }

  .numberInput button {
    outline: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    margin: 0;
    position: relative;
  }

  .numberInput button {
    &::before {
      content: '';
      position: absolute;
      top: 10%;
      width: 1rem;
      height: 2px;
      background-color: var(--background-surface-3);
      transform: translate(-50%, -50%);
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 10%;
      transform: translate(-50%, -50%);
      height: 2px;
      width: 1rem;
      background-color: var(--input-background-surface-1);
    }
  }

  .numberInput button.plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  &_wrapper {
    position: relative;

    .iconContainer {
      @include center;

      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      height: 100%;
    }
  }
}
