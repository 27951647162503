.awards_container {
  grid-area: awards;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--border-primary);
  border-bottom: 1px solid var(--border-primary);
  padding: 20px;
  width: 100%;

  img {
    margin: 0 20px;
  }
}

.desktop {
  .awards_container {
    img {
      max-width: 125px;
    }
  }
}

.mobile {
  .awards_container {
    border: none;
    margin: 0;
    justify-content: space-around;
    padding: 20px;

    img {
      max-width: 80px;
      margin: 0;
    }
  }
}
