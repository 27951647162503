@import '../../../../styles/mixins.scss';

.promo_iframe {
  @include scrollbar();

  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  width: 95vw;
  height: 95vh;
}

.mobile .promo_iframe {
  width: 90svw;
  height: 90svh;
  padding: 0;
}
