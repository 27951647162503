.payment__information {
  align-items: center;
  background: var(--background-surface-3);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 16px;
  min-height: 50px;
  padding: 8px 13px;
  width: fit-content;
  margin: 20px 0;
}

.payment_information_item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 60px;

  &__title {
    font-family: Inter-Medium;
    font-size: 12px;
    line-height: 15px;
    color: var(--text-primary);
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  &__value {
    font-family: Inter-SemiBold;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-secondary);
  }
}

@media (max-width: 766px) {
  .payment__information {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    margin: 0;

    .payment_information_item {
      margin: 0 !important;
    }
  }
}
