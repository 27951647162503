@import '../../../../styles/mixins.scss';

.container {
  display: flex;

  .bankItem {
    width: 21%;
    height: 50px;
    margin: 2%;
    padding: 3px;
    border-radius: 3px;
    text-align: center;

    &.selected {
      border: 3px solid yellow;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.input_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  position: relative;
  outline: none;

  label {
    font-size: 14px;
    color: var(--text-secondary);
  }

  input,
  select {
    border-radius: 10px;
    height: 40px;
    width: 350px !important;
    max-width: 100%;
    padding: 0 10px;
    color: var(--text-secondary);
    border: 1px solid var(--border-primary);
    background-color: var(--background-surface-2);
    appearance: none;
    -webkit-appearance: none !important;
  }

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  select {
    @include scrollbar();

    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  }

  .payment_currency {
    position: absolute;
    top: 40px;
    right: 3%;
    font-size: 12px;
    color: var(--text-secondary);
  }
}

.payment_error {
  color: red;
  font-size: 12px;
  font-style: italic;
  margin: 3px 0 0 5px;
}

.radio_group_container {
  width: 350px;
  max-width: 350px;

  .radio_input_container {
    display: flex;
    margin-bottom: 12px;
    position: relative;
    width: 100%;

    input {
      margin-right: 12px;
      width: 18px;
      border: none !important;

      &:after {
        width: 18px;
        height: 18px;
        border-radius: 15px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        background-color: var(--background-surface-3);
        content: '';
        display: inline-block;
        position: relative;
        top: 2.5px;
      }

      &:checked:after {
        background-color: var(--main-surface-3);
      }
    }
  }
}
