.secondary-ul {
  background-color: var(--button-secondary);
  color: var(--button-secondary-text);
  margin-top: 8px;

  li {
    height: 43px;

    &:hover {
      border-radius: 8px;
      background-color: var(--surface-2);
    }
  }
}
