@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/constants/z-indexes.scss';


#game_launch_preview {
  width: 100vw;
  height: 100vh;
  position: absolute;
  .game-bg-image {
    object-fit: cover;
    width: 150px;
    margin: 15px auto;
  }
  .popup_container {
    position: fixed;
    .popup  {
      border-radius: 10px;
    }
    .popup_body {
      position: relative;
    }
  }
  .btn-section {
    z-index: $modal-base__modal-content--index;
    @include center();
    flex-direction: column;
    padding: 15px;
    width: 100%;
    button {
      margin: 5px 5px;
      max-width: 350px;
    }
  }
}

.game_launch_preview-container {
  position: relative;
  height: 300px;
  width: 450px;
}
.game-preview-btn--container {
  display: flex;
}
.game_launch_preview--icon {
  padding-right: 6px;
  svg {
    height: 16px;
  }
}

.game_launch_preview--bg-overlay {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  background-color: rgba(23, 25, 34, 0.7);
}

.game_launch_preview--footer {
  position: absolute;
  bottom: 0px;
  z-index: $modal-base__modal-content--index;
  @include center();
  flex-direction: column;
  background: var(--background-surface-1);
  padding: 15px;
  width: 100%;
  background-color: rgba(23,25,34,0.7);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  .btn-section {
    button {
      border-radius: 10px;
    }
  }
}
.game_launch_preview--description {
  
  display: flex;
}
.game_launch_preview--description--info {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  padding: 0 15px;
}

.mobile {
  #game_launch_preview {
    position: unset;
    .game-bg-image {
      width: 120px;
      margin: 10px auto;
    }
    // background-color: rgba(23,25,34,0.7);
    .popup_container {
      .popup  {
        height: unset;
        width: 90vw;
      }
      .popup_body {
        position: relative;
      }
    }
    .popup_heading_with_title {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .game_launch_preview-container {
      width: 100%;
      height: 300px;
    }
  }
}
