//* Semantica: component-name__element-name--index: [index]

// Icons
// $popup__popup-close--index: 200;
// $game-tags__control-icon-wrapper--index: 10;
// $modal-header__icon--index: 100;
// $payment-view__notif-svg--index: 200;
// $refer-a-friend__container-mobile-header-arrow-down--index: 90; //! not found, only classname
// $refer-a-friend__fixed-logo--index: 80;
// $sign-up-content__back_icon--index: 100;
// $account-modal__info-mobile-header-and-arrow-down--index: 90;
// $registration-failure__container-dialog-template--index: 150; //! not found, only classname
// $sign-up-modal__prev-icon-svg--index: 120;
// $provider-filter__providers-wrapper-control-icon--index: 10; //! not found, only classname
// $tag-row__game-tag-control-icon--index: 10;
// $footer__footer-icon-btn-rotate-arrow--index: 10;

// Dropdowns
$provider-filter__active-checkbox-dropdown-list--index: 150;
$help-center__search-suggestions--index: 10; //? no matter what index
$history__ant-picker-dropdown--index: 120;
$provider-filter__provider_dropdown--index: 114;
$provider-filter__providers-accordion-wrapper-opened--index: 121;
$dropdown__dropdown--index: 10;
$input__select-wrapper-dropdown--index: 10;

// Modals
// $refer-a-friend__referred-friends-list-modal--index: 80;
// $refer-a-friend__terms-and-condition-modal--index: 80;
// $refer-a-friend__how-it-works-container-modal--index: 80;
// $refer-a-friend__invite-a-friend-modal--index: 80;
// $refer-a-friend__terms-and-condition-modal-mobile--index: 80;
// $refer-a-friend__referred-friends-list-modal-mobile--index: 80;
// $account-modal__mobile--index: 80;
// $search__search-modal-container--index: 110;
// $search__modal-search-container--index: 80;
// $search__modal-search-container-focused--index: 110;

// Inputs
// $help-center__search-input--index: 10;

// Chats
// $zendesk-chat__conatiner--index: 80;

// Sceleton
// $game-card-sceleton--index: 10;

// Iframes & Pages
// $main__full-screen-page--index: 120;
// $section-loader-page__container--index: 90;
// $section-loader-page__container-mobile--index: 40;
// $general-loader-page__container--index: 200;

// Header
// $iframe__game-frame-header-container--index: 30; //! not found, only classname
// $account-modal__info-mobile-header--index: 90; //? no matter
// $header--index: 100;

// Footer
// $footer__footer-with-btn--index: 80;
// $footer__footer-with-btn-open--index: 120;

// Overlays
$game-card__game-card-game-overlay--index: 100;

// Wrappers
// $auth-modal__content-with-image-wrapper-mobile--index: 180; //? no matter what index
// $auth-modal__content-with-image-wrapper-right-content-mobile--index: 100; //? no matter what index
// $auth-modal__content-with-image-wrapper-mobile-content-img--index: 10; //? no matter what index
// $auth-modal__content-without-image-wrapper-mobile--index: 180; //? no matter what index

// Backdrops
// $refer-a-friend__backdrop--index: 30;
// $provider-filter__backdrop_provider--index: 100;
// $popup__popup-container-backdrop--index: -1;

// PsevdoClasses
$sign-up__tab-container-done-after--index: 10;
$game-card__game-card-game-overlay-img-play-after--index: 1000;
$main__overlay-before--index: 90;

// Containers
// $game-tags__games-container--index: 10; //? no matter
// $change-password__container--index: 170;
$payment-view__payment-container--index: 150;
$payment-view__mobile-payment-container-group-container--index: 100;
$account-modal__info-mobile-conatiner--index: 160;
$mobile-fixed-menu__container--index: 40;
// $mobile-menu__container--index: 100;
// $mobile-providers__container--index: 100;
// $mobile-search__container--index: 100;
$provider-filter__container--index: 80;
$provider-filter__providers-container--index: 80;
// $search__search_backdrop--index: 100;
$user-settings--index: 80;
$user-settings__tablet--index: 10;
$game-card__game-image--index: 90;
$game-card__game-card-image-wrapper--index: 10;
$sign-up__step-container--index: 90; //? no matter

// ReferAFriendWidget
// $refer-a-friend__wrapper--index: 10;
$refer-a-friend__wrapper-bg-image--index: 1;
$refer-a-friend__wrapper-button--index: 2;

// Slider
$slider__banner-container--index: 10;
$slider__banner-small-arrows--index: 80;
$slider__banner-small-arrows-wrapper--index: 90;
$slider__banner-small-arrows-wrapper-overlay--index: 30;
$slider__banner-small-arrows-arrow--index: 10;
$slider__banner-arrows--index: 80;
$slider__game-container-template-4-content--index: 10;
$slider__game-container-template-3-arrows--index: 90;
$slider__game-container-header-game-btn-container-arrows--index: 90;

// ModalBase
$modal-base__image-container--index: 100;
$modal-base__portal--index: 100;
$modal-base__modal--index: 160;
$modal-base__modal-content--index: 1000;

// PopUp
$popup__heading-title--index: 200; //? no matter, can be 1

// Template_1
$template-1__desktop-header--index: 115;

// Template_2
$template-2__dropdown-menu-list--index: 10;

// Template_6
$template-6__desktop-header--index: 120;

/*---------------------- NEW INDEXES --------------------*/

// Icons
$icon-index: 10;

// Modals
$focused-modal-index: 110;
$modal-index: 80;
$modal-header-index: 10;

// Chat
$chat-index: 115;

// Sceleton
$sceleton-index: 10;

// Footer
$footer__footer-with-btn--index: 80;
$footer__footer-with-btn-open--index: 120;

// Header
$header--index: 100;

// Iframes & Pages
$full-screen-page--index: 120;

// Loader
$general-loader-index: 500;
$login-page-index: 400;
$section-loader-index: 92;

// Backdrops
$popup__popup-container-backdrop--index: -1;

// Mobile Full Page Widgets
$mobile-full-page-widgets-index: 100;
