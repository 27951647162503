@import '../../../styles/sizes.scss';
@import '../../../styles/constants';

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.refer_a_friend_wrapper {
  margin: 0 5px 0 30px;
  height: $headerBtnDesktopHeight;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  position: relative;
  border-radius: 8px;
  // z-index: $refer-a-friend__wrapper--index;
  width: 216px;

  .refer_a_friend_wrapper_btn {
    background-image: url('./PT.webp');
    background-size: cover;
    & > div {
      padding-left: 25px;
    }
  }

  .bg_image {
    position: absolute;
    height: inherit;
    width: 100%;
    z-index: $refer-a-friend__wrapper-bg-image--index;
  }

  &:hover {
    &::after {
      background: linear-gradient(60deg, #3445a5, #4b59a5, #6e76a5, #ffffff, #6e76a5, #4b59a5, #3445a5);
      animation: animatedgradient 3s ease alternate infinite;
      background-size: 300% 300%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    height: 45px;
    width: 101%;
    background: linear-gradient(180deg, #33408a, #ffffff);
    background-size: 300% 300%;
    border-radius: 10px;
  }

  button {
    // width: $depositDesktopWidth;
    border-radius: 8px;
    font-style: normal;
    font-family: Inter-Bold;
    font-size: 15px;
    line-height: 19px;
    color: #ffffff; // TODO bg not dynamic
    text-transform: capitalize;
    z-index: $refer-a-friend__wrapper-button--index;
    margin: 0 auto;

    p {
      margin: 0;
    }

    svg {
      margin-right: 10px;
    }
  }
}

.desktop .refer_a_friend_wrapper {
  button {
    height: inherit;
  }
}

.tablet .refer_a_friend_wrapper {
  margin: 10px;

  button svg {
    margin: unset;
  }
}
