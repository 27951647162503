.loader {
  display: flex;
  justify-content: center;
  transform: translate(-50%, -50%);
}

.lg {
  width: 38px;
  height: 38px;
}

.md {
  width: 30px;
  height: 30px;
}

.sm {
  width: 20px;
  height: 20px;

  img {
    margin-top: 10px;
  }
}

.loader_inner {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;

  svg rect {
    fill: white;
  }
}

.loader_inner::before {
  content: '';
  position: absolute;
  top: 12px;
  left: 12px;
  background-size: 100%;
  animation: loader-animation 1.5s infinite ease-in-out;
}

@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 480px) {
  .loader {
    display: flex;
    justify-self: center;

    &.lg {
      height: 40px;
      width: 40px;
    }

    &.md {
      width: 30px;
      height: 30px;
    }

    &.sm {
      width: 12px;
      height: 12px;

      img {
        margin-top: 5px;
        margin-left: -10px;
      }
    }

    .loader_inner {
      left: 50%;
    }
  }
}
