.paymentList_container {
  grid-area: payment_list;
  margin: 10px auto 30px;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .payment {
    height: inherit;
    width: 15%;
    margin: 5px 10px;

    img {
      object-fit: contain;
    }
  }
}
