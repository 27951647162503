.error_message_title{
  text-align: center;
  color: var(--text-primary);
}

.error_message_id{
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--text-primary);
}
.copied{
  color: var(--text-primary);
}