@import '../../../styles/mixins';

.sidebar_menu {
  @include center();

  height: 57px;
  cursor: pointer;

  .sidebar_menu_inner {
    @include center();

    background-color: var(--background-surface-1);
    height: 30px;
    width: 30px;
    border-radius: 4px;

    &:hover {
      background-color: var(--navigation-nav-surface-2-hover);
    }

    svg {
      width: 14px;
    }
  }

  &--opened {
    padding: 0 10px;
  }
}
