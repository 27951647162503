.cards_container_item {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: var(--background-surface-3);
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  &_title {
    font-size: 20px;
    color: var(--text-primary);
    margin-bottom: 10px;
    margin-left: 4px;
    font-weight: 700;
  }
  &_description {
    color: var(--text-primary);
    margin-top: 10px;
    margin-left: 4px;
    margin-bottom: 0;
  }
}

.mobile {
  .cards_container_item {
    &_title {
      margin-bottom: 6px;
    }
    &_description {
      color: var(--text-primary);
      margin-top: 6px;
    }
  }
}
