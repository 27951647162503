@import '../../../../../../styles/constants/';
@import '../../../../../../styles/mixins';
@import '../../../../../../configs/medias.scss';

.loginModal__content {
  max-width: 443px;
  color: var(--text-secondary);

  &__checkbox__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 25px 0px 35px;

    .loginModal__content__checkbox {
      background-color: transparent;
      height: 20px;
      width: 20px;
      border: 1px solid var(--border-primary);
      border-radius: 5px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 4px;
      margin-right: 8px;
    }

    p {
      cursor: pointer;
      white-space: nowrap;
      font-size: 14px;
      margin-bottom: 0;
    }

    .errorMessage {
      color: var(--red);
    }
  }

  .loginButton {
    width: 100%;
    height: 47px;
    border-radius: 10px;
    font-size: 16px;
    text-transform: uppercase;

    &:disabled {
      background: var(--background-surface-3);
      color: var(--text-secondary);
    }
  }

  .forgotPass {
    padding: 0;
    text-align: left;
    margin: 12px auto 0;

    span {
      color: var(--text-secondary);
      font-size: 12px;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .signUpButton {
    font-size: 12px;
    font-family: Inter-SemiBold;
    padding-left: 10px;
    width: auto;
    margin: 40px auto 0;
    height: auto;
    color: var(--text-brand);

    span {
      color: var(--text-secondary);
      margin-right: 10px;
    }
  }

  .signin_error {
    text-align: center;
  }
  .login_text {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .loginModal__content {
    margin-top: 15px;

    .withoutImage_body {
      .reg_close {
        background: var(--background-surface-3);
        border-radius: 50%;
        position: absolute;
        top: 17px;
        right: 15px;
        cursor: pointer;
      }
    }

    .content_with_image_wrapper {
      .reg_close {
        background: var(--background-surface-3);
        border-radius: 50%;
        position: absolute;
        top: 12px;
        right: 15px;
        cursor: pointer;
      }
    }
    input {
      font-size: 14px;
    }
  }
}
