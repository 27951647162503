@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/constants/z-indexes.scss';

.button-container {
  width: 80%;
  display: flex;
  margin: 5px;
  .toaster-btn {
    width: 46%;
    margin: 2%;
    height: 30px;
  }
}

.first-deposit-to-bonus-container {
  position: relative;
  height: 300px;
  width: 450px;
  .first-deposit-to-bonus {
    color: white;
    padding: 15px;
  }
}

#first-deposit-to-bonus {
  width: 100vw;
  height: 100vh;
  position: absolute;
  .game-bg-image {
    object-fit: fill;
    width: 200px;
    margin: 15px auto;
  }
  .popup_container {
    .popup  {
      border-radius: 10px;
    }
    .popup_body {
      position: relative;
    }
  }
  .btn-section {
    z-index: $modal-base__modal-content--index;
    @include center();
    flex-direction: column;
    padding: 15px;
    width: 100%;
    button {
      margin: 5px 5px;
      max-width: 350px;
      text-transform: capitalize;
      padding: 0;
    }
  }
  .first-deposit-to-bonus--footer {
    position: absolute;
    bottom: 0px;
    z-index: $modal-base__modal-content--index;
    @include center();
    flex-direction: column;
    background: var(--background-surface-1);
    padding: 15px;
    width: 100%;
    background-color: rgba(23,25,34,0.7);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.mobile {
  #first-deposit-to-bonus {
    position: static;
    .first-deposit-to-bonus-container {
      height: 240px;
      width: 100%;
    }
    .popup_container .popup {
      height: auto;
      width: 90vw;
    }
    .popup_heading_with_title {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
}