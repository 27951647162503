@import '../mixins.scss';
@import '../constants';

.desktop {
  &.template_fullScreen {
    .header-3Rows-with_nl {
      .middleLine {
        padding: 0 30px;
      }

      .home_container,
      .page_container,
      .promotions__container,
      .casinoTournament #tournament,
      .poker #tournament,
      .race #raceIframe,
      .staticIframe #staticIframe,
      .betting #sport_frame .sport,
      .betting #sport,
      .betting .loading_spinner,
      .tombala #tombala,
      .help-center,
      .help-center__articles__holder {
        margin: calc($headerTopLineHeight-3Row + $headerMiddleLineHeight-3Rows + $headerBottomLineHeight-3Rows + 20px)
          auto 0;
      }
      .promotions__container {
        max-width: 1350px;
      }

      .casinoTournament #tournament,
      .poker #tournament,
      .race #raceIframe,
      .staticIframe #staticIframe,
      .betting #sport_frame .sport,
      .betting #sport,
      .betting .loading_spinner,
      .tombala #tombala {
        margin: calc($headerTopLineHeight-3Row + $headerMiddleLineHeight-3Rows + $headerBottomLineHeight-3Rows) auto 0;
        height: calc(100vh - $headerTopLineHeight-3Row - $headerMiddleLineHeight-3Rows - $headerBottomLineHeight-3Rows);
        visibility: visible;
      }
      .tombala #tombala {
        width: 100%;
      }

      .promotions__inner {
        max-width: $maxWidth;
        margin: 0 auto;
      }
    }

    .header-2Rows-with-nl {
      .home_container,
      .page_container,
      .promotions__container,
      .home_container {
        margin: calc($headerTopLineHeight-2Rows + $headerBottomLineHeight-2Rows) auto 0;
      }

      .casinoTournament #tournament,
      .race #raceIframe,
      .staticIframe #staticIframe,
      .dinhutech #staticIframe,
      .betting #sport_frame,
      .betting #sport,
      .liveSport #sport,
      .cybersport #cybersport,
      .tombala #tombala,
      .blogFrame #blogIframe,
      .poker #pokerIframe,
      .silvaSeries #staticIframe {
        margin: calc($headerTopLineHeight-2Rows + $headerBottomLineHeight-2Rows) 0 auto;
        visibility: visible;
        height: calc(
          100vh - $mobileHeaderTopLineHeight-2Row_with_nl - $mobileHeaderBottomLineHeight_2Row_with_nl -
            $mobileFixedMenuHeight
        );
      }
    }

    .header-2Rows-with-nl-fullScreen {
      .promotions__container {
        max-width: $maxWidth;
        margin: calc($headerTopLineHeight-2Rows + $headerBottomLineHeight-2Rows) auto 0;
      }

      .page_content {
        margin: calc($headerTopLineHeight-2Rows + $headerBottomLineHeight-2Rows) auto 0;
      }

      .casinoTournament #tournament,
      .race #raceIframe,
      .staticIframe #staticIframe,
      .dinhutech #staticIframe,
      .betting #sport_frame,
      .betting #sport,
      .liveSport #sport,
      .cybersport #cybersport,
      .tombala #tombala,
      .poker #pokerIframe,
      .blogFrame #blogIframe,
      .silvaSeries #staticIframe,
      .fun-mission #funMissionIframe {
        margin: calc($headerTopLineHeight-2Rows + $headerBottomLineHeight-2Rows) auto 0;
        visibility: visible;
        height: calc(100vh - $headerTopLineHeight-2Rows + $headerBottomLineHeight-2Rows-fullscreen);
      }
      .tombala #tombala {
        width: 100%;
        height: calc(100vh + 99px);
        margin: 0 auto;
      }

      .footer_container,
      .promotions__inner {
        max-width: $maxWidth;
        margin: 0 auto;
      }
    }

    .header-1Row {
      .home_container {
        margin: $headerTopLineHeight-1Row auto 0;
      }
    }

    .page_container {
      max-width: $maxWidth;

      .page_container_second_section {
        display: flex;
        margin: 0 auto 30px;
      }
    }

    .home_container {
      .home_widget_template_3-1,
      .home_slider_container {
        max-width: $maxWidth;
        margin: 0 auto;
      }
    }
  }
  .fullWidth {
    .page_container {
      .page_container_second_section {
        display: block;
      }
    }
  }
}

.mobile {
  &.template_fullScreen {
    margin: 0 auto;

    .header-1Row {
      .home_container,
      .page_container {
        margin-top: $headerTopLineHeight-1Row;
      }
    }

    .header-2Rows-with-nl {
      .home_container,
      .page_container,
      .help-center__articles__holder {
        margin-top: calc($mobileHeaderTopLineHeight-2Row_with_nl + $mobileHeaderBottomLineHeight_2Row_with_nl + 10px);
      }

      .casinoTournament #tournament,
      .poker #tournament,
      .blogFrame #blogIframe,
      .race #raceIframe,
      .staticIframe #staticIframe,
      .betting #sport,
      .blogFrame #blogIframe,
      .betting #sport_frame,
      .blogFrame #blogIframe,
      .fun-mission #funMissionIframe,
      .tombala #tombala {
        height: calc(
          100vh - $mobileHeaderTopLineHeight-2Row_with_nl - $mobileHeaderBottomLineHeight_2Row_with_nl -
            $mobileFixedMenuHeight
        );
        margin-top: $mobileHeaderTopLineHeight-2Row_with_nl + $mobileHeaderBottomLineHeight_2Row_with_nl;
        visibility: visible;
      }
      &.betting #sport,
      .blogFrame #blogIframe,
      &.betting #sport_frame {
        height: calc(100svh - $mobileFixedMenuHeight);
        visibility: visible;
        padding-top: calc($mobileHeaderTopLineHeight-2Row_with_nl + $mobileHeaderBottomLineHeight_2Row_with_nl);
      }
      .safary_sport_frame {
        height: calc(100svh - $mobileFixedMenuHeight) !important;
      }
      &.tombala #tombala,
      .blogFrame #blogIframe {
        height: calc(100svh - $mobileFixedMenuHeight);
        visibility: visible;
        padding-top: calc($mobileHeaderTopLineHeight-2Row_with_nl + $mobileHeaderBottomLineHeight_2Row_with_nl);
      }
    }

    .footer {
      .social_networks_container {
        justify-content: center;
        width: 75%;

        .social_icon_wrapper {
          margin: 5px;
        }
      }
    }
    .fullWidth {
      margin: 0 auto $mobileFixedMenuHeight;
      .promotions__container,
        .content_container,
        .home_container,
        .page_container {
          margin: $mobileHeaderTopLineHeight-1Row 0 0;
        }
        .content_container {
          max-width: 99%; // for poker or other content page left-right scroll
        }

        &.casinoTournament #tournament,
        &.race #raceIframe,
        &.staticIframe #staticIframe,
        &.betting #sport_frame,
        &.betting #sport,
        &.liveSport #sport,
        &.cybersport #cybersport,
        &.tombala #tombala,
        &.poker #pokerIframe,
        &.silvaSeries #staticIframe,
        &.blog #blogIframe,
        &.fun-mission #funMissionIframe {
          visibility: visible;
          margin: $mobileHeaderTopLineHeight-1Row 0 $mobileFixedMenuHeight;

          @supports (-webkit-touch-callout: none) {
            height: calc(100vh - $mobileHeaderTopLineHeight-1Row - $mobileFixedMenuHeight);
            height: calc(100svh - $mobileHeaderTopLineHeight-1Row - $mobileFixedMenuHeight);
          }

          @supports not (-webkit-touch-callout: none) {
            height: calc(
                    100vh - $mobileHeaderTopLineHeight-1Row - $mobileFixedMenuHeight - 50px
            ); // additional fix for andriod
          }
        }
        @supports (-webkit-overflow-scrolling: touch) {
          &.fun-mission #funMissionIframe {
            margin: $mobileHeaderTopLineHeight-1Row 0 0;
            @supports not (-webkit-touch-callout: none) {
              height: calc(100vh - $mobileHeaderTopLineHeight-1Row - 0px - 50px);
            }
          }
        }
        &.casinoTournament #tournament {
          @supports not (-webkit-touch-callout: none) {
            height: calc(
                    100vh - $mobileHeaderTopLineHeight-1Row - $mobileFixedMenuHeight
            ); // additional fix for andriod
          }
        }
        &.casinoTournament #tournament.fullScreen {
          height: 100vh;
          height: 100svh;
          margin-top: 0;
          max-width: none;
          padding: 0;
          width: 100vw;
        }
    }
  }
}
