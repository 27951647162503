@import '../../../../../../../../styles/sizes.scss';
@import '../../../../../../../../configs/medias.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-right: 20px;

  &__item {
    max-width: $account_personal_info_item_width;
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__support {
    color: var(--text-secondary);
  }

  &__birthDate_item {
    width: $account_personal_info_birthDate_item_width;
  }

  .detail_container {
    width: 100%;
    border-radius: 8px;
    color: var(--text-secondary);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: var(--account-background-surface-2);
    padding-bottom: 8px;

    .title {
      text-transform: capitalize;
      color: var(--text-secondary);
      line-height: normal;
      padding: 8px 12px;
    }

    .value {
      padding: 8px 12px;
    }

    .tooltip_wrapper {
      width: 100%;
    }
  }
}

@media (max-width: $tablet) {
  .container {
    padding: 0 1rem;

    &__item {
      width: 100%;
    }

    &__birthDate_item {
      width: 31%;
    }
  }
}
