.activity_info_wrapper {
  font-size: 14px;
  font-family: Inter;
  line-height: 17px;
  grid-area: terms_brief_info_block;
  color: var(--text-secondary);
  margin: 0;
}

.link-to-term-and-conditions {
  text-decoration: underline;
  cursor: pointer;
  font-family: Inter-Bold;
  margin: 0px 4px;
}

.mobile .activity_info_wrapper {
  margin: 20px 0;
  font-size: 12px;
}
