.promotions {
  margin: 0 auto;

  &__bonus-item {
    width: 100%;
    margin: 15px 10px;
    overflow: hidden;
    border-radius: 27px;
    background-color: var(--background-surface-2);
    display: flex;
    flex-direction: column;
    height: 100%;

    &-image-wrapper img {
      display: block;
      width: 100%;
      min-height: 200px;
      object-fit: cover;
    }
  }

  &__item-title-description-button-wrapper {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }

  &__item-title {
    * {
      margin: 0 0 8px;
      padding: 0;
      font-family: Inter-Bold;
      font-size: 1.1rem;
      color: var(--text-primary);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      text-wrap: wrap;
    }
  }

  &__item-description {
    margin-top: 8px;
    font-family: Inter-Medium;
    font-size: 16px;
    color: var(--text-secondary);

    p {
      width: 100%;

      span {
        background-color: #0000 !important;
        color: var(--text-secondary) !important;
        display: inline-block;
        font-family: Inter-Medium;
        font-size: 1rem !important;
        font-style: normal;
        max-width: 365px;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
    }

    * {
      margin: 0;
      padding: 0;
    }
  }
  &__button {
    font-family: Inter-Bold;
    font-size: 16px;
    line-height: 19px;
    border-radius: 8px;
    height: 40px;
    text-transform: uppercase;
    cursor: pointer;

    &--item {
      padding: 0px 45px;
    }

    &--description {
      margin: 17px auto 0;
      padding: 0 20px;
    }
  }
}

.mobile {
  .promotions__container {
    padding: 10px 0;

    .promotions__bonus-item {
      width: 100%;
      margin: 0;

      &-image-wrapper {
        img {
          width: 100%;
        }
      }

      & + .promotions__bonus-item {
        margin-top: 15px;
      }
    }
    .promotions__item-title-description-button-wrapper {
      padding: 12px 16px 16px;
      p {
        width: 100%;

        span {
          max-width: 80vw;
        }
      }
    }
    .promotions__button {
      &--item {
        width: 100%;
        margin-top: 16px;
      }
      &--description {
        margin: 36px 0 0;
        padding: 0 8px;
        width: 100%;
      }
    }
  }
}
