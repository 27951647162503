.phone_number_input_wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;

  .phoneCode {
    width: 20%;

    input {
      background: var(--background-surface-2);
      color: var(--text-primary);
    }

    & > p {
      white-space: nowrap;
    }
  }

  .phoneNumber {
    width: 78%;
  }
}
