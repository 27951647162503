@import '../../../styles/sizes.scss';
@import '../../../styles/mixins.scss';

.gameBox_toggle_wrapper {
  @include center();

  margin: 0 5px;
  width: 45px;
  position: relative;

  button {
    cursor: pointer;
    border-radius: 8px;
    padding: 0 2.5px;

    &.opened {
      border: 1px solid var(--default-widget-selected-border);
    }
  }

  .actual_missions {
    @include center();
    position: absolute;
    top: -4px;
    right: -4px;
    width: 15px;
    height: 15px;
    font-size: 10px;
    color: var(--text-primary);
    font-family: Inter-Bold;
    background-color: #CD2026;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
  }
}

.desktop .gameBox_toggle_wrapper {
  height: $btn-lg-height;

  button {
    height: $btn-lg-height;
    width: inherit;
  }
}

.tablet .gameBox_toggle_wrapper {
  button {
    width: inherit;
  }
}

.mobile .gameBox_toggle_wrapper {
  max-width: $balance_wrapper_max_width;
  height: $headerBtnIconMobileHeight;
  margin: 0 0 0 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  button {
    height: $headerBtnIconMobileHeight;
    width: inherit;
    max-width: inherit;
    margin: 0 !important;
    margin-right: unset !important;
    padding: 0 5px;
  }
}
