.modal-window {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: var(--background-surface-2);
  margin: 0 auto;
  border: 1px solid var(--border-primary);
  border-radius: 5px;
  transition: all 0.3s;
}
