@import '../../../styles/sizes.scss';
@import '../../../styles/mixins.scss';

.cookieSettingManager_container {
  grid-area: cookies_manager;
  min-width: 158px;
}

.cookies_manager_container {
  width: 860px;
  background-color: var(--background-surface-3);
  color: var(--text-secondary);
  border-radius: 8px;
  height: auto;
  overflow: hidden;
  padding: 0 0 8px;

  .header {
    display: flex;
    justify-content: center;
    position: relative;
    height: auto;
    margin: 0 0 2%;
    background: var(--background-surface-1);
    width: 100%;
    padding: 2%;

    .title {
      text-align: center;
      font-size: 14px;
      font-family: Inter-Bold;
      color: var(--text-secondary);
      text-transform: capitalize;
    }

    .close_icon {
      position: absolute;
      right: 11px;
      cursor: pointer;

      svg path {
        fill: var(--text-secondary);
      }
    }
  }

  .content {
    padding: 10px 40px;

    .cookies-settings-item {
      box-shadow: 0 2px 3px 1px var(-background-surface-2);
      padding: 15px;
      margin-bottom: 16px;
    }
  }

  .footer {
    display: flex;
    justify-content: center;

    button {
      // background: var(--button-brand);
      // color: var(--button-brand-text);
      border-radius: 8px;
      // border: 1px solid var(--primary-btn-outlined-border);
      box-shadow: none !important;
      cursor: pointer;
      font-size: 14px;
      outline: none !important;
      position: relative;
      height: 40px;
      margin-bottom: 8px;
      width: 140px;
      font-family: Inter-Bold;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
