@import '../../../styles/mixins.scss';

.skin-builder {
  position: absolute;
  background: #b9b6d0;
  height: 100vh;
  z-index: 1000;
  opacity: 0.9;
  .color-picker__name {
    text-transform: capitalize;
    padding-left: 5px;
  }
  .skin-builder-section {
    padding: 5px;
  }
  .skin-builder__toggle {
    text-transform: capitalize;
    padding: 5px;
  }
  .skin-builder__remove {
    padding: 5px;
  }
  .skin-builder-panel {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 25px;
    align-items: center;
  }
  &.opened {
    width: 320px;
    transition: all 2s ease;
    .skin-builder__remove {
      position: fixed;
      left: 247px;
      z-index: 1000;
      position: fixed;
      z-index: 1000;
      min-width: 65px;
      transition: all 2s ease;
      opacity: 1;
      display: block;
    }
  }
  &.closed {
    height: auto;
    width: auto;
    .skin-builder-content {
      // width: 0;
      opacity: 0;
      display: none;
    }
  }
  .skin-builder-content {
    overflow-y: auto;
    @include scrollbar();
    position: fixed;
    top: 25px;
    left: 0;
    height: calc(100% - 25px);
    width: 320px;
    background: #b9b6d0;
    z-index: 100;
    transition: all 0.5s ease;
  }
  input[type='color'] {
    margin-right: 3px;
    -webkit-appearance: none;
    border: none;
    width: auto;
    height: auto;
    cursor: pointer;
    background: none;
    &::-webkit-color-swatch-wrapper {
      padding: 0;
      width: 30px;
      height: 14px;
    }
    &::-webkit-color-swatch {
      border: 1px solid #bfc9d9;
      border-radius: 4px;
      padding: 0;
    }
  }

  input[type='text'] {
    border: none;
    max-width: 90px;
    font-size: 14px;
  }
  .skin-builder__toggle {
  }
  .btn-builder-container {
    display: flex;
    justify-content: center;
    .builder-btn {
      margin: 0 2%;
      width: 40%;
    }
  }
}
.color-picker-container {
  display: flex;
  font-size: 14px;
  margin: 2px 0;
  input[type='text'] {
    border: 1px solid grey;
  }
}
