@import '../../../styles/sizes.scss';
@import '../../../styles/constants';

.gameTags_container {
  margin-bottom: 0;
  position: relative;
  overflow-x: unset;
  border-radius: 8px;

  .game_tags_wrapper {
    display: flex;
    height: 60px;
    justify-content: flex-start;
    scroll-behavior: smooth;
    text-align: center;
    overflow-x: auto;
    width: 100%;
    align-items: center;
    border: 1px solid var(--border-primary);
    padding: 0;
    border-radius: 8px;

    .gameTag {
      -webkit-transition: color 0.12s;
      transition: color 0.12s;
      text-transform: uppercase;
      margin: 0 0 0 8px;
      padding: 10px;
      cursor: pointer;
      height: $gameTag_desktop_height;
      white-space: nowrap;
      display: flex;
      align-items: center;
      font-style: normal;
      font-family: Inter-SemiBold;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .control_icon_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 2px;
      top: 29%;
      width: 25px;
      height: 25px;
      border-radius: 4px;
      background: var(--background-surface-2);
      border: 1px solid var(--border-primary);
      margin: 0 10px;
      cursor: pointer;
      // z-index: $game-tags__control-icon-wrapper--index;
      z-index: $icon-index;

      > i {
        color: var(--text-secondary);
        font-size: 10px;
      }

      &.prev {
        right: unset;
        left: 2px;
      }
    }
  }
}

.games_wrapper {
  display: flex;
  flex-direction: column;
  min-height: 300px;

  .games_container {
    display: grid;

    &.template_noFullScreen {
      gap: 3px;
    }

    .game {
      margin: 10px 5px;
    }

    &.has-details .game {
      margin: 2px;
    }
  }

  .show_more_btn {
    background: var(--background-surface-2);
    border: 1px solid var(--border-primary);
    border-radius: 8px;
    color: var(--text-secondary);
    text-transform: uppercase;
    padding: 12px 20px;
    font-family: Inter-Bold;
    line-height: 19px;
    min-width: 96px;
    min-height: 19px;
    height: auto;
    width: fit-content;
    margin: 1rem auto 0;

    &:hover,
    &:active {
      background: var(--background-surface-3);
    }
  }
}

.games_no_data_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .text {
    color: var(--text-secondary);
    margin-top: 20px;
  }
}

.desktop,
.tablet {
  .games_no_data_wrapper {
    height: 300px;
  }

  .gametags_container {
    .title {
      margin: 0 0 15px 0;
    }
  }

  .games_wrapper {
    padding-bottom: 0;

    .show_more_btn {
      font-size: 16px;
    }
  }
}

.tablet {
  .gametags_container {
    padding: 0 20px;
  }
}

.mobile {
  .games_no_data_wrapper {
    height: 200px;
  }

  .games_wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 0 0 20px 10px;

    .games_container {
      align-items: center;
    }

    .show_more_btn {
      font-size: 12px;
    }
  }
}

.mobile .gameTags_container {
  height: $gameTags_container_mobile_height;

  .title {
    font-family: Inter-SemiBold;
    font-size: 16px;
    color: var(--text-secondary);
    padding: 29px 0 8px;
    margin: 0;
  }

  .game_tags_wrapper {
    height: 49px;
    width: 100%;
    border: none;
    position: relative;
    left: 0;
    padding: 0;

    .gameTag {
      padding: 3px 10px;
      font-size: 12px;
      font-family: Inter-SemiBold;
      height: $gameTag_mobile_height;
      margin: 0px 5px;
    }

    .control_icon_wrapper {
      display: none;
    }
  }
}
