@import '../../../styles/mixins.scss';

.tooltip_wrapper {
  position: relative;
  max-width: 100%;

  .tooltip__overlay {
    position: absolute;
    display: block;
    bottom: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
    background: var(--background-surface-3);
    padding: 6px 8px;
    color: #efefef;
    font-size: 11px;
    font-weight: 500;
    width: inherit;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: calc(100% - 4px);
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      background-color: var(--background-surface-3);
      width: 8px;
      height: 8px;
      z-index: 2;
    }

    &--bottom {
      top: calc(100% + 8px);
      bottom: unset;

      &::before {
        bottom: calc(100% - 4px);
        top: unset;
      }
    }
  }
  
  .tooltip__inner {
    width: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
}

.mobile {
  .burgerMenu {
    @include center();
    .tooltip__overlay {
      display: grid;
      row-gap: 20px;
      grid-template-columns: repeat(3, 1fr);
      border-radius: 8px;
      bottom: calc(100% + 16px);
      padding: 20px 6px;
      left: -30px;
      &::before {
        left: 72%;
      }
    }
  }
}
