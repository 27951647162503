@import '../../../../../../styles/sizes.scss';
@import '../../../../../../styles/mixins.scss';
@import '../../../../../../styles/constants';

.back_icon {
  @include center();

  position: absolute;
  top: 12px;
  margin-left: -30px;
  color: var(--text-secondary);
  width: 100px;
  // z-index: $sign-up-content__back_icon--index;
  z-index: $icon-index;
  cursor: pointer;

  span {
    font-size: 12px;
  }

  svg {
    transform: rotate(90deg);
  }
}

.reg_close {
  background: var(--background-surface-3);
  border-radius: 50%;
  position: absolute;
  top: 12px;
  right: 15px;
  cursor: pointer;
}

.withImage {
  .form_wrapper {
    @include scrollbar();

    @supports (-webkit-touch-callout: none) {
      margin-left: 10px;
    }

    padding-right: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .signUp-section {
      .signup_form {
        overflow-y: auto;
        padding-right: 10px;

        @include scrollbar();
      }
    }

    .loginModal__content__signUpButton {
      font-size: 12px;
      font-family: Inter-SemiBold;
      width: auto;
      height: auto;
      padding: 0 0 4px;
      margin: 10px auto 0;
      text-align: center;
      color: var(--text-brand);

      span {
        color: var(--text-secondary);
        margin-right: 10px;
      }
    }
  }
}

.withoutImage_body {
  .form_wrapper {
    @include scrollbar();

    @supports (-webkit-touch-callout: none) {
      margin-left: 10px;
    }

    padding-right: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .signUp-section {
      margin-bottom: 20px;

      .signup_form {
        overflow-y: auto;
        padding-right: 10px;
        max-height: calc(100vh - 180px);

        @include scrollbar();
      }
    }

    .loginModal__content__signUpButton {
      font-size: 12px;
      font-family: Inter-SemiBold;
      width: auto;
      height: auto;
      padding: 0 0 4px;
      margin: 10px auto 0;
      text-align: center;
      color: var(--text-brand);

      span {
        color: var(--text-secondary);
        margin-right: 10px;
      }
    }
  }
}

.buttons_container {
  .terms_and_conditions_checkbox {
    background-color: transparent;
    height: 20px;
    width: 20px;
    border: 1px solid var(--border-primary);
    border-radius: 5px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 4px;
    margin-right: 8px;

    &__link {
      font-family: Inter-SemiBold;
      text-decoration: underline;
      cursor: pointer;
      margin-left: 3px;
    }
  }

  .primaryButton {
    width: 100%;
    height: $headerBtnDesktopHeight;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    text-transform: uppercase;

    &:disabled {
      background: var(--background-surface-3);
      color: var(--text-secondary);
    }
  }
}

.otp_popup {
  &_countDown {
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;
    button {
      width: 120px;
      font-size: 12px;
    }
    .ant-statistic-content-value {
      color: var(--text-primary);
    }
  }
  button {
    &:disabled {
      background-color: var(--button-disable);
    }
  }
}

@media (max-width: 480px) {
  .back_icon {
    display: flex;
    position: absolute;
    left: 20px;
  }

  .withoutImage_body {
    .signup_form {
      max-height: calc(100svh - 210px);
    }

    .reg_close {
      background: var(--background-surface-3);
      border-radius: 50%;
      position: absolute;
      top: 17px;
      right: 15px;
      cursor: pointer;
    }
  }

  .content_with_image_wrapper {
    .reg_close {
      background: var(--background-surface-3);
      border-radius: 50%;
      position: absolute;
      top: 12px;
      right: 15px;
      cursor: pointer;
    }
  }

  .form_wrapper {
    margin-top: -5px;
    height: 100%;

    &:nth-child(1) {
      height: 47vh;
      height: 47svh;
    }
  }

  .buttons_container {
    .primaryButton {
      margin: 10px 0;
    }
  }
}
