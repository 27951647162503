@import '../../../styles/sizes.scss';

.promotion_wrapper {
  text-transform: uppercase;
  cursor: pointer;
  height: $headerBtnDesktopHeight;
  display: flex;
  align-items: center;
  margin: 0 5px;

  button {
    height: $headerBtnDesktopHeight;
    rect {
      fill: var(--icon-color-secondary);
    }
  }

  p {
    font-family: Inter-Bold;
    font-size: 16px;
    margin: 0 10px;
    text-transform: uppercase;
  }
}

.tablet .promotion_wrapper{
  button div{
    margin: 0;
  }
}
