.secondary-menu {
  background-color: var(--background-surface-1);
  color: var(--text-secondary);
  border: 1px solid var(--border-primary);
  margin-top: 8px;

  ul {
    background-color: var(--background-surface-1);
    color: var(--text-secondary);

    li {
      min-height: 43px;
      background-color: var(--background-surface-2);
      border: 1px solid var(--border-primary);

      button {
        color: inherit;
      }

      &:hover {
        border-radius: 8px;
        background-color: var(--background-surface-3);
      }
    }
  }
}

.mobile {
  .secondary-menu {
    ul {
      li {
        &:hover {
          background-color: var(--background-surface-2);
        }
      }
    }
  }
}
