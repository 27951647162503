.toggle_input_container {
  display: flex;
  align-items: center;
  margin: 20px 0;

  .toggle_input {
    display: flex;

    input {
      display: none;

      &:checked ~ label {
        background: #6fbeb5;

        &:after {
          left: 34px;
          background: #179588;
        }
      }

      &:disabled ~ label {
        background: #89898991;
        pointer-events: none;

        &:after {
          background: #bcbdbc;
        }
      }
    }

    label {
      position: relative;
      display: block;
      height: 24px;
      width: 57px;
      background: #898989;
      border-radius: 100px;
      cursor: pointer;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      margin: 0;

      &::after {
        display: block;
        position: absolute;
        left: 2px;
        top: 2px;
        width: 20px;
        height: 20px;
        border-radius: 100px;
        background: #fff;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
        content: '';
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
    }
  }

  p {
    margin: 0 0 0 15px;
    font-size: 12px;
  }
}
