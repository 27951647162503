@import '../../../../styles/sizes.scss';

.tabs_section__item_2 {
  background: var(--background-surface-3);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
  border: 1px solid var(--border-primary);
  padding: 10px;

  .tab_image {
    width: 103px;
    height: 60px;
    object-fit: cover;
    top: 4px;
    border-radius: 8px;

    &[alt] {
      font-size: 10px;
    }
  }

  &.selected {
    background-color: var(--background-surface-3);
    border: 0.5px solid var(--border-primary-selected);
  }
}

@media (min-width: 481px) {
  .tabs_section__item_2 {
    height: 80px;
    width: $account_modal_payment_tab_width;

    .tab_inner_content .tab_image {
      object-fit: contain;
      width: auto;
    }
  }
}

@media (max-width: 480px) {
  .tabs_section__item_2 {
    height: 80px;
    width: 40vw;
    margin: 7px 5px;
    padding: 5px;

    .tab_inner_content {
      height: 80px;
    }

    .tab_image {
      width: unset;
      height: 53px;
      object-fit: cover;
      top: 9px;
      position: relative;
    }

    &.selected {
      border: 1px solid #ffbf17;

      .triangle {
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-top: 0;
        border-bottom: 7px solid #ffbf17;
        transform: rotate(180deg);
        position: relative;
        top: 12px;
      }
    }
  }
}
