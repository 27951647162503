@import '../../../styles/sizes.scss';

.promotions {
  &__container {
    width: 100%;
    height: 100%;
  }

  &__title {
    font-family: Inter-Bold;
    font-size: 28px;
    line-height: 34px;
    color: var(--text-primary);
  }

  &__tags {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-left: -4px;
  }
  &__tag {
    background: var(--background-surface-2);
    border-radius: 8px;
    height: 40px;
    padding: 0 24px;
    font-family: Inter-SemiBold;
    font-size: 16px;
    text-transform: uppercase;
    color: var(--text-secondary);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & + .promotions__tag {
      margin-left: 12px;
    }
    
    &--active {
      border: 1px solid var(--tag-primary-hover-border);
    }
  }
  &__bonus-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

// only desktop
.desktop {
  .promotions {
    &__container {
      margin: 0 auto;
      position: relative;
      position: relative;
      right: -4px;

      .promotions__inner {
        padding: 0 0 20px;
      }
    }
    &__tags {
      margin: 16px 0px 6px 0px;
    }
    &__bonus-list {
      gap: 20px 10px;
      padding-top: 16px;
    }
    &__bonus-item {
      width: 390px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
    &__bonus-item {
      margin: 0px 0px 10px 0px;
    }
  }
}

// Mobile
.mobile {
  .promotions__container {
    max-width: unset;
    margin-top: calc($mobileHeaderHeight + 10px);
    .promotions__bonus-item {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .promotions__inner {
    padding: 12px;
  }
  .promotions__title {
    font-size: 20px;
    line-height: 24px;
  }
  .promotions__tags {
    margin-left: 0;
    margin-top: 12px;
    display: block;
    overflow: auto;
    white-space: nowrap;
  }
  .promotions__tag {
    display: inline-block;
    line-height: 40px;
    & + .promotions__tag {
      margin-left: 8px;
    }
  }
  .promotions__bonus-list {
    margin: 20px 0px 0px;
    display: flex;
    flex-direction: column;
  }

  .promotions__bonus-item {
    margin: 5px auto;
  }

  @media (orientation: portrait) {
    .promotions__tags {
      padding-bottom: 12px;
    }
  }
}

.tablet {
  .promotions__container {
    padding: 20px 24px;

    .promotions__bonus-item {
      width: 28vw;
      height: auto;
    }

    .promotions__bonus-item-image-wrapper img {
      min-height: unset;
    }

    .promotions__item-description p span {
      max-width: 26vw;
    }

    .promotions__item-title-description-button-wrapper {
      justify-content: space-between;
      height: 100%;
    }
  }
}
