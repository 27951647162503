.dark-light-theme-1 {
  .success-filled-btn {
    border-radius: 30px;
  }
  .navigation-container {
    .nav_item {
      color: white;

      svg {
        path {
          fill: white;
        }
      }

      &.selected {
        color: var(--text-brand);
      }
    }
  }

  .gameTags_container {
    .game_tags_wrapper {
      .gameTag {
        border-radius: 30px;
        padding: 20px;

        &:first-child {
          margin: 8px 4px 8px 0;
        }

        &.selected {
          background-color: var(--button-brand);
          border: 1px solid var(--button-brand);
        }
      }
    }
  }
  .mobile .gameTags_container {
    .game_tags_wrapper {
      .gameTag {
        &:first-child {
          margin: 8px 4px 8px 8px;
        }
      }
    }
  }

  .banner-img {
    border-radius: 16px;
  }

  .squaredDark,
  .banner_small_arrows .arrow {
    background: var(--button-brand);
    border-radius: 5px;
    color: var(--text-primary);
  }
  .games_wrapper .show_more_btn {
    background-color: var(--button-secondary);
    color: var(--button-secondary-text);
    &:hover {
      background-color: var(--button-secondary-hover);
    }
  }

  .providers_accordion_wrapper .providers_accordion_container {
    padding: 0;
    .selected {
      color: var(--button-primary-text);
      background-color: var(--button-primary);
    }
    .provider_search_container {
      border-radius: 30px;

      .icon_wrapper {
        background-color: var(--button-brand);
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;

        .icon svg path {
          fill: black;
        }
      }
    }
  }

  .tab-image-btn {
    svg path {
      fill: var(--icon-color-secondary) !important;
    }

    &.active {
      background: var(--button-brand-hover);
      border: 1px solid var(--border-primary-hover);

      svg path {
        fill: white !important;
      }
    }
  }

  &.desktop .content_without_image_wrapper .title,
  &.tablet .content_without_image_wrapper .title {
    color: var(--text-brand) !important;
  }

  .backToTopBtn {
    background-color: transparent;
    border: 1px solid var(--button-secondary);
    border-radius: 8px;
    color: #0f0f10;

    svg {
      path {
        fill: #0f0f10;
      }
    }

    &:hover {
      background: var(--button-primary);
      border-color: var(--button-primary-border-hover);
      color: #fff;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
  .modal_search_container {
    border-radius: 25px;
  }
  .search_game_input input {
    height: 43px;
    border-top: unset !important;
    border-bottom: unset !important;
    width: 100%;
    background-color: #fff !important;
    border: 1px solid var(--search-border) !important;
    border-radius: 0 30px 30px 0 !important;
  }
  .modal_search_container .icon_wrapper {
    background-color: var(--search-border);
  }
  .gameTags_container .game_tags_wrapper .gameTag {
    border-radius: 30px !important;
  }

  .desktop_header .topLine .firstSection {
    width: 200px;
  }

  .promotions__item-title * {
    color: var(--text-brand);
  }

  .promotions-info-page__description-wrapper {
    background: #eee;
  }

  .promotions-info-page__back-button-wrapper,
  .promotions-info-page__description-title {
    color: var(--text-brand);

    svg {
      path {
        fill: var(--icon-color-secondary);
      }
    }
  }

  .promotions__tag {
    background: var(--button-secondary);
    border-radius: 30px;
    color: #fff;

    &--active {
      background-color: var(--button-secondary);
      border: 1px solid var(--button-secondary-selected);
    }
  }

  .secondary-menu {
    color: var(--text-primary);
    border: 1px solid var(--border-primary);

    .user_settings_list li {
      color: var(--text-primary);
      border: unset;

      button {
        border: 1px solid var(--background-surface-3);
        border-radius: 4px !important;

        svg {
          path {
            fill: var(--icon-color-secondary) !important;
            stroke: var(--icon-color-secondary) !important;
          }
        }

        .arrow {
          svg {
            path {
              fill: rgb(117, 123, 140);
              stroke: rgb(117, 123, 140);
            }
          }
        }
      }

      &:last-child {
        color: #cd2026;

        svg {
          path {
            fill: #cd2026 !important;
            stroke: #cd2026 !important;
          }
        }
      }
    }
  }
  .transaction_history_wrapper {
    .filters_wrapper {
      .first_section {
        select {
          background-color: var(--navigation-background);
          color: var(--text-tag);
          option {
            background-color: var(--navigation-background);
          }
        }
      }
    }
  }
  .desktop .secondary-menu .user_settings_list li button,
  .tablet .secondary-menu .user_settings_list li button {
    border-radius: 30px !important;
  }

  .popup_body {
    .account_sidebar {
      background-color: var(--account-background-surface-1);
      border: 1px solid var(--border-primary);

      .account_section,
      .account_user_section {
        border-bottom: 1px solid var(--border-primary);
        .user-avatar {
          background-color: var(--account-background-surface-3) !important;
        }
        .user_balance {
          background-color: var(--account-background-surface-3);
          svg {
            path,
            g,
            defs {
              fill: var(--icon-color-secondary) !important;
            }
          }
        }
      }
      .active_section {
        background-color: var(--account-background-surface-3) !important;
      }
    }

    .account_subsections {
      border: 1px solid var(--border-primary);
    }
  }
  .game_tags_wrapper,
  .providers_accordion_container {
    border: unset;
  }

  .mobile_footer_container {
    background-color: #fff;
    border-top: 2px solid #1f2121;
    margin-top: 10px;
  }

  .search_modal_container {
    background-color: transparent;
    border: 1px solid var(--border-primary);
  }

  .desktop .user_settings_wrapper,
  .tablet .user_settings_wrapper {
    margin: 0 15px;
  }

  &.mobile {
    .user_settings__separator_line {
      border-bottom: 1px solid var(--border-primary) !important;
    }

    .user_settings_wrapper .user_settings .user_settings_list .logout_btn button {
      height: 43px;
      margin: 0;
    }

    .auth_container {
      .button_row button {
        padding: 0 16px !important;
      }
    }

    .gameTag {
      padding: 0 16px !important;
    }
    .mobile .user_settings_wrapper {
      .user_settings_info .user {
        background-color: var(--account-background-surface-3);
      }
      .user_settings__balance_info {
        background-color: var(--account-background-surface-3);
        .icons_wrapper {
          .eye {
            svg {
              path,
              g,
              defs {
                fill: var(--icon-color-secondary);
              }
            }
          }
        }
      }
      .user_settings_list li ul {
        border: 1px solid var(--border-primary);
        border-top: unset;
      }
    }

    .mobile_provider_item,
    .mobile_search_input input {
      background-color: transparent !important;
      border: 1px solid var(--border-primary) !important;
    }

    .mobile_search_icon {
      background-color: var(--search-background-surface-1) !important;
      height: 40px !important;
    }

    .mobile_fixed_menu_container {
      background-color: var(--navigation-background);
      border: unset;
      div button {
        color: #fff !important;
      }
    }

    .mobile_menu_accordion {
      background-color: transparent !important;
      border: 1px solid var(--background-surface-3) !important;

      .mobile_menu_category_icon {
        svg {
          path {
            fill: var(--icon-color-secondary) !important;
          }
        }
      }

      .mobile_menu_promo_icon {
        color: #ffbf17 !important;
      }
    }

    .providers_filter_mobile_container,
    .search_mobile_container {
      button {
        color: #0f0f10 !important;

        svg {
          path {
            fill: #0f0f10 !important;
          }
        }
      }
    }
  }

  .general_loader_page_container {
    background-color: white !important;

    .cs_loader_inner {
      color: var(--text-brand) !important;
    }
  }
  .mobile_provider {
    >div {
      padding-left: 8px;
      padding-right: 8px;
      background-color: var(--tag-primary);
      span {
        color: var(--text-tag) !important;
      }
    }
  }
  .balance_wrapper {
    button {
      color: var(--button-secondary-text);
    }
  }
}
