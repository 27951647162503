@import '../../../../../styles/mixins.scss';

.final-tour {
  width: 904px;
  height: 452px;
  padding: 20px 26px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &-container_img {
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__button-wrapper {
    margin-top: 345px;
    margin-bottom: 20px;
    width: 191px;
    height: 51px;

    & + .final-tour__dont-show-again-wrapper {
      margin-top: 0;
    }
  }

  &__dont-show-again-wrapper {
    position: absolute;
    bottom: 10px;
    label {
      margin-left: 10px;
      color: var(--text-secondary);
    }
  }
}

.mobile .final-tour {
  width: initial;
  padding: 20px;

  .final-tour__button-wrapper {
    width: 100%;
    height: 41px;
  }
}
