@import '../../../styles/sizes.scss';

.wins_container {
  border-radius: 8px;
  margin-bottom: 30px;

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    width: 100%;

    .squaredDark {
      position: relative;
      & > div {
        position: absolute;
        top: 9px;
        left: 12px;
      }
    }

    .wins_types_container {
      display: flex;
      align-items: center;

      .win_tab {
        padding: 8px 16px;
        background-color: transparent;
        border-radius: 6px;
        text-transform: uppercase;
        color: var(--text-secondary);
        font-style: normal;
        font-family: Inter-Bold;
        line-height: 15px;
        min-width: 122px;

        &.active {
          color: var(--text-secondary);
          background-color: var(--main-12);
          border: 1px solid var(--border-primary);
        }
      }

      .arrows {
        display: flex;
      }
    }

    .arrows {
      display: flex;
    }
  }

  .game_carousel_conatiner {
    height: 100%;
    width: 100%;

    .inner_carousel {
      display: flex !important;
      justify-content: space-between;
      width: inherit !important;

      .win_card {
        background: var(--background-surface-2);
        border: 1px solid var(--border-primary);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        width: 100%;

        .game_thumbnail {
          img {
            border: 1px solid var(--border-primary);
            border-radius: 4px;
          }
        }

        .info {
          display: flex;
          flex-direction: column;

          .winner {
            line-height: 19px;
            color: var(--text-secondary);
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .win_amount {
            color: var(--text-brand);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
              color: var(--text-primary);
            }
          }

          .game_name {
            color: var(--text-secondary);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
              color: var(--text-primary);
            }
          }
        }
      }
    }
  }
}

.desktop,
.tablet {
  .wins_container {
    min-height: 100px;
    margin: 40px 0 0;
  }

  .win_tab {
    font-size: 12px;
  }

  .game_carousel_conatiner {
    height: 100%;
    width: 100%;

    .inner_carousel {
      .win_card {
        height: 77px;
        width: 260px;

        .game_thumbnail {
          margin: 8px 0 8px 8px;

          img {
            width: 61px;
            height: 61px;
          }
        }

        .info {
          width: 68%;

          .winner {
            max-width: 180px;
            font-size: 16px;
            font-family: Inter-SemiBold;
          }

          .win_amount {
            max-width: 170px;
          }

          .game_name {
            max-width: 170px;
          }
        }
      }
    }
  }
}

.mobile {
  .wins_container {
    width: 100%;
    height: 120px;
    margin: 10px 0;
  }

  .win_tab {
    font-size: 10.5px;
  }

  .game_carousel_conatiner {
    height: 100%;
    width: 100%;

    .inner_carousel {
      .win_card {
        height: 77px;
        margin: 0 5px;

        .game_thumbnail {
          margin: 8px;

          img {
            width: 49px;
            height: 49px;
          }
        }

        .info {
          width: 68%;
          justify-content: flex-start;

          .winner {
            max-width: 110px;
            font-size: 12px;
            font-family: Inter-Bold;
            line-height: 15px;
            margin-bottom: 4px;
          }

          .win_amount {
            max-width: 110px;
            font-family: Inter;
            font-size: 10px;
            line-height: 12px;
            margin-bottom: 2px;
          }

          .game_name {
            max-width: 110px;
            font-family: Inter;
            font-size: 10px;
            line-height: 12px;
          }
        }
      }
    }
  }
}
