.imageWidget_container {
  grid-area: image_widget;
  margin: 20px auto;
  width: 100%;
  justify-content: center;
  display: flex;
}

.mobile {
  .imageWidget_container {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
}
