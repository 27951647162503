@import '../../../../styles/sizes.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/constants';

.account_modal {
  color: var(--text-secondary);
  width: $account_modal_width;
  height: $account_modal_height;
  max-height: $account_modal_max_height;
  border-radius: 8px;
  background-color: var(--account-background-surface-1);
  overflow: hidden;

  .modal_container {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;

    .modal_sidebar {
      width: $account_modal_sidebar_desktop_width;
      background-color: var(--account-background-surface-2);
      border-right: 1px solid var(--border-primary);
      padding: 0;
      display: flex;
      flex-direction: column;
      border-radius: 8px;

      .active_section {
        background-color: var(--account-background-surface-1);
      }

      .section {
        height: $account_modal_sidebar_item_height;
        padding: 17px 24px;
        display: flex;
        border-bottom: 1px solid var(--border-primary);
        cursor: pointer;
        font-style: normal;
        font-family: Inter-SemiBold;
        font-size: 16px;
        line-height: 19px;
        color: var(--text-secondary);

        &:hover {
          background-color: var(--account-background-surface-3);
        }

        svg {
          margin-left: 5px;
        }
      }

      .user_section {
        border-bottom: 1px solid var(--border-primary);
        height: fit-content;
        padding: 17px 24px;

        .user_settings_info {
          display: flex;
          justify-content: start;

          .user {
            width: 48px;
            height: 48px;
            background: var(--account-background-surface-1);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .user_info {
            align-items: baseline;
            margin-left: 20px;
            color: var(--text-secondary);
            position: absolute;
            margin-left: 65px;

            .username {
              font-style: normal;
              font-family: Inter-SemiBold;
              font-size: 16px;
              line-height: 19px;
              color: var(--text-secondary);
            }

            .userId {
              display: flex;

              span {
                font-style: normal;
                font-family: Inter-SemiBold;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                color: var(--text-secondary);
                margin-right: 8px;
              }

              .copy {
                cursor: pointer;
              }

              .copied {
                margin-left: 5px;
                margin-top: 0;
                position: relative;
              }
            }
          }

          .user_avatar {
            align-items: center;
            justify-content: center;
            padding: 0;
            width: 25px;
            height: 30px;

            svg path {
              fill: var(--text-brand) !important;
            }
          }
        }

        .user_settings__balance_info {
          background: var(--account-background-surface-1);
          border-radius: 8px;
          margin-top: 10px;
          padding: 12px 16px;
          display: flex;
          justify-content: space-between;

          .balance {
            display: flex;
            flex-direction: column;

            .title {
              font-style: normal;
              font-family: Inter-Medium;
              font-size: 14px;
              line-height: 17px;
              color: var(--text-secondary);
              text-transform: capitalize;
            }

            .value {
              font-style: normal;
              font-family: Inter-SemiBold;
              font-size: 16px;
              line-height: 19px;
              color: var(--text-secondary);
              margin-top: 8px;
            }
          }

          .eye {
            cursor: pointer;
            height: fit-content;

            svg{
              path, g, defs{
                fill: var(--icon-color-primary);
              }
            }
          }
        }

        .user_settings__withdrawableBalance_info {
          background: var(--account-background-surface-1);
          border-radius: 8px;
          margin-top: 10px;
          padding: 12px 16px;
          display: flex;
          justify-content: space-between;

          .title {
            font-style: normal;
            font-family: Inter-Medium;
            font-size: 14px;
            line-height: 17px;
            color: var(--text-secondary);
            text-transform: capitalize;
          }

          .value {
            font-style: normal;
            font-family: Inter-SemiBold;
            font-size: 16px;
            line-height: 19px;
            color: var(--text-secondary);
            margin-top: 8px;
          }
        }
      }
    }

    .modal_content {
      position: relative;
      padding: 16px 24px;
      height: 100%;
      display: inline-block;

      .content {
        @include scrollbar();

        max-height: calc($account_modal_max_height - 110px);
        height: 85%;
      }
    }
  }

  .header {
    display: flex;
    border-bottom: 1px solid var(--border-primary);
    align-items: center;
    margin-bottom: 10px;
    padding: 0 0 16px;

    .subsections {
      @include scrollbar();

      height: $account_modal_sub_section_height;
      border: 1px solid var(--border-primary);
      color: var(--main-color-1);
      padding: 0 5px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      user-select: none;
      overflow-x: auto;
      overflow-y: hidden;
      height: 60px;
      width: 95%;

      .fullWidth {
        width: calc(76.25rem - 315px);
        max-width: calc(80vw - 315px);
      }

      .active_subSection_as_section {
        font-family: Inter-SemiBold;
        font-size: 20px;
        line-height: 24px;

        &:hover {
          background-color: unset !important;
          pointer-events: none !important;
          opacity: 1;
        }
      }

      span {
        font-family: Inter-SemiBold;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: $account_modal_active_subSection;
        border-radius: 8px;
        margin: 0 3px;
        padding: 0 10px;
        white-space: nowrap;

        &:hover {
          opacity: 0.9;
          background-color: var(--account-background-surface-3);
        }
      }

      .active_subSection {
        border-radius: 8px;
        font-family: Inter-SemiBold;
        height: $account_modal_active_subSection;
        display: flex;
        align-items: center;
        background-color: var(--account-background-surface-3);
        color: var(--text-secondary);
      }
    }
  }
}

.account_modal_mobile {
  z-index: $modal-index;

  &_content {
    overflow: auto;
    max-height: 100svh;
    height: 100%;
    height: calc(100svh - 59px);
  }
}

.mobile_modal_container {
  text-align: center;
  background: var(--background-surface-1);
  width: 100%;
  justify-content: flex-start;
  padding-top: 0 10px;
  // z-index: $account-modal__mobile--index;
  z-index: $modal-index;
  height: 100vh;

  &.account_modal {
    // z-index: $account-modal__mobile--index;
    z-index: $modal-index;
  }

  &.full_height {
    top: 0;
    height: 100%;
  }

  .mobile_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    height: 59px;
    font-family: Inter-SemiBold;
    font-size: 16px;
    color: var(--text-secondary);

    .close_menu_wrapper {
      background: var(--background-surface-3);
      border-radius: 50%;
      height: $close_menu_wrapper_size;
      width: $close_menu_wrapper_size;
      display: flex;
      align-items: center;
      justify-content: center;

      .close_menu {
        display: flex;
        background-size: contain;
        width: $close_menu_size;
        height: $close_menu_size;
        color: var(--text-secondary);
        justify-content: center;
      }
    }

    button {
      background-color: var(--background-surface-3);
      border-radius: 50%;
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrowDown {
        transform: rotate(90deg);
        margin-right: 10px;
      }
    }
  }
}

.info_header {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  background-color: var(--background-surface-2);
  left: 0;
  right: 0;

  img {
    height: 24px;
  }
}

.info_mobile_header {
  position: relative;
  // z-index: $account-modal__info-mobile-header--index;
  z-index: $modal-header-index;

  &.bottom_nav {
    padding-top: 55px;
  }

  .arrowDown {
    position: fixed;
    top: 0;
    left: 0;
    transform: rotate(90deg);
    margin: 15px 0 0 10px;
    // z-index: $account-modal__info-mobile-header-and-arrow-down--index;
    z-index: $icon-index;
  }
}

.info_mobile_conatiner {
  position: fixed; // absolute is changed
  top: 0;
  z-index: $account-modal__info-mobile-conatiner--index;
  width: 100vw;

  .account_modal_mobile_content {
    height: 100vh;
    height: 100svh;
    padding-bottom: 50px;

    .info_container {
      height: 100%;
      max-height: unset;
    }
  }

  .mobile_modal_container {
    top: 0;
  }
}


@media (min-width: 481px) and (max-width: 1023px) {
  .account_modal {
    width: 720px;
    height: 744px;

    .modal_container {
      .modal_sidebar {
        width: 370px;

        .section {
          height: fit-content;
        }
      }

      .content {
        overflow-x: auto;
      }

      .header_user {
        width: 450px;
      }
      .modal_content {
        padding: 16px 14px;
      }
    }
    .user_settings__withdrawableBalance_info {
      flex-direction: column;
      width: 185px;
      h5 {
        padding-bottom: 4px;
      }
    }
  }

  .payment_inner_content .payment .input_container {
    width: 100%;
  }
}
