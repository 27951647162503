@import '../../../../styles/sizes.scss';
@import '../../../../styles/constants';

.live_chat_btn {
  font-family: Inter-SemiBold;
  border-radius: 6px;
  text-transform: capitalize;
  font-size: 13px;
  height: $headerBtnDesktopHeight;
  width: fit-content;
}

.mobile {
  .live_chat_btn {
    display: none;
  }
}

.desktop {
  #chat-widget-container {
    z-index: $chat-index!important;
  }
}
