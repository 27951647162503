@import '../../../styles/constants';
@import '../../../styles/mixins';
@import '../../../styles/constants';
@import '../../../styles/sizes.scss';

.dropdown {
  cursor: pointer;
  position: relative;
  text-align: center;
  text-decoration: none;
  z-index: $dropdown__dropdown--index;
  transform: perspective(1000px);

  .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: max-content;
    padding: 0 10px 0 16px;
  }

  .dropdown-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    padding: 1px 10px;
  }

  .dropdown-menu-item {
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: space-between;
    color: var(--text-primary);

    .iconSrc {
      margin-right: 7px;
    }

    img {
      filter: none !important;
      width: 26px;
      height: 17px;
    }

    svg {
      height: 25px;
      width: 40px;
    }
  }
}
.lngBar {
  .dropdown-menu-item {
    img {
      width: 19px;
      height: 18px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.dropdown-menu {
  position: absolute;
  top: 8px;
  right: 0;
  left: 5px;
  height: fit-content;
  list-style-type: none;
  margin: 0;
  padding: 0;
  opacity: 0;
  text-align: center;
  visibility: hidden;
  font-size: 0.9rem;
  font-family: Inter-Bold;
  border-radius: 8px;
  margin-top: 5px;
  padding: 5px 10px;
}

.dropdown-menu a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.open > .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
  -webkit-transition: all 0.3s, background, 0.5s 0.5s linear;
  transition: all 0.3s, background 0.5s 0.5s linear;
}

.open > .dropdown-toggle {
  svg {
    transform: rotate(-180deg) !important;
  }
}

.theme {
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0;
  margin-top: 9px;

  .dropdown-toggle {
    justify-content: space-between;
    width: 91%;
    position: relative;
    top: -22px;
    left: 39px;
  }

  .dropdown-menu {
    width: 100%;
    margin-top: -20px;
  }
}

@media (max-width: 480px) {
  .dropdown-menu {
    &.secondary-btn {
      background-color: var(--background-surface-1);
    }
    .dropdown-btn {
      margin-top: 6px;
      .iconSrc {
        height: 20px !important;
      }
    }
  }
}
