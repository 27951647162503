@import '../../../styles/mixins.scss';
@import '../../../styles/sizes.scss';
.tltJPWidget {
  display: block;
  margin: 0 auto;
  height: 100%;
  #tlt-jp-widget {
    margin: 140px auto 20px;
    height: 100%;
  }
}
.desktop {
  #tlt-jp-widget {
    .jackpot-gw-item {
      &:hover {
        z-index: 999;
      }
    }
    .jackpot-gw-content-jackpots ul {
      justify-content: end;
    }
  }
}

#tlt-jp-widget {
  .jackpot-gw-logo {
    width: 200px;
  }

  .jackpot-gw-item-name {
    color: var(--text-secondary);
    text-transform: capitalize;
  }

  .jackpot-gw-item-details,
  .jackpot-gw-content {
    background: var(--background-surface-2);
    border-radius: 6px;
    .jackpot-gw-item-display {
      background: var(--background-surface-1);
      border-radius: 6px;
      .jackpot-gw-image-wrapper {
        background: var(--background-surface-2);
      }
    }
  }
}
.mobile {
  #tlt-jp-widget {
    .jackpot-gw-logo {
      width: 120px;
    }
    .jackpot-gw[id^=tt] .jackpot-gw-item {
      min-width: 190px;
      width: 190px;
    }
  }
}