@import '../../../styles/mixins.scss';

.providerList_container {
  grid-area: provider_list;
  margin: 25px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
  border-top: 1px solid var(--bg-main-color-3);

  .provider {
    height: 55px;
    width: 11%;
    margin-top: 5px;
    @include center();
    text-transform: capitalize;


    img {
      width: 80%;
      height: 80%;
      object-fit: contain;
      border-radius: 4px;
    }
  }
}
