@import '../../../../styles/sizes.scss';

.live_chat_button {
  .sidebar_section__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 40px;
  
    &>div{
      width: unset;
    }
    
    .chat_title {
      display: none;
    }
  }

  button {
    width: 40px;
    height: 40px;
    padding: 0 8px;
  }
}

.desktop {
  .sidebar_global_container {
    &--opened {
      .live_chat_button .sidebar_section__item {
        width: 228px;

        .chat_title {
          display: block;
          margin-left: 10px;
        }

        button {
          width: 228px;
        }
      }
    }
  }
}

.mobile {
   .live_chat_button{
    display: none;
  }
}
