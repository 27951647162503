@import '../../../styles/sizes.scss';

.gambleAware_wrapper {
  grid-area: gamble_aware;
  display: flex;
  align-items: center;

  &__icon {
    margin-right: 7px;
  }

  .gambleAware {
    width: 130px;
  }
}
