ol,
ul {
  list-style: none;
}

.container {
  padding: 0;
}

img {
  max-width: 100%; /* This rule is very important, please do not ignore this! */
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
  pointer-events: none;
}

button {
  background: transparent;
  border: 0;
  border-radius: 0;

  &:active,
  &:focus {
    outline: none;
  }
}

button,
[type='button'],
[type='submit'],
[type='reset'] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;

  &:focus {
    outline: none;
  }
}

button,
input {
  overflow: visible;
}

button,
input[type='submit'] {
  display: block;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

input,
textarea,
select {
  outline: none;
}

input[type='text'] {
  -moz-appearance: textfield;
  appearance: textfield;

  &::-ms-clear {
    display: none;
  }
}

input[type='password'] {
  &::-ms-reveal {
    display: none;
  }
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']:hover,
input[type='number']:focus {
  -moz-appearance: textfield;
}

textarea {
  overflow: auto;
  resize: none;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

button {
  &:focus {
    outline: none !important;
  }
}
