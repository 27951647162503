.cerificateList_container {
  grid-area: certificate_list;
  margin: 0 auto 10px;
  display: flex;
  align-items: center;

  img {
    margin: 0 10px;
    height: 47px;
  }

  .liner {
    height: 1px;
    background: -webkit-gradient(linear, 0 0, 100% 0, from(transparent), color-stop(0.5, #979797), to(transparent));
    background: -webkit-linear-gradient(left, transparent, #979797, transparent);
    border: 1px solid linear-gradient(left, transparent, #979797, transparent);
    -webkit-flex-grow: 1;
    -moz-box-flex: 1;
    flex-grow: 1;
    border: 0;
    width: 30vw;
  }
}
