@import '../../../styles/constants';

$background-color: #35394c;
$gradient-color: lighten($background-color, 10%);

.skeleton {
  position: absolute;
  background-size: 40% auto;
  background-repeat: no-repeat;
  background-position: center;
  // z-index: $game-card-sceleton--index;
  z-index: $sceleton-index;
  border-radius: 16px;
  width: calc(100% - 20px);

  &.gif {
    background-color: #2e2e2e66;
  }

  &.animation {
    background-color: #2e2e2e66;
    filter: grayscale(1);

    .skeleton__item {
      background: linear-gradient(
        90deg,
        rgba(31, 34, 45, 0.1) 46%,
        rgba(92, 101, 136, 0.3) 50%,
        rgba(31, 34, 45, 0.1) 54%
      );

      background-size: 200%;
      animation: skeleton 1.5s linear infinite;
      height: 100%;
    }
  }

  &__list {
    display: flex;
    align-items: center;
  }
}

@keyframes skeleton {
  0% {
    background-position: 200%;
  }
  50% {
    background-position: 100%;
  }
  100% {
    background-position: 0%;
  }
}
