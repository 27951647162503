@import '../../../configs/medias.scss';
@import '../../../styles/sizes.scss';

.theme_container {
  width: 416px;
  border-radius: 8px;
  padding: 8px 12px;
  color: var(--text-secondary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 41px;
  background-color: var(--account-background-surface-2);
  text-transform: capitalize;

  ul {
    width: 416px;
    position: relative;
    top: 0 !important;
    left: -40px;

    li {
      width: 416px !important;
    }
  }

  .theme_dropdown {
    width: 375px;
    position: relative;
    left: 30px;
    box-shadow: none;
  }

  .theme_icon {
    position: absolute;
    margin-top: 3px;
  }

  .title {
    font-family: Inter;
    font-size: 12px;
    line-height: 15px;
    text-transform: capitalize;
  }

  .value {
    font-family: Inter-SemiBold;
    font-size: 16px;
    line-height: 19px;
  }
}

.mobile {
  .rotateArrow {
    transform: rotate(180deg);
  }
  .switcher{
    border-radius: 8px;
    color: var(--text-secondary);
    border: 1px solid var(--border-primary);
    background-color: var(--account-background-surface-2);
    button {
      width: 74px;
      padding: 0;
        >div {
          justify-content: space-around;
        }
    }
    ul {
      padding: 0;
      margin-bottom: 0;
      li {
        text-transform: capitalize;
        background-color: var(--account-background-surface-1);
        padding: 8px 0;
        border-bottom: 1px solid var(--border-primary);
        &:last-child {
          border-bottom: none;
          border-bottom-right-radius: 6px;
          border-bottom-left-radius: 6px;
        }
      }
    }
  }
}
