.reset-password-success-container {
  background-color: var(--background-surface-1);
  padding-bottom: 0;
  border-radius: 10px;
  overflow: hidden;

  .ant-modal-close {
    background: var(--background-surface-2);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    span {
      color: var(--text-secondary);
      font-size: 10px;
      line-height: unset;
    }
  }

  .ant-modal-content {
    padding: 0;
    box-shadow: unset;
  }

  .ant-modal-body {
    padding: 32px 24px;
    background-color: var(--background-surface-1);
  }

  &__title-description-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__title {
    font-family: Inter-Bold;
    font-size: 24px;
    line-height: 29px;
    color: var(--text-secondary); 
  }
  &__description {
    margin-top: 24px;
    font-family: Inter-Medium;
    font-size: 16px;
    line-height: 19px;

    strong {
      color: var(--text-secondary);
    }
  }
}
