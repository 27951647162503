@import '../../../styles/sizes.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/constants';

.gameTags_container {
  margin-bottom: 0;
  position: relative;
  overflow-x: unset;
  border-radius: 8px;

  &.withButtons {
    padding: 0px 40px;
  }

  .game_tags_wrapper {
    display: flex;
    height: 60px;
    justify-content: flex-start;
    scroll-behavior: smooth;
    text-align: center;
    overflow-x: auto;
    width: 100%;
    align-items: center;
    border: 1px solid var(--border-primary);
    border-radius: 8px;

    .gameTag {
      -webkit-transition: color 0.12s;
      transition: color 0.12s;
      text-transform: uppercase;
      margin: 0 4px;
      padding: 20px;
      border-radius: 4px;
      cursor: pointer;
      height: $gameTag_desktop_height;
      white-space: nowrap;
      display: flex;
      align-items: center;
      font-style: normal;
      font-family: Inter-SemiBold;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      position: relative;

      &:first-child {
        margin: 8px 4px 8px 8px;
      }

      &__label {
        position: absolute;
        right: -2px;
        top: -6px;
        background: var(--button-brand);
        border-radius: 4px;
        padding-left: 7px;
        padding-right: 7px;
        font-size: 10px;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .control_icon_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 29%;
      right: 2px;
      height: 25px;
      width: 25px;
      border-radius: 4px;
      background: var(--background-surface-2);
      border: 1px solid var(--border-primary);
      margin: 0 10px;
      cursor: pointer;
      // z-index: $tag-row__game-tag-control-icon--index;
      z-index: $icon-index;

      > i {
        color: var(--text-secondary);
        font-size: 10px;
      }

      &.prev {
        right: unset;
        left: 2px;
      }
    }
  }
}

.desktop {
  .gametags_container {
    .title {
      margin: 0 0 15px 0;
    }
  }

  .games_wrapper {
    padding-bottom: 30px;
  }
}

//SimpleRow css
.simple_row_container {
  display: flex;
  align-items: center;

  margin-bottom: 24px;
  position: relative;
  overflow-x: unset;
  border-radius: 8px;
  width: 100%;

  &.scrollable {
    width: -webkit-fill-available;
  }

  button {
    margin: 0;
  }

  .wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    overflow-x: scroll;
    white-space: nowrap;
    min-height: 54px;

    .gameTag {
      text-transform: uppercase;
      padding: 10px;
      cursor: pointer;
      white-space: nowrap;
      font-family: Inter-SemiBold;
      text-transform: capitalize;
      font-size: 14px;
      line-height: 17px;
      margin: 0 12px 0 0;
      border-radius: 4px;
      position: relative;

      &:hover {
        opacity: 0.8;
      }
      &__label {
        position: absolute;
        top: -8px;
        left: 14px;
        background: var(--button-brand);
        color: var(--text-tag);
        border-radius: 4px;
        padding-left: 7px;
        padding-right: 7px;
        font-size: 10px;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.mobile {
  .simple_row_container {
    position: relative;
    width: 100vw;
    height: 50px;
    border-bottom: 1px solid var(--border-primary);
    border-radius: 0;

    .wrapper {
      padding: 0;
      height: inherit;

      .gameTag {
        font-size: 12px;
        font-family: Inter;

        &.selected {
          color: var(--text-secondary);
        }
      }
    }
  }
}

//BorderedRow css
.bordered_row {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-style: normal;
      font-family: Inter-Bold;
      font-size: 20px;
      line-height: 24px;
      overflow: hidden;
      text-align: inherit;
      margin-bottom: 15px;
      color: var(--text-secondary);
    }

    .arrows {
      display: flex;
    }
  }

  .gameTags_container.withButtons {
    padding: 0;
  }
}

.mobile .bordered_row {
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    .title {
      padding: 0;
      font-family: Inter-SemiBold;
      font-size: 14px;
      margin: 0;
    }

    .arrows {
      display: none;
    }
  }
  .gameTag {
    &__label {
      top: -8px;
      padding-left: 6px;
      padding-right: 6px;
      font-size: 9px;
      color: var(--text-tag);
    }
  }
}
