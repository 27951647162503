.coins_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .visual-currency {
    svg {
      path {
        &:nth-child(1) {
          fill: rgb(251, 195, 78) !important;
        }
       &:nth-child(2) {
         fill: rgb(229, 165, 51) !important;
       }
        &:nth-child(3) {
          fill: green !important;
        }
      }
    }
  }
  svg {
    height: 20px;
    width: auto;
    margin-right: 10px;
    path {
       &:nth-child(1) {
         fill: rgb(251, 195, 78);
       }
      &:nth-child(2) {
        fill: rgb(229, 165, 51);
      }
      &:nth-child(3) {
        fill: rgb(228, 237, 242);
      }
    }
  }
}

.lastColumn {
  .coins_wrapper {
    justify-content: flex-end;
  }
}

@media (max-width: 480px) {
  .coins_wrapper {
    justify-content: center;
  }
}
