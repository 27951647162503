@import '../../../../styles/sizes.scss';
@import '../../../../styles/mixins.scss';

.mobile_menu_button {
  width: 20%;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  cursor: pointer;
  border: none;
  font-size: 10px;
  padding: 0;
  height: 54px;
  margin-right: 8px;

  svg {
    height: 13px;
    width: 18px;

    path {
      fill: var(--text-primary);
    }
  }

  span {
    margin-top: 6px;
    font-family: Inter-SemiBold;
    font-size: 10px;
    line-height: 12px;
    color: var(--text-primary);
  }

  &.menu_open {
    color: #ffbf17;
    @include svg-color(#ffbf17);
  }
}
