@import '../../../../configs/medias.scss';

.simpleModal {
  &__content {
    flex-grow: 1;
    width: fit-content;
    margin: 0 auto;

    &_noPadding {
      padding: 0;
    }
  }

  &_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;

    &_cancelButton {
      margin-left: 20px;
    }
  }
}

@media (min-width: $laptop) {
  .simpleModal {
    &__content {
      background-color: var(--background-surface-1);
      padding: 0 65px 40px;

      &_noPadding {
        padding: 0;
      }
    }
  }
}

@media (min-width: $tablet) and (max-width: $laptopMin) {
  .simpleModal {
    &__content {
      background-color: var(--background-surface-1);
      padding: 0 30px 40px;

      &_noPadding {
        padding: 0;
      }
    }
  }
}

@media (max-width: $tabletMin) {
  .simpleModal {
    &__content {
      padding: 0 20px 40px;

      &_noPadding {
        padding: 0;
      }
    }

    .title {
      font-size: 14px;
    }
  }
}
