// variables for fullscreen template
$headerTopLineHeight-1Row: 44px;

$mobileHeaderTopLineHeight-1Row: 55px;
$mobileHeaderTopLineHeight-2Row_with_nl: 27px;
$mobileHeaderBottomLineHeight_2Row_with_nl: 55px;

$headerTopLineHeight-2Rows: 30px;
$headerTopLineHeight-2Rows-lg: 74px;
$headerBottomLineHeight-2Rows: 110px;
$headerBottomLineHeight-2Rows-fullscreen: 129px;

$headerTopLineHeight-3Row: 30px;
// 40
$headerMiddleLineHeight-3Rows: 74px;
$headerBottomLineHeight-3Rows: 55px;

$headerLineHeight-1Rows_siderbar: 68px;

$socialIconHeight: 36px;

$sidebarWidth: 228px;
